/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react"

import { useAppSelector } from "../store/selectors/app.selector"
import { runtimeSelector } from "../store/selectors/runtime.selector"
import { FeatureFlag } from "../config/featureFlags.config"
import { FeatureFlagsHelper } from "../helpers/featureFlags.helper"

export const useIsFeatureFlagEnabled = (
    neededFeatureFlag: FeatureFlag
): boolean => {
    const runtime = useAppSelector(runtimeSelector)

    return useMemo<boolean>(() => {
        return FeatureFlagsHelper.isFeatureFlagEnabled(
            runtime.enabledFeatureFlags,
            neededFeatureFlag
        )
    }, [runtime.enabledFeatureFlags])
}
