/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesGetReportsAggregationsStatusCodes } from "../../../store/thunks/policies.thunks"
import { POLICIES_CONFIG } from "../../../config/policies.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { ReportsStatusCodesChartComponent } from "./reportsStatusCodesChart.component"
import { UtilHelper } from "../../../helpers/util.helper"

export const ReportsStatusCodesVisualizationComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const { reportsFilters, reportsAggregationsStatusCodes } =
        useAppSelector(policiesSelector)

    const [hoveredLabel, setHoveredLabel] = useState<string>()

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isReportsAggregationsStatusCodesLoading()
    }, [runtimeState.isLoading])

    const aggregatedCalculations = useMemo<
        IReportsAggregatedStatusesCalculations[]
    >(() => {
        const result = [] as IReportsAggregatedStatusesCalculations[]

        for (const config of POLICIES_CONFIG.reportsStatusCodesAggregations) {
            const value = reportsAggregationsStatusCodes
                ? config.aggregationRules
                    ? PoliciesHelper.getStatusCodeAggregationValue(
                          reportsAggregationsStatusCodes,
                          config
                      )
                    : // If aggregationRules not set it's everything that left: total - sum(previous)
                      reportsAggregationsStatusCodes.total -
                      result.reduce((memo, a) => memo + a.value, 0)
                : 0

            result.push({
                label: config.label,
                color: config.color,
                value,
                percentage: UtilHelper.getPercentageValue(
                    value,
                    reportsAggregationsStatusCodes?.total
                ),
            })
        }

        return result
    }, [JSON.stringify(reportsAggregationsStatusCodes)])

    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const thunkPromise = dispatch(
            policiesGetReportsAggregationsStatusCodes({
                practice: selectedPracticeRole.practice,
                payload: {
                    filters: reportsFilters,
                },
            })
        )

        return () => {
            thunkPromise.abort()
        }
    }, [selectedPracticeRole?.practice.id, JSON.stringify(reportsFilters)])

    return (
        <div
            className={`
                relative min-h-550px
                ${props.className}
            `}
        >
            {isLoading ? (
                <div
                    className="
                        absolute z-10 w-full h-full bg-brand-whiteTransparent85
                        flex items-center justify-center
                    "
                >
                    <SpinnerElement />
                </div>
            ) : null}

            {reportsAggregationsStatusCodes && (
                <div className="py-40px px-24px">
                    <PrimaryText
                        centered
                        size={29}
                        lineHeight={34}
                        weight="bold"
                    >
                        Total Checks: {reportsAggregationsStatusCodes.total}
                    </PrimaryText>

                    <ReportsStatusCodesChartComponent
                        className="mt-16px mx-auto"
                        data={aggregatedCalculations}
                        hoveredLabel={hoveredLabel}
                        widthPercentage={80}
                    />

                    <PrimaryText
                        typography="subtitleSemibold"
                        className="mt-16px"
                    >
                        Status
                    </PrimaryText>

                    {aggregatedCalculations.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="mt-16px flex items-center cursor-default"
                                onMouseEnter={() => setHoveredLabel(item.label)}
                                onMouseLeave={() => setHoveredLabel(undefined)}
                            >
                                <div
                                    className="w-15px h-15px rounded-4px mr-8px relative top--2px"
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                />

                                <PrimaryText>{item.label}</PrimaryText>

                                <div className="flex-1" />

                                <PrimaryText>
                                    <span className="text-bold">
                                        {item.value}
                                    </span>{" "}
                                    ({item.percentage}%)
                                </PrimaryText>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
