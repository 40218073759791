/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { AuthHelper } from "../../helpers/auth.helper"

export const LogoutComponent: React.FunctionComponent = () => {
    const navigate = useNavigate()

    useEffect(() => {
        AuthHelper.logout(undefined, navigate)
    }, [])

    return null
}
