import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"

const initialState: IAuthState = {
    isLoading: false,
    verificationEmail: undefined,
    verificationPassword: undefined,
    forgotPasswordEmail: undefined,
    userInvitation: undefined,
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authReset: () => {
            return initialState
        },
        authSetIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        authSetVerificationData: (state, action: PayloadAction<ILoginData>) => {
            state.verificationEmail = action.payload.email
            state.verificationPassword = action.payload.password
        },
        authSetForgotPasswordEmail: (
            state,
            action: PayloadAction<{ email: string }>
        ) => {
            state.forgotPasswordEmail = action.payload.email
        },
        authSetCheckUserInvitation: (
            state,
            action: PayloadAction<IOrganizationUserInvite | undefined>
        ) => {
            if (!action.payload) {
                BrowserStorageHelper.remove(
                    GENERAL_CONFIG.browserStorageKeys.inviteToken,
                    BrowserStorageType.sessionStorage
                )
            }

            state.userInvitation = action.payload
        },
    },
})

export const {
    authSetIsLoading,
    authReset,
    authSetVerificationData,
    authSetForgotPasswordEmail,
    authSetCheckUserInvitation,
} = authSlice.actions

export const authReducer = authSlice.reducer
