/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import {
    AvailableLookupConsumer,
    ButtonElement,
    PageFloatingButton,
    useCptCodes,
    useStateCallback,
    UtilHelper,
} from "nirvana-react-elements"
import moment from "moment-timezone"

import {
    CALCULATOR_CONFIG,
    CoverageCheckerRunningState,
} from "../../../config/calculator.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { SingleCoverageCheckComponent } from "./singleCoverageCheck.component"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { ResultsQuickViewComponent } from "../quickView/resultsQuickView.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { calculatorSelector } from "../../../store/selectors/calculator.selector"
import { calculatorSetRunningState } from "../../../store/slices/calculator.slice"
import { calculatorRunBulkCoverageChecks } from "../../../store/thunks/calculator.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"

import calculatorIcon from "../../../assets/images/icons/calculator-white.svg"
import plusIcon from "../../../assets/images/icons/plus-dark.svg"

const ROOT_INPUT_DATA_KEY = "data"

export const CoverageCheckerManualComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
        watch,
        setError,
        clearErrors,
    } = useForm()

    const dataWatcher = watch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const calculatorState = useAppSelector(calculatorSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const canRunCoverageChecks = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                [
                    AvailableCoveragePortalPermission.runCoverageChecks,
                    AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                ],
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const [maxInputDataIndex, setMaxInputDataIndex] = useState<number>(0)

    const getDefaultDataIndexes = (): number[] => {
        return calculatorState.coverageChecks?.length
            ? // This is done to use indexes from array in state, because they might be not coming in order there
              // Filter here as required as well, because when unregister happens of row, it's index undefined...
              UtilHelper.getRealIndexesOfArray(calculatorState.coverageChecks)
            : [maxInputDataIndex]
    }

    // Set initial amount of rows: either from state or default to 1 row (so 0 index)
    const [inputDataIndexes, setInputDataIndexes] = useStateCallback<number[]>(
        getDefaultDataIndexes()
    )

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isBulkCoverageChecksLoading()
    }, [runtimeState.isLoading])

    const resultsCount = useMemo<number>(() => {
        return (
            calculatorState.coverageChecks?.filter(item => !!item?.result)
                .length || 0
        )
    }, [calculatorState.coverageChecks])

    const totalCoverageChecks = useMemo<number>(
        () =>
            inputDataIndexes.reduce((accumulator, index) => {
                return (
                    accumulator +
                    (!dataWatcher?.[ROOT_INPUT_DATA_KEY]?.[index]
                        ? 0
                        : dataWatcher?.[ROOT_INPUT_DATA_KEY]?.[index]
                              ?.inNetworkCheck &&
                          dataWatcher?.[ROOT_INPUT_DATA_KEY]?.[index]
                              ?.outNetworkCheck
                        ? 2
                        : 1)
                )
            }, 0),
        [inputDataIndexes, dataWatcher]
    )

    // Mount if we are coming with predefined data
    // Elements that are objects -> set them in hook form
    useEffect(() => {
        setDataFromState()
    }, [calculatorState.inputDataSetAt])

    // Mount if we are coming with predefined data
    // Elements that are objects -> set them in hook form
    const setDataFromState = () => {
        if (!calculatorState.coverageChecks?.length) {
            return
        }

        const neededDataIndexes = getDefaultDataIndexes()
        setMaxInputDataIndex(Math.max(...neededDataIndexes))

        // Basically update items
        // And after they are updated, change data in state
        // cb here is called after this specific state update is done
        setInputDataIndexes(neededDataIndexes, newValues => {
            setTimeout(() => {
                if (!calculatorState.coverageChecks?.length) {
                    return
                }

                for (const index of newValues) {
                    const fieldGroupName = `${ROOT_INPUT_DATA_KEY}[${index}]`

                    try {
                        setValue(
                            `${fieldGroupName}.payer`,
                            calculatorState.coverageChecks[index]?.inputData
                                .payer
                        )

                        setValue(
                            `${fieldGroupName}.cptCode`,
                            calculatorState.coverageChecks[index]?.inputData
                                .cptCode
                        )
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        })
    }

    const onAddRow = () => {
        if (
            inputDataIndexes.length >= CALCULATOR_CONFIG.maxBulkCoverageChecks
        ) {
            return
        }

        const nextDataIndex = maxInputDataIndex + 1
        setMaxInputDataIndex(nextDataIndex)

        setInputDataIndexes(prevIndexes => [...prevIndexes, nextDataIndex])
    }

    const onRemoveRow = (index: number) => {
        if (inputDataIndexes.length <= 1) {
            return
        }

        setInputDataIndexes(prevIndexes => [
            ...prevIndexes.filter(item => item !== index),
        ])

        setValue(`${ROOT_INPUT_DATA_KEY}[${index}]`, undefined)
    }

    const performCalculation = (inputData: any) => {
        if (
            !canRunCoverageChecks ||
            !selectedPracticeRole?.monthlyCoverageQuotaLeft
        ) {
            return
        }

        // We are collecting date in Moment, but it's not allowed for serialization
        // So we need to map it to date string
        // Some items when removed from UI can be set to undefined - we need to filter them out
        const payload = (inputData[ROOT_INPUT_DATA_KEY] as ICoverageInputData[])
            .filter(item => !!item)
            .map(item => ({
                ...item,

                dob: UtilHelper.dateToMysqlFormat(moment(item.dob).toDate()),

                customerPatientNextAppointmentDate:
                    item.customerPatientNextAppointmentDate
                        ? UtilHelper.dateToMysqlFormat(
                              moment(
                                  item.customerPatientNextAppointmentDate
                              ).toDate()
                          )
                        : undefined,
            }))

        dispatch(
            calculatorRunBulkCoverageChecks({
                payload,
                practice: selectedPracticeRole.practice,

                onSuccess: () => {
                    dispatch(
                        calculatorSetRunningState(
                            CoverageCheckerRunningState.RESULTS_MANUAL
                        )
                    )
                },
            })
        )

        // Add random part to the url, so we can safely catch browser back button then
        // And if back button is clicked from showing results -> it will initiate "run new checks"
        // Acts as history.push
        navigate(
            `${ROUTES_CONFIG.coverageCheckerUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.results
            }=${new Date().getTime()}`
        )
    }

    return (
        <div
            className={`
                ${props.className}
                relative
            `}
        >
            <div>
                {/*RUN COVERAGE CHECKS BTN*/}
                {/*SHOW when no results at all OR when still receiving results (but at this point can receive some of them)*/}
                {!resultsCount || isLoading ? (
                    <PageFloatingButton
                        className="bottom-60px!"
                        zIndex={!isLoading ? 40 : undefined}
                        label={`Run${
                            isLoading ? "ning" : ""
                        } ${totalCoverageChecks} Check${
                            totalCoverageChecks > 1 ? "s" : ""
                        }${isLoading ? "..." : ""}`}
                        icon={calculatorIcon}
                        isLoading={isLoading}
                        disabled={
                            !selectedPracticeRole?.monthlyCoverageQuotaLeft
                        }
                        onClick={handleSubmit(performCalculation)}
                    />
                ) : null}

                <form
                    onSubmit={handleSubmit(performCalculation)}
                    className="mt-44px md:mt-24px"
                >
                    {resultsCount ? (
                        <ResultsQuickViewComponent className="ml--45px mr--45px sm:ml--16px sm:mr--16px" />
                    ) : (
                        <OrganizationQuoteComponent className="mt-32px" />
                    )}

                    {inputDataIndexes.map(index => {
                        const fieldGroupName = `${ROOT_INPUT_DATA_KEY}[${index}]`

                        // Make sure to pass this as copy, so it's values can be checked in React.memo
                        const checkDataWatcher = JSON.parse(
                            JSON.stringify(watch(fieldGroupName) || {})
                        )

                        // Make sure to pass this as copy, so it's values can be checked in React.memo
                        let fieldGroupErrors =
                            errors?.[ROOT_INPUT_DATA_KEY]?.[index]

                        fieldGroupErrors = fieldGroupErrors
                            ? JSON.parse(JSON.stringify(fieldGroupErrors))
                            : undefined

                        const stateCheckResults =
                            calculatorState.coverageChecks?.[index]?.result

                        const stateCheckInputData =
                            calculatorState.coverageChecks?.[index]?.inputData

                        const checkDisabled =
                            calculatorState.coverageChecksSubmitted ||
                            !!stateCheckResults

                        return (
                            <SingleCoverageCheckComponent
                                key={index}
                                className={`
                                    ${!index ? "mt-24px" : "mt-32px"}
                                `}
                                cptCodes={availableCptCodes}
                                dataRootKey={ROOT_INPUT_DATA_KEY}
                                coverageCheckIndex={index}
                                fieldGroupName={fieldGroupName}
                                dataWatcher={checkDataWatcher}
                                fieldGroupErrors={fieldGroupErrors}
                                reactHookControl={control}
                                reactHookFormRegister={register}
                                reactHookFormUnregister={unregister}
                                reactHookFormSet={setValue}
                                reactHookFormWatch={watch}
                                reactHookSetError={setError}
                                reactHookClearErrors={clearErrors}
                                removeRowAvailable={inputDataIndexes.length > 1}
                                onRemoveRow={onRemoveRow}
                                stateCheckInputData={stateCheckInputData}
                                stateCheckResults={stateCheckResults}
                                checkDisabled={checkDisabled}
                            />
                        )
                    })}

                    {CALCULATOR_CONFIG.maxBulkCoverageChecks >
                        inputDataIndexes.length &&
                    !calculatorState.coverageChecksSubmitted ? (
                        <div className="flex mt-24px">
                            <div className="flex-1" />

                            <ButtonElement
                                className="md:mt-24px"
                                label="Add another check"
                                icon={plusIcon}
                                type="ghost"
                                htmlType="button"
                                onClick={onAddRow}
                                disabled={isLoading}
                            />
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    )
}
