/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText } from "nirvana-react-elements"
import moment from "moment-timezone"

import {
    POLICIES_CONFIG,
    ResetBenefitStatus,
} from "../../../config/policies.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { POLICIES_COLUMNS_CONFIG } from "../../../config/policiesColumns.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"

export const PlanYearResetBreakdownComponent: React.FunctionComponent<
    IPlanYearResetBreakdownComponentProps
> = props => {
    const renderingConfig = useMemo<{
        titleSuffix: string
        needFullBreakdown: boolean
    }>(() => {
        let needFullBreakdown = false
        let titleSuffix = "Not Detected"

        const { resetBenefitsStatus } =
            props.coverageCheckHistory.coverageResult

        if (
            resetBenefitsStatus &&
            [
                ResetBenefitStatus.DETECTED_ACTION_REQUIRED,
                ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED,
            ].includes(resetBenefitsStatus)
        ) {
            needFullBreakdown = true
            titleSuffix = "Detected"
        }

        return {
            needFullBreakdown,
            titleSuffix,
        }
    }, [props.coverageCheckHistory.coverageResult.resetBenefitsStatus])

    const fullBreakdown = useMemo<JSX.Element | null>(() => {
        const { coverageResult } = props.coverageCheckHistory

        if (
            !renderingConfig.needFullBreakdown ||
            !coverageResult.resetBenefits
        ) {
            return null
        }

        return (
            <div className="mt-24px">
                <div className="flex items-center">
                    <div className="w-225px mr-8px" />

                    <PrimaryText
                        className="flex-1 mr-8px"
                        typography="textSemibold"
                    >
                        Current
                    </PrimaryText>

                    <PrimaryText className="flex-1" typography="textSemibold">
                        {POLICIES_CONFIG.planYearResetsTargetYear}
                    </PrimaryText>
                </div>

                {Object.keys(coverageResult.resetBenefits).map((key, index) => {
                    const columnConfig = Object.values(
                        POLICIES_COLUMNS_CONFIG.columnsConfig
                    ).find(
                        column =>
                            column.sourcePropertyPath === key ||
                            column.alternativeSourcePropertyPath === key
                    )

                    const currentValue = columnConfig
                        ? PoliciesHelper.getMappedColumnValue(
                              props.coverageCheckHistory,
                              columnConfig,
                              COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                              true,
                              true
                          )
                        : coverageResult[key]

                    const newValue = columnConfig
                        ? PoliciesHelper.getMappedColumnValue(
                              {
                                  ...props.coverageCheckHistory,
                                  coverageResult: {
                                      ...props.coverageCheckHistory
                                          .coverageResult,

                                      ...(coverageResult.resetBenefits || {}),
                                  },
                              },
                              columnConfig,
                              COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                              true,
                              true
                          )
                        : coverageResult.resetBenefits?.[key] ||
                          COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown

                    const isHighlighted = currentValue !== newValue

                    return (
                        <div key={index} className="mt-2px flex items-center">
                            <PrimaryText
                                className="w-225px mr-8px"
                                typography="textSemibold"
                            >
                                {columnConfig?.label || key}
                            </PrimaryText>

                            <div className="flex-1 mr-8px">
                                <PrimaryText
                                    className={`
                                        inline-block w-auto pt-2px
                                        ${
                                            isHighlighted
                                                ? "bg-brand-paleRed010 px-4px"
                                                : ""
                                        }
                                    `}
                                    typography="text"
                                >
                                    {currentValue}
                                </PrimaryText>
                            </div>

                            <div className="flex-1">
                                <PrimaryText
                                    className={`
                                        inline-block w-auto pt-2px
                                        ${
                                            isHighlighted
                                                ? "bg-brand-greenHighlight px-4px border-b-2px border-solid border-brand-primary"
                                                : ""
                                        }
                                    `}
                                    typography="text"
                                >
                                    {newValue}
                                </PrimaryText>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }, [
        renderingConfig.needFullBreakdown,
        props.coverageCheckHistory.coverageResult,
    ])

    return (
        <div
            className={`
                relative px-24px pt-24px pb-16px bg-brand-white rounded-16px w-500px
                md:w-full
                ${props.className}
            `}
        >
            <div className="flex items-center">
                <PrimaryText typography="h4" className="flex-1 mr-16px">
                    {POLICIES_CONFIG.planYearResetsTargetYear} Policy{" "}
                    {renderingConfig.titleSuffix}
                </PrimaryText>

                <PrimaryText typography="h6">
                    <span className="opacity-50">Last check: </span>
                    <span className="opacity-75">
                        {moment(
                            props.coverageCheckHistory.coverageResult.createdAt
                        ).format(
                            GENERAL_CONFIG.defaultMomentDateFormatReadable
                        )}
                    </span>
                </PrimaryText>
            </div>

            {fullBreakdown}
        </div>
    )
}
