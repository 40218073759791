import React from "react"
import { PrimaryText } from "nirvana-react-elements"

import { supportSetSelectedContentCategory } from "../../store/slices/support.slice"
import { useAppDispatch } from "../../store/appDispatch.hook"

export const SupportContentCategoryCardComponent: React.FunctionComponent<
    ISupportContentCategoryViewComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const onCategorySelected = () => {
        dispatch(supportSetSelectedContentCategory(props.item))
    }

    return (
        <div className={props.className}>
            <div
                className="
                    w-full h-full pt-25px pr-20px pb-22px pl-20px bg-brand-warmLight05
                    cursor-pointer overflow-hidden
                "
                onClick={onCategorySelected}
            >
                <img src={props.item.icon.file.url} alt={props.item.title} />

                <PrimaryText typography="textBold" className="mt-16px">
                    {props.item.shortTitle}
                </PrimaryText>

                <PrimaryText typography="caption" className="mt-4px">
                    {props.item.shortDescription}
                </PrimaryText>
            </div>
        </div>
    )
}
