import React, { useMemo } from "react"
import { PrimaryText } from "nirvana-react-elements"

import { POLICIES_CONFIG } from "../../../config/policies.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"

export const FlagBadgeComponent: React.FunctionComponent<
    IFlagBadgeComponentProps
> = props => {
    const flagIcon = useMemo<string>(() => {
        const denialRisk = PoliciesHelper.getPolicyDenialRisk([props.flagType])

        return POLICIES_CONFIG.policyDenialRiskIconsMapping[denialRisk]
    }, [props.flagType])

    return (
        <div
            className={`
                relative flex items-center bg-brand-white
                px-9px py-2px rounded-full border border-solid border-brand-warmLight
                ${props.className}
            `}
        >
            <div className="mr-8px">
                <img src={flagIcon} width="18" alt="flag icon" />
            </div>

            <PrimaryText typography="h6" className="relative top-1px">
                {POLICIES_CONFIG.flagTypeMappings[props.flagType].label}
            </PrimaryText>
        </div>
    )
}
