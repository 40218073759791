import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { AvailableApplicationRole } from "../config/rolesPermissions.config"

export class OrganizationUsersService {
    /**
     * Get list of coverage portal users within practice
     */
    static async getUsersList(
        practice: IPractice | number
    ): Promise<IOrganizationUser[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_GET_LIST.replace(
                ":practiceId",
                (typeof practice === "number"
                    ? practice
                    : practice.id
                ).toString()
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete user from practice
     */
    static async deleteUser(
        practice: IPractice,
        user: IOrganizationUser
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_DELETE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":userId", user.id.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Change user role in practice
     */
    static async changeUserRole(
        practice: IPractice,
        user: IOrganizationUser,
        role: AvailableApplicationRole
    ): Promise<IOrganizationUser | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_CHANGE_ROLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":userId", user.id.toString()),
            {
                role,
            },
            "PUT"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Invite user to organization
     */
    static async inviteUser(
        practice: IPractice,
        email: string,
        role: AvailableApplicationRole,
        billerGroupIds?: number[]
    ): Promise<
        | {
              isNew: boolean
              invite: IOrganizationUserInvite
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_INVITE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                email,
                role,
                billerGroupIds,
            },
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get list of coverage portal users invites within practice
     */
    static async getInvitesList(
        practice: IPractice
    ): Promise<IOrganizationUserInvite[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_GET_INVITES_LIST.replace(
                ":practiceId",
                practice.id.toString()
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete user invite from practice
     */
    static async deleteUserInvite(
        practice: IPractice,
        invite: IOrganizationUserInvite
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_DELETE_INVITE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":inviteId", invite.id.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Change user invite role in practice
     */
    static async changeUserInviteRole(
        practice: IPractice,
        invite: IOrganizationUserInvite,
        role: AvailableApplicationRole
    ): Promise<IOrganizationUserInvite | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ORGANIZATION_USERS_CHANGE_INVITE_ROLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":inviteId", invite.id.toString()),
            {
                role,
            },
            "PUT"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
