import React from "react"
import { PrimaryText, MarkdownElement } from "nirvana-react-elements"

import { SupportContentArticleViewComponent } from "./supportContentArticleView.component"
import { SupportContentCategoryCardComponent } from "./supportContentCategoryCard.component"

export const SupportContentCategoryViewComponent: React.FunctionComponent<
    ISupportContentCategoryViewComponentProps
> = props => {
    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {!props.item.isCategoryTitleHidden &&
            !props.isCategoryTitleHidden ? (
                <PrimaryText typography="h2">{props.item.title}</PrimaryText>
            ) : null}

            {props.item.description || props.item.articles ? (
                <>
                    {props.item.description ? (
                        <MarkdownElement
                            className="contentful-markdown block mt-16px"
                            text={props.item.description}
                        />
                    ) : null}

                    {props.item.articles?.map((item, index) =>
                        !props.searchValue ||
                        item.title?.toLowerCase().includes(props.searchValue) ||
                        item.description
                            ?.toLowerCase()
                            .includes(props.searchValue) ? (
                            <SupportContentArticleViewComponent
                                key={index}
                                className="mt-24px"
                                item={item}
                            />
                        ) : null
                    )}
                </>
            ) : props.item.childCategories ? ( // Show panels for inner categories if current category doesn't have description or inner articles
                <div className="flex items-stretch flex-wrap">
                    {props.item.childCategories.map((item, index) => (
                        <SupportContentCategoryCardComponent
                            className="flex-basis-50 mt-24px pr-24px"
                            item={item}
                            key={index}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    )
}
