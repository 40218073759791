import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    SpinnerElement,
    PrimaryText,
    useScrollFix,
    UtilHelper,
    PageHelmetElement,
    FixedTopRightActionElement,
} from "nirvana-react-elements"

import { authResendVerificationLink } from "../../../store/thunks/auth.thunks"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { authSelector } from "../../../store/selectors/auth.selector"
import { ToastrHelper } from "../../../helpers/toastr.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { authReset } from "../../../store/slices/auth.slice"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import registerSuccessImg from "../../../assets/images/shapes/email-sent.svg"

export const RegisterSuccessComponent: React.FunctionComponent = () => {
    useScrollFix()

    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const authState = useAppSelector(authSelector)

    const [registeredEmail] = useState<string | undefined>(
        UtilHelper.getRawUrlSearchParam("email")
    )

    useEffect(() => {
        return () => {
            dispatch(authReset())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!registeredEmail) {
            ToastrHelper.error("Not enough data to proceed. Please try again")

            return navigate(ROUTES_CONFIG.loginUrl)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registeredEmail])

    const onResendVerificationLink = () => {
        if (!registeredEmail || authState.isLoading) {
            return
        }

        dispatch(
            authResendVerificationLink({
                payload: registeredEmail,
            })
        )
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Successful Registration"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <img
                src={registerSuccessImg}
                className="mx-auto mt-120px sm:w-150px md:mt-32px"
                alt="Register success"
                title="Register success"
            />

            <PrimaryText className="mt-32px" centered typography="h1">
                Almost there!
            </PrimaryText>

            <PrimaryText className="mt-32px" centered typography="subtitle">
                You’re almost done creating an account. Please{" "}
                <span className="text-bold">check your email</span>
                <br />
                for a verification link prior to logging in your Nirvana
                account!
            </PrimaryText>

            <FixedTopRightActionElement
                ctaLabel="Click here to resend"
                additionalLabel="Not seeing the email?"
                ctaAction={onResendVerificationLink}
                className="z-201 md:static! md:mt-32px md:justify-center"
            />

            {authState.isLoading && (
                <SpinnerElement containerClassName="mt-33px text-center" />
            )}
        </div>
    )
}
