/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    ButtonElement,
    PageHelmetElement,
    useScrollFix,
} from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    PoliciesViewType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { policiesSetOverridesFilters } from "../../../store/slices/policies.slice"
import { PoliciesColumnsManagementComponent } from "../policiesList/policiesColumnsManagement.component"
import { PoliciesFilterPopupComponent } from "../../popups/policiesFilterPopup.component"
import { SearchFilterDisplayComponent } from "../search/searchFilterDisplay.component"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { BulkOverridesComponent } from "./bulkOverrides/bulkOverrides.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"

import filtersIcon from "../../../assets/images/icons/filters-dark.svg"

export const OverridesComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions([AvailableCoveragePortalPermission.overrideCoverageData])

    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const { overridesFilters } = useAppSelector(policiesSelector)

    const scrollableTableRef = useRef<HTMLDivElement>(null)
    const scrollableTableBoundaries =
        useScrollableElementBoundaries(scrollableTableRef)

    const {
        // vars
        isOverridesEnabled,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const [isFiltersPopupActive, setIsFiltersPopupActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return canManageOverrides && isOverridesEnabled
    }, [isOverridesEnabled, canManageOverrides])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            BrowserStorageHelper.getDefaultOverridesPoliciesColumnsConfiguration(
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        // Deselect everything on filters change
        setSelectedPolicies([])

        setIsFiltersPopupActive(true)
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        dispatch(policiesSetOverridesFilters(data))
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        BrowserStorageHelper.saveColumnsConfigurations(
            GENERAL_CONFIG.browserStorageKeys
                .overridesPoliciesColumnsConfiguration,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Overrides"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div
                className="relative p-16px flex items-start"
                style={{
                    right: scrollableTableBoundaries?.x,
                }}
            >
                {!Object.keys(overridesFilters).filter(
                    key =>
                        !!overridesFilters[key] &&
                        !POLICIES_CONFIG.filtersHiddenDisplayKeys.includes(
                            key as keyof IPoliciesListFiltersData
                        )
                ).length ? (
                    <ButtonElement
                        label="Filters"
                        className="mr-16px"
                        onClick={onEditFilters}
                        htmlType="button"
                        type="default"
                        icon={filtersIcon}
                        isRightIcon
                    />
                ) : (
                    <SearchFilterDisplayComponent
                        className="w-380px mr-16px"
                        onEditFilters={onEditFilters}
                        existingFilters={overridesFilters}
                    />
                )}

                <div className="flex-1 mx-16px" />

                {columnsConfiguration.length && selectedPracticeRole ? (
                    <PoliciesColumnsManagementComponent
                        className="ml-16px"
                        initialColumnsConfiguration={PoliciesHelper.getDefaultOverridesColumns(
                            selectedPracticeRole.availableModalities
                        )}
                        currentColumnsConfiguration={columnsConfiguration}
                        onColumnsConfigurationChange={
                            onSaveColumnsConfiguration
                        }
                        isRightPlacement
                    />
                ) : null}
            </div>

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    viewType={PoliciesViewType.OVERRIDES}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    scrollableElementRef={scrollableTableRef}
                    onChangeColumnOrder={onChangeColumnOrder}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                />
            ) : null}

            {showBulkOverrideButton && (
                <BulkOverridesComponent
                    coverageChecksType={PoliciesViewType.OVERRIDES}
                    onCancelBulkOverride={onDeselectAllPolicies}
                    coverageChecksToOverride={selectedPolicies}
                    setCoverageChecksToOverride={setSelectedPolicies}
                />
            )}

            {isFiltersPopupActive && (
                <PoliciesFilterPopupComponent
                    isActive
                    withFlags={false}
                    withResetBenefitsStatus={false}
                    existingFilters={overridesFilters}
                    persistenceBrowserStorageKey={
                        GENERAL_CONFIG.browserStorageKeys
                            .overridesPoliciesFiltersConfiguration
                    }
                    onFiltersSubmitted={onFiltersSubmitted}
                    onDone={() => setIsFiltersPopupActive(false)}
                />
            )}
        </div>
    )
}
