/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    ButtonElement,
    PageHelmetElement,
    useScrollFix,
} from "nirvana-react-elements"
import { useNavigate, useSearchParams } from "react-router-dom"
import moment from "moment-timezone"

import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../../../config/rolesPermissions.config"
import {
    PoliciesViewType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { policiesSetReportsFilters } from "../../../store/slices/policies.slice"
import { PoliciesColumnsManagementComponent } from "../policiesList/policiesColumnsManagement.component"
import { PoliciesFilterPopupComponent } from "../../popups/policiesFilterPopup.component"
import { SearchFilterDisplayComponent } from "../search/searchFilterDisplay.component"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesExportList } from "../../../store/thunks/policies.thunks"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { ReportsVisualizationComponent } from "./reportsVisualization.component"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { PoliciesSavedConfigurationsComponent } from "../policiesList/policiesSavedConfigurations.component"
import { UtilHelper } from "../../../helpers/util.helper"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { BulkOverridesComponent } from "../overrides/bulkOverrides/bulkOverrides.component"

import closeIcon from "../../../assets/images/icons/close-dark-secondary.svg"
import filtersIcon from "../../../assets/images/icons/filters-dark.svg"
import exportIcon from "../../../assets/images/icons/download-up-dark.svg"

export const ReportsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions(
        [
            AvailableCoveragePortalPermission.managePolicyFlags,
            AvailableCoveragePortalPermission.resolveFlags,
            AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
        ],
        PermissionsExistenceLogicalOperator.OR
    )

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const {
        // vars
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageFlags,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const scrollableTableRef = useRef<HTMLDivElement>(null)
    const scrollableTableBoundaries =
        useScrollableElementBoundaries(scrollableTableRef)

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const { reportsItems, reportsFilters, policiesGettingFullListProgress } =
        useAppSelector(policiesSelector)

    const [isFiltersPopupActive, setIsFiltersPopupActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return (
            (canManageFlags && isFlagsResolutionEnabled) ||
            (canManageOverrides && isOverridesEnabled)
        )
    }, [
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageOverrides,
        canManageFlags,
    ])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    const isExportLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPoliciesExportLoading()
    }, [runtimeState.isLoading])

    // Monitor filters in search params and set them in state
    useEffect(() => {
        const searchParamsFilters = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.filters
        )

        // If no reports filters by in url (that's our main reports url)
        // Set them in search params so clicking back works fine
        if (!searchParamsFilters) {
            searchParams.set(
                GENERAL_CONFIG.urlSearchParamsKeys.filters,
                JSON.stringify(reportsFilters)
            )

            setSearchParams(searchParams)

            return
        }

        // In case json is invalid
        try {
            const filtersFromUrl: IPoliciesListFiltersData =
                JSON.parse(searchParamsFilters)

            if (
                JSON.stringify(filtersFromUrl) !==
                JSON.stringify(reportsFilters)
            ) {
                dispatch(policiesSetReportsFilters(filtersFromUrl))
            }
        } catch (e) {}
    }, [searchParams])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            BrowserStorageHelper.getDefaultReportsPoliciesColumnsConfiguration(
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        setIsFiltersPopupActive(true)
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        // Deselect everything on filters change
        setSelectedPolicies([])

        navigate(
            `${ROUTES_CONFIG.reportsUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.filters
            }=${JSON.stringify(data)}`
        )
    }

    const onExportCsv = () => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesExportList({
                practice: selectedPracticeRole.practice,
                payload: {
                    viewType: PoliciesViewType.REPORTS,
                    columns:
                        BrowserStorageHelper.getDefaultReportsPoliciesColumnsConfiguration(
                            selectedPracticeRole.availableModalities
                        ),
                },
            })
        )
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        BrowserStorageHelper.saveColumnsConfigurations(
            GENERAL_CONFIG.browserStorageKeys
                .reportsPoliciesColumnsConfiguration,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    const onSavedConfigurationSelected = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        if (
            JSON.stringify(columnsConfiguration) !==
            JSON.stringify(configuration.columns)
        ) {
            onSaveColumnsConfiguration(configuration.columns)
        }

        let filtersOverrides: IPoliciesListFiltersData = {}

        const { dateTo, dateFrom } = configuration.filters

        // Make sure to adjust dates to be relative to the date when configuration was created
        if (configuration.isRelativeDates && (dateTo || dateFrom)) {
            const dateFromDiffDays = dateFrom
                ? moment(configuration.createdAt).diff(moment(dateFrom), "days")
                : null

            const newDateFrom = dateFromDiffDays
                ? UtilHelper.dateToMysqlFormat(
                      moment()
                          .startOf("day")
                          .subtract(dateFromDiffDays, "day")
                          .toDate()
                  )
                : dateFrom

            const dateToDiffDays = dateTo
                ? moment(configuration.createdAt).diff(moment(dateTo), "days")
                : null

            const newDateTo = dateToDiffDays
                ? UtilHelper.dateToMysqlFormat(
                      moment()
                          .startOf("day")
                          .add(dateToDiffDays, "day")
                          .toDate()
                  )
                : dateTo

            filtersOverrides = {
                dateTo: newDateTo,
                dateFrom: newDateFrom,
            }
        }

        const newFilters = {
            ...configuration.filters,
            ...filtersOverrides,
        }

        if (JSON.stringify(reportsFilters) !== JSON.stringify(newFilters)) {
            onFiltersSubmitted(newFilters)
        }
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Reports"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div
                className="
                    relative p-16px flex items-start
                    md:block
                "
                style={{
                    right: scrollableTableBoundaries?.x,
                }}
            >
                <div className="mr-16px">
                    {!Object.keys(reportsFilters).filter(
                        key => !!reportsFilters[key]
                    ).length ? (
                        <ButtonElement
                            label="Filters"
                            onClick={onEditFilters}
                            htmlType="button"
                            type="default"
                            icon={filtersIcon}
                            isRightIcon
                        />
                    ) : (
                        <SearchFilterDisplayComponent
                            className="w-380px"
                            onEditFilters={onEditFilters}
                            existingFilters={reportsFilters}
                        />
                    )}
                </div>

                <div className="flex-1 mx-16px md:hideen" />

                <div className="flex items-center md:mt-32px md:justify-center">
                    {selectedPolicies.length &&
                    isFlagsResolutionEnabled &&
                    canManageFlags ? (
                        <>
                            {/*TODO IMPLEMENT THIS*/}
                            <ButtonElement
                                label="Assign to..."
                                onClick={() => alert("TODO")}
                                htmlType="button"
                                type="default"
                            />

                            <div
                                className="ml-24px cursor-pointer"
                                onClick={onDeselectAllPolicies}
                            >
                                <img src={closeIcon} alt="Cancel" />
                            </div>
                        </>
                    ) : null}

                    {columnsConfiguration.length && selectedPracticeRole ? (
                        <>
                            <PoliciesColumnsManagementComponent
                                className="ml-16px"
                                initialColumnsConfiguration={PoliciesHelper.getDefaultReportsColumns(
                                    selectedPracticeRole.availableModalities
                                )}
                                currentColumnsConfiguration={
                                    columnsConfiguration
                                }
                                onColumnsConfigurationChange={
                                    onSaveColumnsConfiguration
                                }
                                isRightPlacement
                            />

                            <PoliciesSavedConfigurationsComponent
                                className="ml-16px"
                                columns={columnsConfiguration}
                                defaultColumns={BrowserStorageHelper.getDefaultReportsPoliciesColumnsConfiguration(
                                    selectedPracticeRole.availableModalities
                                )}
                                filters={reportsFilters}
                                browserStorageKey={
                                    GENERAL_CONFIG.browserStorageKeys
                                        .reportsSavedConfigurations
                                }
                                isRightPlacement
                                onSavedConfigurationSelected={
                                    onSavedConfigurationSelected
                                }
                            />
                        </>
                    ) : null}

                    <ButtonElement
                        label={`Export CSV${
                            isExportLoading
                                ? ` (${policiesGettingFullListProgress}%)`
                                : ""
                        }`}
                        className="ml-16px"
                        onClick={onExportCsv}
                        htmlType="button"
                        type="default"
                        icon={exportIcon}
                        isLoading={isExportLoading}
                        disabled={!reportsItems.length}
                        isRightIcon
                    />
                </div>
            </div>

            {!!selectedPracticeRole && (
                <ReportsVisualizationComponent
                    className="mt-30px"
                    adjustHorizontalScrollReference={scrollableTableRef}
                />
            )}

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    scrollableElementRef={scrollableTableRef}
                    viewType={PoliciesViewType.REPORTS}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                    onChangeColumnOrder={onChangeColumnOrder}
                />
            ) : null}

            {isFiltersPopupActive && (
                <PoliciesFilterPopupComponent
                    isActive
                    withFlags={true}
                    withResetBenefitsStatus={false}
                    existingFilters={reportsFilters}
                    persistenceBrowserStorageKey={
                        GENERAL_CONFIG.browserStorageKeys
                            .reportsPoliciesFiltersConfiguration
                    }
                    onFiltersSubmitted={onFiltersSubmitted}
                    onDone={() => setIsFiltersPopupActive(false)}
                />
            )}

            {showBulkOverrideButton && (
                <BulkOverridesComponent
                    coverageChecksType={PoliciesViewType.REPORTS}
                    onCancelBulkOverride={onDeselectAllPolicies}
                    coverageChecksToOverride={selectedPolicies}
                    setCoverageChecksToOverride={setSelectedPolicies}
                />
            )}
        </div>
    )
}
