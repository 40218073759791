/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    useScrollFix,
    ScreenTabsElement,
    PrimaryText,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { OrganizationInformationComponent } from "./organizationInformation.component"
import { OrganizationUsersComponent } from "./users/organizationUsers.component"
import { BillerGroupsComponent } from "./billerGroups/billerGroups.component"
import { CoveragePayersComponent } from "./coveragePayers/coveragePayers.component"
import { MENU_CONFIG } from "../../config/menu.config"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { FeatureFlagsHelper } from "../../helpers/featureFlags.helper"

export const OrganizationComponent: React.FunctionComponent = () => {
    useScrollFix()

    const [searchParams, setSearchParams] = useSearchParams()

    const runtime = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    // ?tab=1 is supported in url for quick navigation to some tab
    const [selectedTab, setSelectedTab] = useState<IOrganizationAvailableTab>(
        MENU_CONFIG.organizationAvailableTabs[
            searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) &&
            MENU_CONFIG.organizationAvailableTabs[
                searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) || 0
            ]
                ? searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) || 0
                : 0
        ]
    )

    // If tab changes in url make sure to change it in state
    useEffect(() => {
        const tab = searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab)

        if (!tab || !MENU_CONFIG.organizationAvailableTabs[tab]) {
            return
        }

        setSelectedTab(MENU_CONFIG.organizationAvailableTabs[tab])

        // Clean url
        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.tab)
        setSearchParams(searchParams)
    }, [searchParams])

    const availableTabs = useMemo<IOrganizationAvailableTab[]>(() => {
        if (!selectedPracticeRole) {
            return []
        }

        // Filter out tabs by permissions
        return MENU_CONFIG.organizationAvailableTabs.filter(item => {
            if (
                item.featureFlag &&
                !FeatureFlagsHelper.isFeatureFlagEnabled(
                    runtime.enabledFeatureFlags,
                    item.featureFlag
                )
            ) {
                return false
            }

            return (
                !item.neededPermissions ||
                PermissionsHelper.hasPermission(
                    item.neededPermissions,
                    selectedPracticeRole,
                    item.neededPermissionsLogicalOperator
                )
            )
        })
    }, [selectedPracticeRole?.id, runtime.enabledFeatureFlags])

    const tabRelatedContent = useMemo<JSX.Element | null>(() => {
        switch (selectedTab.key) {
            case "information":
                return <OrganizationInformationComponent />

            case "users":
                return <OrganizationUsersComponent />

            case "billerGroups":
                return (
                    <BillerGroupsComponent
                        onSidebarTabSelected={(tab: IScreenTab) =>
                            setSelectedTab(tab as IOrganizationAvailableTab)
                        }
                        sidebarAvailableTabs={availableTabs}
                        sidebarSelectedTab={selectedTab}
                    />
                )

            case "payers":
                return <CoveragePayersComponent />

            default:
                return null
        }
    }, [selectedTab.key, availableTabs])

    return (
        <div className="relative">
            {!MENU_CONFIG.organizationTabsHiddenKeys.includes(
                selectedTab.key
            ) ? (
                availableTabs.length > 1 ? (
                    <ScreenTabsElement
                        className="w-840px mx-auto mt-32px mb-40px md:w-full"
                        currentTab={selectedTab}
                        tabs={availableTabs}
                        onSelected={tab =>
                            setSelectedTab(tab as IOrganizationAvailableTab)
                        }
                    />
                ) : (
                    <PrimaryText className="mt-32px mb-24px" typography="h4">
                        {selectedTab.label}
                    </PrimaryText>
                )
            ) : null}

            {tabRelatedContent}
        </div>
    )
}
