import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../config/rolesPermissions.config"

export class PermissionsHelper {
    /**
     * Check if user has some specific permission based on currently selected practiceRole
     */
    static hasPermission(
        permissions:
            | AvailableCoveragePortalPermission
            | AvailableCoveragePortalPermission[],
        selectedPracticeRole?: IPracticeRole,
        multipleLogic: PermissionsExistenceLogicalOperator = PermissionsExistenceLogicalOperator.OR
    ): boolean {
        try {
            if (!selectedPracticeRole) {
                return false
            }

            if (!Array.isArray(permissions)) {
                return !!selectedPracticeRole?.role.permissions.includes(
                    permissions
                )
            }

            switch (multipleLogic) {
                // Should have ALL permissions that are requested in array
                case PermissionsExistenceLogicalOperator.AND:
                    return (
                        permissions.filter(item =>
                            selectedPracticeRole.role.permissions.includes(item)
                        ).length === permissions.length
                    )

                // Should have ONE or more permissions that are requested in array
                case PermissionsExistenceLogicalOperator.OR:
                    return !!permissions.filter(item =>
                        selectedPracticeRole.role.permissions.includes(item)
                    ).length
            }
        } catch (e) {
            return false
        }
    }
}
