import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

const initialState: ICoverageOverridesState = {
    items: [],
    selectedPolicyOverride: null,
}

const coverageOverridesSlice = createSlice({
    name: "coverageOverrides",
    initialState,
    reducers: {
        coverageOverridesReset: () => initialState,

        coverageOverridesSetSelectedPolicyOverride: (
            state,
            action: PayloadAction<ICoverageOverride | null>
        ) => {
            state.selectedPolicyOverride = action.payload
        },
    },
})

export const {
    coverageOverridesReset,
    coverageOverridesSetSelectedPolicyOverride,
} = coverageOverridesSlice.actions

export const coverageOverridesReducer = coverageOverridesSlice.reducer
