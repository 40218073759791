import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import {
    ButtonElement,
    PrimaryText,
    useScrollFix,
    NIRVANA_COLORS,
    PageHelmetElement,
    FixedTopRightActionElement,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { authLogin } from "../../store/thunks/auth.thunks"
import { useAppSelector } from "../../store/selectors/app.selector"
import { authSelector } from "../../store/selectors/auth.selector"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppDispatch } from "../../store/appDispatch.hook"

import mainImage from "../../assets/images/shapes/login.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"

export const LoginComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const authState = useAppSelector(authSelector)

    const [isLoginError, setIsLoginError] = useState<boolean>(false)

    const performLogin = (data: any) => {
        setIsLoginError(false)

        dispatch(
            authLogin({
                payload: {
                    ...data,
                } as ILoginData,
                navigate,
                onError: () => setIsLoginError(true),
            })
        )
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Log In"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <img
                src={mainImage}
                alt="Nirvana Health"
                title="Nirvana Health"
                className="margin-x-center sm:w-150px"
            />

            <PrimaryText className="mt-32px" typography="h1" centered>
                Welcome back to <br />
                Nirvana Health
            </PrimaryText>

            <PrimaryText typography="subtitle" centered className="mt-32px">
                Insurance coverage solutions optimized for your specialty and
                scale.
            </PrimaryText>

            <form onSubmit={handleSubmit(performLogin)} noValidate={true}>
                <div className="mt-44px flex items-start md:block">
                    <InputElement
                        className="mr-24px flex-1 md:mr-0px"
                        name="email"
                        label="Email address"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                        innerInputClassName={
                            isLoginError ? "errored" : undefined
                        }
                    />

                    <InputElement
                        className="flex-1 md:mt-44px"
                        name="password"
                        label="Password"
                        type="password"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            minLength: VALIDATION_CONFIG.minLengthPassword,
                            maxLength: VALIDATION_CONFIG.maxLengthPassword,
                        }}
                        innerInputClassName={
                            isLoginError ? "errored" : undefined
                        }
                    />
                </div>

                <div className="mt-32px flex items-center">
                    <div className="flex-1 mr-16px">
                        <Link to={ROUTES_CONFIG.restoreUrl}>
                            Forgot password?
                        </Link>
                    </div>

                    {isLoginError ? (
                        <div className="mr-24px">
                            <PrimaryText color={NIRVANA_COLORS.brand.error}>
                                Hmmm… wrong credentials provided. <br />
                                Please try again.
                            </PrimaryText>
                        </div>
                    ) : null}

                    <ButtonElement
                        label="Log in"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                    />
                </div>

                <FixedTopRightActionElement
                    ctaAction={() => navigate(ROUTES_CONFIG.registerUrl)}
                    ctaLabel="Create an Account"
                    additionalLabel="Don’t have an login?"
                    className="z-201 md:static! md:mt-32px"
                />
            </form>
        </div>
    )
}
