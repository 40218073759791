/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { PageHelmetElement, useScrollFix } from "nirvana-react-elements"
import { useSearchParams } from "react-router-dom"

import {
    PoliciesViewType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { SearchFilterFormComponent } from "./searchFilterForm.component"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { SearchFilterDisplayComponent } from "./searchFilterDisplay.component"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { policiesSetHistoryFilters } from "../../../store/slices/policies.slice"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { PoliciesColumnsManagementComponent } from "../policiesList/policiesColumnsManagement.component"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { BulkOverridesComponent } from "../overrides/bulkOverrides/bulkOverrides.component"

export const SearchPoliciesComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions([
        AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
    ])

    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    const {
        // vars
        isOverridesEnabled,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const { historyFilters } = useAppSelector(policiesSelector)

    const scrollableTableRef = useRef<HTMLDivElement>(null)
    const scrollableTableBoundaries =
        useScrollableElementBoundaries(scrollableTableRef)

    const [filtersSubmitted, setFiltersSubmitted] = useState<boolean>(
        !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.ignoreListReload)
    )

    const [filtersFormKey, setFiltersFormKey] = useState<number>(
        new Date().getTime()
    )

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return canManageOverrides && isOverridesEnabled
    }, [isOverridesEnabled, canManageOverrides])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            BrowserStorageHelper.getDefaultHistoricalPoliciesColumnsConfiguration(
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        // Deselect everything on filters change
        setSelectedPolicies([])

        setFiltersSubmitted(false)
    }

    const onResetFilters = async () => {
        await dispatch(policiesSetHistoryFilters({}))
        setFiltersFormKey(new Date().getTime())
    }

    const onFiltersSubmitted = async (data: IPoliciesListFiltersData) => {
        await dispatch(policiesSetHistoryFilters(data))
        setFiltersSubmitted(true)
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        BrowserStorageHelper.saveColumnsConfigurations(
            GENERAL_CONFIG.browserStorageKeys
                .historicalPoliciesColumnsConfiguration,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    return selectedPracticeRole ? (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Search"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {filtersSubmitted &&
            Object.keys(historyFilters).filter(key => !!key).length ? (
                <>
                    <div
                        className="pt-16px pb-24px px-24px relative flex justify-between items-end"
                        style={{
                            right: scrollableTableBoundaries?.x,
                        }}
                    >
                        <SearchFilterDisplayComponent
                            className="w-380px"
                            onEditFilters={onEditFilters}
                            existingFilters={historyFilters}
                        />

                        {columnsConfiguration.length ? (
                            <PoliciesColumnsManagementComponent
                                initialColumnsConfiguration={PoliciesHelper.getDefaultHistoricalChecksColumns(
                                    selectedPracticeRole.availableModalities
                                )}
                                currentColumnsConfiguration={
                                    columnsConfiguration
                                }
                                onColumnsConfigurationChange={
                                    onSaveColumnsConfiguration
                                }
                                isRightPlacement
                            />
                        ) : null}
                    </div>

                    {columnsConfiguration.length ? (
                        <PoliciesListComponent
                            viewType={PoliciesViewType.HISTORY_SEARCH}
                            sortEnabled={true}
                            columns={columnsConfiguration}
                            scrollableElementRef={scrollableTableRef}
                            onChangeColumnOrder={onChangeColumnOrder}
                            policiesSelectable={listPoliciesSelectable}
                            selectedItems={selectedPolicies}
                            onSelectedItems={onPoliciesSelected}
                            onDeselectedItems={onPoliciesDeselected}
                        />
                    ) : null}

                    {showBulkOverrideButton && (
                        <BulkOverridesComponent
                            coverageChecksType={PoliciesViewType.HISTORY_SEARCH}
                            onCancelBulkOverride={onDeselectAllPolicies}
                            coverageChecksToOverride={selectedPolicies}
                            setCoverageChecksToOverride={setSelectedPolicies}
                        />
                    )}
                </>
            ) : (
                <SearchFilterFormComponent
                    key={filtersFormKey}
                    className="mt-48px px-24px w-992px md:w-full"
                    onFiltersSubmitted={onFiltersSubmitted}
                    existingFilters={historyFilters}
                    onResetFilters={onResetFilters}
                    isRequired
                    withDefaultDateRange
                />
            )}
        </div>
    ) : null
}
