import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class CoverageHistoryCommentsService {
    /**
     * Get list of comments associated with patient or policy
     */
    static async getList(
        practice: IPractice,
        patient?: IPatientIdentificationData,
        policy?: IPolicyIdentificationData
    ): Promise<IPolicyComment[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COMMENTS_GET_LIST.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                // Because GET
                ...(patient
                    ? {
                          patient: JSON.stringify(patient),
                      }
                    : {}),
                ...(policy
                    ? {
                          policy: JSON.stringify(policy),
                      }
                    : {}),
            }
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create patient/policy comment
     */
    static async create(
        practice: IPractice,
        comment: string,
        patient?: IPatientIdentificationData,
        policy?: IPolicyIdentificationData,
        replyToCommentId?: number
    ): Promise<IPolicyComment | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COMMENTS_CREATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                comment,
                patient,
                policy,
                replyToCommentId,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Update patient/policy comment
     */
    static async update(
        practice: IPractice,
        commentId: number,
        comment: string
    ): Promise<IPolicyComment | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COMMENTS_UPDATE_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":commentId", commentId.toString()),
            {
                comment,
            },
            "PUT"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete patient/policy comment
     */
    static async delete(
        practice: IPractice,
        commentId: number
    ): Promise<
        | {
              deletedId: number
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COMMENTS_DELETE_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":commentId", commentId.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
