import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState: IOrganizationState = {
    users: [],
    invites: [],
}

const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        organizationReset: () => initialState,

        organizationUsersSetList: (
            state,
            action: PayloadAction<IOrganizationUser[]>
        ) => {
            state.users = action.payload
        },

        organizationUsersDeleteDone: (
            state,
            action: PayloadAction<IOrganizationUser>
        ) => {
            state.users = state.users.filter(
                item => item.id !== action.payload.id
            )
        },

        organizationUsersUpdateDone: (
            state,
            action: PayloadAction<IOrganizationUser>
        ) => {
            const newItems = [...state.users]

            const neededItemIndex = newItems.findIndex(
                item => item.id === action.payload.id
            )

            if (~neededItemIndex) {
                newItems[neededItemIndex] = action.payload
            }

            state.users = newItems
        },

        organizationInvitesSetList: (
            state,
            action: PayloadAction<IOrganizationUserInvite[]>
        ) => {
            state.invites = action.payload
        },

        organizationInvitesCreateDone: (
            state,
            action: PayloadAction<IOrganizationUserInvite>
        ) => {
            state.invites = [...state.invites, action.payload]
        },

        organizationInvitesDeleteDone: (
            state,
            action: PayloadAction<IOrganizationUserInvite>
        ) => {
            state.invites = state.invites.filter(
                item => item.id !== action.payload.id
            )
        },

        organizationInvitesUpdateDone: (
            state,
            action: PayloadAction<IOrganizationUserInvite>
        ) => {
            const newItems = [...state.invites]

            const neededItemIndex = newItems.findIndex(
                item => item.id === action.payload.id
            )

            if (~neededItemIndex) {
                newItems[neededItemIndex] = action.payload
            }

            state.invites = newItems
        },
    },
})

export const {
    organizationReset,
    ///////////////////////////////////////// users
    organizationUsersSetList,
    organizationUsersDeleteDone,
    organizationUsersUpdateDone,
    ///////////////////////////////////////// invites
    organizationInvitesSetList,
    organizationInvitesCreateDone,
    organizationInvitesDeleteDone,
    organizationInvitesUpdateDone,
} = organizationSlice.actions

export const organizationReducer = organizationSlice.reducer
