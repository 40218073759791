/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    InputElement,
    PrimaryText,
    VALIDATION_CONFIG,
    ButtonElement,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"

import { billerGroupsUpdate } from "../../../store/thunks/billerGroups.thunks"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import editIcon from "../../../assets/images/icons/edit-dark-third.svg"
import closeIcon from "../../../assets/images/icons/close-dark-lighter.svg"

export const BillerGroupNameComponent: React.FunctionComponent<
    IBillerGroupNameComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const [isEdit, setIsEdit] = useState<boolean>(!!props.isDefaultEdit)

    const isLoading = useMemo(
        () =>
            RuntimeHelper.isBillerGroupsCreateLoading() ||
            RuntimeHelper.isBillerGroupsUpdateLoading(),
        [runtimeState.isLoading]
    )

    // Parent control for inner edit state
    useEffect(() => {
        if (!props.editCancelTrigger) {
            return
        }

        setIsEdit(false)
    }, [props.editCancelTrigger])

    // Notify parent once isEdit changes
    useEffect(() => {
        props.onEditStateChange?.(isEdit)
    }, [isEdit])

    const onEditCancel = () => {
        props.onEditCancel?.()
        setIsEdit(false)
    }

    // Existing biller group will be updated with new name
    // New one will be created in parent
    const onEditSubmit = (data: any) => {
        const { name } = data

        if (!selectedPracticeRole || !name) {
            return
        }

        if (props.existingBillerGroup) {
            dispatch(
                billerGroupsUpdate({
                    payload: {
                        billerGroupId: props.existingBillerGroup.id,
                        data: {
                            name,
                        },
                    },
                    practice: selectedPracticeRole.practice,
                    onSuccess: () => {
                        setIsEdit(false)
                    },
                })
            )
        } else {
            props.onEditSubmit?.(name)
        }
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {isEdit ? (
                <div className="flex">
                    <div className="relative flex-1 mr-24px">
                        <InputElement
                            name="name"
                            innerInputClassName="pr-84px"
                            placeholder="New Biller Group"
                            defaultValue={props.existingBillerGroup?.name}
                            reactHookErrors={errors}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            disabled={isLoading}
                        />

                        {props.existingBillerGroup && !isLoading && (
                            <div className="absolute z-100 flex items-center right-12px top-4px">
                                <div
                                    className="cursor-pointer px-5px py-12px"
                                    onClick={onEditCancel}
                                >
                                    <img src={closeIcon} alt="Cancel" />
                                </div>
                            </div>
                        )}
                    </div>

                    <ButtonElement
                        label="Save"
                        htmlType="button"
                        type="primary"
                        onClick={handleSubmit(onEditSubmit)}
                        isLoading={isLoading}
                    />
                </div>
            ) : (
                <div className="flex items-center">
                    <PrimaryText className="mr-12px" typography="h3">
                        {props.existingBillerGroup?.name || "No name"}
                    </PrimaryText>

                    <div
                        className="cursor-pointer"
                        onClick={() => setIsEdit(true)}
                    >
                        <img src={editIcon} alt="Edit" />
                    </div>
                </div>
            )}
        </div>
    )
}
