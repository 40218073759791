import { ISelectRenderedOption } from "nirvana-react-elements"

export enum AvailableApplicationRole {
    nirvanaAdmin = "nirvanaAdmin",
    practiceOwner = "practiceOwner",
    practiceAdmin = "practiceAdmin",
    practiceBiller = "practiceBiller",
}

export enum PermissionsExistenceLogicalOperator {
    AND = "AND",
    OR = "OR",
}

export enum AvailableCoveragePortalPermission {
    useEagleEye = "useEagleEye", // feature that allows to act as other user

    runCoverageChecks = "runCoverageChecks",
    runCsvBulkCoverageChecks = "runCsvBulkCoverageChecks",
    reportCoverageCheck = "reportCoverageCheck",
    managePracticeUsers = "managePracticeUsers",
    readPayersConfiguration = "readPayersConfiguration",
    managePayersConfiguration = "managePayersConfiguration",
    transferPracticeOwnership = "transferPracticeOwnership",
    manageBilling = "manageBilling",
    manageBillerGroups = "manageBillerGroups",
    resolveFlags = "resolveFlags",
    managePolicyFlags = "managePolicyFlags",
    viewHistoricalCoverageChecks = "viewHistoricalCoverageChecks",
    overrideCoverageData = "overrideCoverageData",
    receivePracticeWideSocketsEvents = "receivePracticeWideSocketsEvents",
    viewPlanYearResets = "viewPlanYearResets",
}

export const ROLES_PERMISSIONS_CONFIG = {
    inviteRenderedRoles: [
        {
            value: AvailableApplicationRole.practiceAdmin,
            displayValue: "Admin",
            dropdownDisplayValue: "Admin",
        },
        {
            value: AvailableApplicationRole.practiceBiller,
            displayValue: "Biller",
            dropdownDisplayValue: "Biller",
        },
    ] as ISelectRenderedOption[],
}
