/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { ConfirmationHelper, PrimaryText } from "nirvana-react-elements"

import {
    billerGroupsGetSelected,
    billerGroupsUpdateUsers,
} from "../../../store/thunks/billerGroups.thunks"
import { billerGroupsSelectUser } from "../../../store/slices/billerGroups.slice"
import { UpdateUsersInGroupOperation } from "../../../config/organization.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { billerGroupsSelector } from "../../../store/selectors/billerGroups.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import closeIcon from "../../../assets/images/icons/close-dark.svg"
import trashIcon from "../../../assets/images/icons/trash-dark.svg"

export const BillerGroupUsersInformationComponent: React.FunctionComponent<
    IBillerGroupUsersInformationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const billerGroups = useAppSelector(billerGroupsSelector)

    const onDeselectUser = () => {
        dispatch(billerGroupsSelectUser())
    }

    const onRemoveUserFromGroup = (group: IBillerGroup) => {
        if (!selectedPracticeRole) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                dispatch(
                    billerGroupsUpdateUsers({
                        payload: {
                            billerGroupId: group.id,
                            data: {
                                userIds: [props.user.id],
                                operation: UpdateUsersInGroupOperation.REMOVE,
                            },
                        },

                        practice: selectedPracticeRole.practice,

                        onSuccess: () => {
                            // Deselect user on success - if biller group that was removed from user is active one
                            // Otherwise reload active biller group

                            if (
                                !billerGroups.selectedItem?.id ||
                                !selectedPracticeRole
                            ) {
                                return
                            }

                            if (group.id === billerGroups.selectedItem.id) {
                                onDeselectUser()
                            } else {
                                dispatch(
                                    billerGroupsGetSelected({
                                        payload: {
                                            billerGroupId:
                                                billerGroups.selectedItem.id,
                                            ignoreSelectedUserReset: true,
                                        },
                                        practice: selectedPracticeRole.practice,
                                        ignorePreloader: true,
                                    })
                                )
                            }
                        },
                    })
                )
            },
            undefined,
            `User "${
                props.user.name || "No name"
            }" will be permanently removed from biller group`
        )
    }

    return (
        <div
            className={`
                relative bg-brand-warmLight05 h-full overflow-y-auto
                md:fixed md:top-0 md:left-0 md:w-screen md:h-screen md:bg-brand-white md:z-20
                ${props.className}
            `}
        >
            <div className="bg-brand-white w-full p-24px">
                <PrimaryText typography="subtitleSemibold">
                    {props.user.name || "No Name"}
                </PrimaryText>

                <div className="mt-16px">
                    {props.user.billerGroups.map((group, index) => (
                        <div
                            key={index}
                            className="
                                py-12px flex items-center
                                border-t border-solid border-brand-warmLight
                            "
                        >
                            <PrimaryText className="flex-1 mr-16px">
                                {group.name}
                            </PrimaryText>

                            <div
                                className="flex items-center cursor-pointer"
                                onClick={() => onRemoveUserFromGroup(group)}
                            >
                                <PrimaryText
                                    typography="textSemibold"
                                    className="mr-16px"
                                >
                                    Remove
                                </PrimaryText>

                                <img src={trashIcon} alt="Remove" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className="absolute cursor-pointer z-10 top-0 right-0 px-24px py-20px"
                onClick={onDeselectUser}
            >
                <img src={closeIcon} alt="Close" />
            </div>
        </div>
    )
}
