import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    organizationInvitesCreateDone,
    organizationInvitesDeleteDone,
    organizationInvitesSetList,
    organizationInvitesUpdateDone,
    organizationUsersDeleteDone,
    organizationUsersSetList,
    organizationUsersUpdateDone,
} from "../slices/organization.slice"
import { OrganizationUsersService } from "../../services/organizationUsers.service"
import { ToastrHelper } from "../../helpers/toastr.helper"

/**
 * Get list of organization users
 */
export const organizationUsersGetList = createAsyncThunk<
    IOrganizationUser[],
    IThunkActionWithPracticeOrIdData<undefined>
>("organizationUsersGetListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationUsersGetListLoading"))

    try {
        const result = await OrganizationUsersService.getUsersList(
            action.practice
        )

        if (result) {
            dispatch(organizationUsersSetList(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || []
    } catch (e) {
        return []
    } finally {
        dispatch(runtimeStopLoading("organizationUsersGetListLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Delete organization user
 */
export const organizationUsersDelete = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IOrganizationUser>
>("organizationUsersDeletePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationUsersDeleteLoading"))

    try {
        const result = await OrganizationUsersService.deleteUser(
            action.practice,
            action.payload
        )

        if (result) {
            dispatch(organizationUsersDeleteDone(action.payload))

            ToastrHelper.success(
                "User was successfully removed from organization"
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationUsersDeleteLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Change role of organization user
 */
export const organizationUsersChangeRole = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IOrganizationUsersChangeRoleData>
>("organizationUsersChangeRolePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationUsersChangeRoleLoading"))

    try {
        const result = await OrganizationUsersService.changeUserRole(
            action.practice,
            action.payload.user,
            action.payload.role
        )

        if (result) {
            dispatch(organizationUsersUpdateDone(result))

            ToastrHelper.success("User's role was successfully changed")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationUsersChangeRoleLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Get list of user invites
 */
export const organizationInvitesGetList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<undefined>
>("organizationInvitesGetListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationInvitesGetListLoading"))

    try {
        const result = await OrganizationUsersService.getInvitesList(
            action.practice
        )

        if (result) {
            dispatch(organizationInvitesSetList(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationInvitesGetListLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Invite user to organization
 */
export const organizationInvitesCreate = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IOrganizationUsersInviteData>
>("organizationInvitesCreatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationInvitesCreateLoading"))

    try {
        const result = await OrganizationUsersService.inviteUser(
            action.practice,
            action.payload.email,
            action.payload.role,
            action.payload.billerGroupIds
        )

        if (result) {
            dispatch(
                result.isNew
                    ? organizationInvitesCreateDone(result.invite)
                    : organizationInvitesUpdateDone(result.invite)
            )

            ToastrHelper.success(
                "User was successfully invited to join your organization"
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationInvitesCreateLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Delete user invite
 */
export const organizationInvitesDelete = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IOrganizationUserInvite>
>("organizationInvitesDeletePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationInvitesDeleteLoading"))

    try {
        const result = await OrganizationUsersService.deleteUserInvite(
            action.practice,
            action.payload
        )

        if (result) {
            dispatch(organizationInvitesDeleteDone(action.payload))

            ToastrHelper.success("User invitation was successfully removed")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationInvitesDeleteLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Change role of user invite
 */
export const organizationInvitesChangeRole = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IOrganizationInviteChangeRoleData>
>("organizationInvitesChangeRolePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("organizationInvitesChangeRoleLoading"))

    try {
        const result = await OrganizationUsersService.changeUserInviteRole(
            action.practice,
            action.payload.invite,
            action.payload.role
        )

        if (result) {
            dispatch(organizationInvitesUpdateDone(result))

            ToastrHelper.success(
                "User invitation's role was successfully changed"
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("organizationInvitesChangeRoleLoading"))

        action.onFinally && action.onFinally()
    }
})
