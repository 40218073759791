import React from "react"
import { PrimaryText, AvatarElement } from "nirvana-react-elements"

export const InvitedPracticeElement: React.FunctionComponent<
    InvitedPracticeElementProps
> = props => {
    return (
        <div
            className={`
                px-12px py-8px rounded-8px
                flex items-center bg-brand-white
                border border-solid border-brand-warmShadow
                ${props.className}
            `}
        >
            <AvatarElement
                className="mr-8px"
                width={32}
                fontClassName="pt-3px fs-13px lh-13px"
                user={props.practice}
            />

            <PrimaryText className="pt-3px" typography="textSemibold">
                {props.practice.name}
            </PrimaryText>
        </div>
    )
}
