/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { ChartData, TooltipItem } from "chart.js"

import { DoughnutChartElement } from "../../../elements/doughnutChart.element"

export const ReportsStatusCodesChartComponent: React.FunctionComponent<
    IReportsStatusCodesChartComponentProps
> = props => {
    const chartData = useMemo<ChartData<"doughnut", number[], any>>(() => {
        return {
            labels: props.data.map(item => item.label),
            datasets: [
                {
                    data: props.data.map(item => item.value),
                    backgroundColor: props.data.map(item => item.color),
                    borderWidth: 0,
                },
            ],
        }
    }, [props.data])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
            style={{
                width: `${props.widthPercentage || 100}%`,
            }}
        >
            <DoughnutChartElement
                data={chartData}
                redraw={false}
                hoveredChartLabel={props.hoveredLabel}
                tooltipGetLabelCallback={(item: TooltipItem<"doughnut">) => {
                    const config = props.data.find(
                        inner => inner.value === item.parsed
                    )

                    if (!config) {
                        return item.parsed.toString()
                    }

                    return `${item.parsed} (${config.percentage}%)`
                }}
            />
        </div>
    )
}
