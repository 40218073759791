import socketIOClient, { Socket } from "socket.io-client"

import { AuthHelper } from "./auth.helper"
import { SOCKETS_CONFIG, SocketEvent } from "../config/sockets.config"
import { ToastrHelper } from "./toastr.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { policiesNewCommentReceivedThroughSockets } from "../store/thunks/policyComments.thunks"

let instance: SocketsHelper

export class SocketsHelper {
    io: Socket = {} as Socket

    // Right now we are not connecting therapists to any additional rooms
    // Just to default that are handled during connection in API gateway
    // But in the future we might want to add them to some other rooms
    // So implemented this just in case
    additionalSubscribedRooms: { room: string; data: any }[] = []

    reconnectionAttempts: number = 0

    /**
     * Get singleton instance of helper
     */
    static getInstance(): SocketsHelper {
        if (!instance) {
            instance = new SocketsHelper()
            instance.io = socketIOClient(
                `${process.env.REACT_APP_SOCKETS_URL}`,
                SOCKETS_CONFIG.defaultSocketIOConfig
            )
        }

        return instance
    }

    /**
     * Check if sockets are connected right now
     */
    isConnected(): boolean {
        if (!this.io || !this.io.connect) {
            return false
        }

        return this.io.connected
    }

    /**
     * Reconnect sockets
     */
    async reconnect() {
        if (!this.io || !this.io.connect) {
            return
        }

        this.disconnect()

        this.io.io.opts.query = {}

        // Add jwt token if it exists to our connection query
        // So we can identify user
        const jwtToken = await AuthHelper.getToken()

        if (jwtToken) {
            this.io.io.opts.query.jwtToken = jwtToken
        }

        this.io.connect()

        this.io.on("connect", () => {
            // console.log("Successfully connected!")

            this.reconnectionAttempts = 0
            this.setEventsProcessors()

            // Rejoin needed rooms (that were previously connected)
            for (let i = 0; i < this.additionalSubscribedRooms.length; i++) {
                this.subscribeToRoom(
                    this.additionalSubscribedRooms[i].room,
                    this.additionalSubscribedRooms[i].data
                )
            }
        })

        this.io.on("connect_error", () => {
            this.reconnectionAttempts++
        })
    }

    /**
     * Disconnect sockets
     */
    disconnect(clearAdditionalRooms = false) {
        try {
            if (!this.io || !this.io.connect) {
                return
            }

            this.io.disconnect()
            this.reconnectionAttempts = 0

            if (clearAdditionalRooms) {
                this.additionalSubscribedRooms = []
            }
        } catch (e) {
            // console.log(e)
        }
    }

    /**
     * Subscribe somebody to room
     */
    subscribeToRoom(room: string, data: any = {}) {
        this.io.emit(SocketEvent.coveragePortalJoinRoom, { room, data })

        // Check if such room already exists in our array, if not push it there for future use
        const existing = this.additionalSubscribedRooms.find(
            item =>
                item.room === room &&
                JSON.stringify(item.data) === JSON.stringify(data)
        )

        if (existing) {
            return
        }

        this.additionalSubscribedRooms.push({
            room,
            data,
        })
    }

    /**
     * Unsubscribe somebody from room
     */
    unsubscribeFromRoom(room: string, data: any = {}) {
        this.io.emit(SocketEvent.coveragePortalLeaveRoom, room, data)

        this.additionalSubscribedRooms = this.additionalSubscribedRooms.filter(
            item =>
                !(
                    item.room === room &&
                    JSON.stringify(item.data) === JSON.stringify(data)
                )
        )
    }

    /**
     * Set event processors for socket io
     */
    private setEventsProcessors() {
        // SINGLE TOAST MESSAGE FROM BACKGROUND
        !this.io.hasListeners(SocketEvent.coveragePortalSingleToastMsg) &&
            this.io.on(
                SocketEvent.coveragePortalSingleToastMsg,
                (payload: IToastMsgSocketsPayload) => {
                    let toastHandler:
                        | ((
                              msg: string,
                              timeOut?: number,
                              withHtml?: boolean
                          ) => void)
                        | undefined = undefined

                    switch (payload.type) {
                        case "success":
                            toastHandler = ToastrHelper.success
                            break

                        case "warning":
                            toastHandler = ToastrHelper.warning
                            break

                        case "error":
                            toastHandler = ToastrHelper.error
                            break
                    }

                    if (!toastHandler) {
                        return
                    }

                    toastHandler(
                        payload.msg,
                        // Extended by default since user isn't expecting these error messages in interface
                        payload.timeOut || GENERAL_CONFIG.extendedToastrTimeout,
                        payload.withHtml
                    )
                }
            )

        // NEW COMMENT THROUGH SOCKETS
        !this.io.hasListeners(SocketEvent.coveragePortalPolicyCommentCreated) &&
            this.io.on(
                SocketEvent.coveragePortalPolicyCommentCreated,
                (payload: IPolicyComment) => {
                    SocketsHelper.dispatch(
                        policiesNewCommentReceivedThroughSockets({
                            payload,
                        })
                    )
                }
            )
    }

    /**
     * Dispatch data to store
     */
    private static dispatch(action: any) {
        const dispatch = require("../store/store").store.dispatch

        dispatch(action)
    }
}
