import {
    ISelectRenderedOption,
    LookupHelper as NirvanaLookupHelper,
    PrimaryText,
} from "nirvana-react-elements"

export class LookupHelper extends NirvanaLookupHelper {
    /**
     * Convert  object to array of rendered select options
     */
    static getRenderedOptionsFromObject<Key extends string | number>(
        values: Partial<Record<Key, string>>,
        ignoredKeys: Key[] = []
    ): ISelectRenderedOption[] {
        return Object.keys(values)
            .filter(key => !ignoredKeys.includes(key as Key))
            .map(key => ({
                key,
                displayValue: values[key],
                value: key,
                dropdownDisplayValue: values[key],
            }))
    }

    /**
     * Get rendered simplified or full Practice value for dropdown
     */
    static getRenderedPractice(
        item: IPractice | ISimplifiedPractice
    ): ISelectRenderedOption {
        const displayValue = item.name

        return {
            displayValue,
            dropdownDisplayValue: <PrimaryText>{displayValue}</PrimaryText>,
            dropdownDisplayValueAdditional: (
                <div>
                    <PrimaryText typography="caption">
                        ID: {item.id}
                    </PrimaryText>
                    <PrimaryText typography="caption">
                        Group NPI: {item.groupNPI}
                    </PrimaryText>
                </div>
            ),
            value: item,
        }
    }

    /**
     * Get rendered organization user value for dropdown
     */
    static getRenderedOrganizationUser(
        item: IOrganizationUser
    ): ISelectRenderedOption {
        const displayValue = item.name || item.email

        return {
            displayValue,
            dropdownDisplayValue: <PrimaryText>{displayValue}</PrimaryText>,
            dropdownDisplayValueAdditional: (
                <div>
                    <div className="flex items-center justify-between">
                        <PrimaryText typography="caption">
                            ID: {item.id}
                        </PrimaryText>
                        <PrimaryText typography="caption">
                            {item.email}
                        </PrimaryText>
                    </div>
                    <PrimaryText typography="caption">
                        Role: {item.role?.label}
                    </PrimaryText>
                </div>
            ),
            value: item,
        }
    }
}
