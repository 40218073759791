/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { PrimaryText, ScreenTabsElement } from "nirvana-react-elements"

import {
    BulkOverrideTab,
    OVERRIDES_CONFIG,
} from "../../../../config/overrides.config"
import { CoverageHistoryHelper } from "../../../../helpers/coverageHistory.helper"
import { PoliciesListTableComponent } from "../../policiesList/policiesListTable.component"
import { POLICIES_COLUMNS_CONFIG } from "../../../../config/policiesColumns.config"
import { PoliciesViewType } from "../../../../config/policies.config"

export const BulkOverridesPreviewComponent: React.FunctionComponent<
    IBulkOverridesPreviewComponentProps
> = props => {
    const tableWrapperRef = useRef<HTMLDivElement>(null)

    const [neededColumns] = useState<IPolicyColumnConfiguration[]>(
        POLICIES_COLUMNS_CONFIG.overridesPreviewPoliciesColumns.map(item => ({
            ...item,

            isEnabled: true,
        }))
    )

    const [activeTab, setActiveTab] = useState<IBulkOverrideAvailableTab>(
        OVERRIDES_CONFIG.bulkOverrides.availableTabs.find(
            item => item.key === BulkOverrideTab.allowed
        ) as IBulkOverrideAvailableTab
    )

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const isCollapsible = useMemo<boolean>(() => {
        return activeTab.key === BulkOverrideTab.allowed && isExpanded
    }, [activeTab.key, isExpanded])

    const groupedCoverageChecks = useMemo<
        Record<
            BulkOverrideTab,
            {
                fullList: ICoverageCheckHistory[]
                limitedList?: ICoverageCheckHistory[]
            }
        >
    >(() => {
        const allowedFullList = props.coverageChecks.filter(
            item => !!CoverageHistoryHelper.getPolicyIdentificationData(item)
        )

        const requiredDataMissingFullList = props.coverageChecks.filter(
            item => !CoverageHistoryHelper.getPolicyIdentificationData(item)
        )

        return {
            [BulkOverrideTab.allowed]: {
                fullList: allowedFullList,
                limitedList: allowedFullList.slice(
                    0,
                    OVERRIDES_CONFIG.bulkOverrides.defaultPreviewCount
                ),
            },
            [BulkOverrideTab.requiredDataMissing]: {
                fullList: requiredDataMissingFullList,
            },
        }
    }, [props.coverageChecks])

    const { isExpandable, showMoreCount } = useMemo<{
        isExpandable: boolean
        showMoreCount: number
    }>(() => {
        const { limitedList, fullList } = groupedCoverageChecks[activeTab.key]

        return {
            isExpandable:
                !!limitedList?.length && fullList.length > limitedList.length,
            showMoreCount: limitedList?.length
                ? fullList.length - limitedList.length
                : 0,
        }
    }, [activeTab.key])

    // Notify parent about active tab change
    useEffect(() => {
        props.onPreviewTabChange(activeTab.key)

        // Expand by default not allowed list
        setIsExpanded(activeTab.key !== BulkOverrideTab.allowed)
    }, [activeTab])

    const onToggleIsExpanded = () => {
        setIsExpanded(current => !current)
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div className="px-46px">
                <PrimaryText typography="h4" className="mb-16px">
                    Policies Details
                </PrimaryText>

                {groupedCoverageChecks[BulkOverrideTab.requiredDataMissing]
                    .fullList.length ? (
                    <ScreenTabsElement
                        className="w-840px mb-8px mx-auto md:w-full"
                        currentTab={activeTab}
                        tabs={OVERRIDES_CONFIG.bulkOverrides.availableTabs}
                        onSelected={tab =>
                            setActiveTab(tab as IBulkOverrideAvailableTab)
                        }
                    />
                ) : null}
            </div>

            {/*IF scrolled vertically table - it will be converted to full page width*/}
            {/*Since there will be sticky header that is fixed and overflows this wrapper*/}
            <div
                className={`
                    transition-all relative overflow-hidden
                    ${isExpanded ? "px-0" : "px-46px"}
                `}
            >
                <div
                    ref={tableWrapperRef}
                    className={`
                        sticky-wrapper
                        transition-all
                        pt-8px px-16px bg-brand-white overflow-auto
                        ${
                            isCollapsible || (isExpandable && !isExpanded)
                                ? "pb-40px"
                                : "pb-8px"
                        }
                        ${
                            isExpanded
                                ? "max-h-600px"
                                : "max-h-500px rounded-8px"
                        }
                    `}
                >
                    <PoliciesListTableComponent
                        className="w-full"
                        policies={
                            isExpanded
                                ? groupedCoverageChecks[activeTab.key].fullList
                                : groupedCoverageChecks[activeTab.key]
                                      .limitedList ||
                                  groupedCoverageChecks[activeTab.key].fullList
                        }
                        columns={neededColumns}
                        viewType={PoliciesViewType.OVERRIDES}
                        ignoreNavigateToPolicy
                        ignoreLastItemBorder
                        ignoreHorizontalPadding
                        forceHeaderStickiness
                        stickyHeaderVerticalScrollElementSelector=".sticky-wrapper"
                        stickyHeaderHorizontalScrollElement={tableWrapperRef}
                    />
                </div>

                {isCollapsible || (isExpandable && !isExpanded) ? (
                    <div
                        className={`
                            z-10 relative top--32px left-0 w-full bg-brand-lilacLight h-32px
                            flex items-center cursor-pointer pl-16px
                            ${!isExpanded ? "rounded-b-lg" : ""}
                        `}
                        onClick={onToggleIsExpanded}
                    >
                        <PrimaryText typography="h6">
                            {!isExpanded
                                ? `Show ${showMoreCount} more`
                                : isCollapsible
                                ? "Click to collapse"
                                : null}
                        </PrimaryText>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
