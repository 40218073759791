import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { ISupportContentCategory, UtilHelper } from "nirvana-react-elements"

import { SUPPORT_CONFIG } from "../../config/support.config"

const initialState: ISupportState = {
    selectedContentCategory: null,
}

const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {
        supportReset: () => initialState,

        supportSetSelectedContentCategory: (
            state,
            action: PayloadAction<ISupportContentCategory | null>
        ) => {
            // Modify url when some article is selected
            if (action.payload) {
                UtilHelper.addUrlQueryParam(
                    SUPPORT_CONFIG.supportArticleUrlQueryKey,
                    action.payload.urlSlug
                )
            }

            state.selectedContentCategory = action.payload
        },
    },
})

export const { supportReset, supportSetSelectedContentCategory } =
    supportSlice.actions

export const supportReducer = supportSlice.reducer
