/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UtilHelper } from "nirvana-react-elements"

import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../config/rolesPermissions.config"
import { ROUTES_CONFIG } from "../config/routes.config"
import { PermissionsHelper } from "../helpers/permissions.helper"
import { useAppSelector } from "../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../store/selectors/selectedPracticeRole.selector"

export const useHasPermissions = (
    accessAllowedPermissions:
        | AvailableCoveragePortalPermission
        | AvailableCoveragePortalPermission[],
    permissionsExistenceOperator?: PermissionsExistenceLogicalOperator,
    withRedirectOnMissingPermission = true
) => {
    const navigate = useNavigate()
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [hasPermission, setHasPermission] = useState<boolean>(false)

    // Check if user has permissions to access the page basically
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const localHasPermission = PermissionsHelper.hasPermission(
            accessAllowedPermissions,
            selectedPracticeRole,
            permissionsExistenceOperator
        )

        setHasPermission(localHasPermission)

        !localHasPermission &&
            withRedirectOnMissingPermission &&
            UtilHelper.redirectTo(ROUTES_CONFIG.baseApplicationUrl, navigate)
    }, [selectedPracticeRole])

    return hasPermission
}
