import React from "react"
import { Link } from "react-router-dom"
import {
    ButtonElement,
    PrimaryText,
    useScrollFix,
    PageHelmetElement,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"

export const NotFoundComponent: React.FunctionComponent = () => {
    useScrollFix()

    return (
        <div className="primary-container mb-20px">
            <PageHelmetElement
                title="Not Found"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <PrimaryText typography="h1" centered>
                Oops...
            </PrimaryText>

            <PrimaryText typography="text" centered className="mt-24px">
                The page you are looking for does not exist.
            </PrimaryText>

            <div className="mt-32px flex justify-center">
                <Link to="/">
                    <ButtonElement
                        label="Go home"
                        type="primary"
                        htmlType="button"
                        size="large"
                    />
                </Link>
            </div>
        </div>
    )
}
