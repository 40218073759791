import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    billerGroupsCreateDone,
    billerGroupsDeleteDone,
    billerGroupsSelectSingle,
    billerGroupsSetList,
    billerGroupsUpdateDone,
} from "../slices/billerGroups.slice"
import { BillerGroupsService } from "../../services/billerGroups.service"
import { UtilHelper } from "../../helpers/util.helper"

/**
 * Get list of biller groups
 */
export const billerGroupsGetList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IPagination | undefined>
>("billerGroupsGetListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("billerGroupsGetListLoading"))

    try {
        const result = await BillerGroupsService.getList(
            action.practice,
            action.payload || UtilHelper.getPagination()
        )

        if (result) {
            dispatch(
                billerGroupsSetList({
                    items: result.items,
                    newPagination: result.pagination,
                })
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsGetListLoading"))
    }
})

/**
 * Get single biller group
 */
export const billerGroupsGetSelected = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        billerGroupId: number
        ignoreSelectedUserReset?: boolean
    }>
>("billerGroupsGetSelectedPrefix", async (action, { dispatch }) => {
    !action.ignorePreloader &&
        dispatch(runtimeStartLoading("billerGroupsGetSelectedLoading"))

    try {
        const result = await BillerGroupsService.getSingle(
            action.practice,
            action.payload.billerGroupId
        )

        if (result) {
            dispatch(
                billerGroupsSelectSingle({
                    item: result,
                    ignoreSelectedUserReset:
                        action.payload.ignoreSelectedUserReset,
                })
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsGetSelectedLoading"))
    }
})

/**
 * Create biller group
 */
export const billerGroupsCreate = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IBillerGroupUpsertRequestData>
>("billerGroupsCreatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("billerGroupsCreateLoading"))

    try {
        const result = await BillerGroupsService.create(
            action.practice,
            action.payload
        )

        if (result) {
            dispatch(billerGroupsCreateDone(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsCreateLoading"))
    }
})

/**
 * Update biller group
 */
export const billerGroupsUpdate = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        billerGroupId: number
        data: IBillerGroupUpsertRequestData
    }>
>("billerGroupsUpdatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("billerGroupsUpdateLoading"))

    try {
        const result = await BillerGroupsService.update(
            action.practice,
            action.payload.billerGroupId,
            action.payload.data
        )

        if (result) {
            dispatch(billerGroupsUpdateDone(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsUpdateLoading"))
    }
})

/**
 * Delete biller group
 */
export const billerGroupsDelete = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<number>
>("billerGroupsDeletePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("billerGroupsDeleteLoading"))

    try {
        const result = await BillerGroupsService.delete(
            action.practice,
            action.payload
        )

        if (result) {
            dispatch(billerGroupsDeleteDone(result.deletedId))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsDeleteLoading"))
    }
})

/**
 * Update users in biller group
 */
export const billerGroupsUpdateUsers = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        billerGroupId: number
        data: IBillerGroupUpdateUsersRequestData
    }>
>("billerGroupsUpdateUsersPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("billerGroupsUpdateUsersLoading"))

    try {
        const result = await BillerGroupsService.updateUsers(
            action.practice,
            action.payload.billerGroupId,
            action.payload.data
        )

        if (result) {
            dispatch(billerGroupsUpdateDone(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("billerGroupsUpdateUsersLoading"))
    }
})
