import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { GENERAL_CONFIG } from "../config/general.config"

export class AuthService {
    /**
     * Register user in application
     */
    static async register(data: IRegisterData): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_REGISTER,
            {
                ...(data.emailData
                    ? {
                          email: data.emailData.email,
                      }
                    : {}),
                ...(data.passwordData || {}),

                inviteToken: data.inviteToken,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Login user in app
     */
    static async login(
        data: ILoginData
    ): Promise<
        | { token?: string; errorData?: { needEmailVerification: boolean } }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_LOGIN,
            data,
            "post",
            undefined,
            undefined,
            GENERAL_CONFIG.extendedToastrTimeout,
            [400, 404]
        )

        return !UtilHelper.isEmptyObject(result) ? result : undefined
    }

    /**
     * Initiate forgot password flow
     */
    static async forgotPassword(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_FORGOT_PASSWORD,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Process forgot password flow
     */
    static async forgotPasswordProcess(
        data: IPasswordRestoreFinishData
    ): Promise<
        | { token?: string; errorData?: { needEmailVerification: boolean } }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_FORGOT_PASSWORD_PROCESS,
            data,
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check if email is available
     */
    static async emailAvailable(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_EMAIL_AVAILABLE,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Verify user email by code
     */
    static async verifyEmail(
        data: IVerifyEmailData
    ): Promise<{ token: string } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_VERIFY_EMAIL,
            data,
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Resend verification link to just registered user
     */
    static async resendVerificationLink(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_RESEND_VERIFY_RESEND,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Confirm user by email and verification code in Cognito
     */
    static async confirmUser(
        email: string,
        confirmationCode: string
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_CONFIRM_USER,
            {
                email,
                confirmationCode,
            },
            "post",
            undefined,
            undefined,
            GENERAL_CONFIG.extendedToastrTimeout
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check invite token of a user
     */
    static async checkInviteToken(
        inviteToken: string
    ): Promise<IOrganizationUserInvite | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_CHECK_INVITE_TOKEN.replace(
                ":inviteToken",
                inviteToken
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
