import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    calculatorReceivedCoverageResult,
    calculatorSetCoverageChecksSubmitted,
    calculatorSetInputData,
} from "../slices/calculator.slice"
import { CalculatorService } from "../../services/calculator.service"
import { CALCULATOR_CONFIG } from "../../config/calculator.config"
import { RootState } from "../store"
import { UtilHelper } from "../../helpers/util.helper"
import { ToastrHelper } from "../../helpers/toastr.helper"

/**
 * Run single coverage check for calculator
 */
const runSingleCoverageCheck = async (
    data: ICoverageInputData,
    practice: IPractice,
    dispatch: ThunkDispatch<unknown, unknown, any>,
    triesCount = 0
) => {
    const defaultErrorResult = CalculatorService.getDefaultErrorCoverageResult(
        data.id,
        data.memberId,
        data.payer,
        data.inNetworkCheck
    )

    try {
        // run here 2 checks if 2 checks were requested: inNetwork and outOfNetwork
        for (const networkType of ["inNetworkCheck", "outNetworkCheck"]) {
            if (!data[networkType]) {
                continue
            }

            const result = await CalculatorService.runCoverageCheck(
                data,
                practice,
                networkType === "inNetworkCheck"
            )

            if (!result && triesCount < CALCULATOR_CONFIG.maxGetEstimateRetry) {
                return await runSingleCoverageCheck(
                    data,
                    practice,
                    dispatch,
                    triesCount + 1
                )
            }

            dispatch(
                calculatorReceivedCoverageResult(result || defaultErrorResult)
            )
        }
    } catch (e) {
        // If errored -> set default error result
        dispatch(calculatorReceivedCoverageResult(defaultErrorResult))
    }
}

/**
 * Run bulk coverage checks for calculator
 */
export const calculatorRunBulkCoverageChecks = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<ICoverageInputData[]>
>(
    "calculatorRunBulkCoverageChecksPrefix",
    async (action, { dispatch, getState }) => {
        dispatch(runtimeStartLoading("calculatorRunBulkCoverageChecksLoading"))

        const globalState = getState() as RootState

        // IF it's internal user - we'll have extended batch size
        const batchSize = UtilHelper.isInternalUser(
            globalState.userDetails.profile
        )
            ? CALCULATOR_CONFIG.processBatchSizeExtended
            : CALCULATOR_CONFIG.processBatchSize

        try {
            // Sleep for X seconds here so breathing spinner stays longer during this process
            await UtilHelper.sleep(3)

            // Filter input data, it can have empty values
            // Need to copy these because by default they are immutable
            let inputData = JSON.parse(
                JSON.stringify(action.payload.filter(item => !!item))
            ) as ICoverageInputData[]

            if (!inputData.length) {
                return
            }

            // Generate unique id for each of the input data array item
            // This id will be used for listening for results and mapping to needed input data
            try {
                inputData = inputData.map(item => {
                    item.id =
                        item.id ||
                        `${item.memberId}_${UtilHelper.generateUniqueVarchar(
                            40
                        )}`

                    return item
                })
            } catch (e) {
                console.log(e)
            }

            dispatch(calculatorSetInputData(inputData))
            dispatch(calculatorSetCoverageChecksSubmitted(true))

            let processedCount = 0

            while (processedCount < inputData.length) {
                const coverageChecks = [] as Promise<any>[]

                // Generate processing promises with our "pagination' based on batch size
                for (
                    let i = processedCount;
                    i < processedCount + batchSize;
                    i++
                ) {
                    if (!inputData[i]) {
                        break
                    }

                    coverageChecks.push(
                        runSingleCoverageCheck(
                            inputData[i],
                            action.practice,
                            dispatch
                        )
                    )
                }

                processedCount += batchSize

                await Promise.all(coverageChecks)
            }

            action.onSuccess && action.onSuccess()
        } catch (e) {
            dispatch(calculatorSetCoverageChecksSubmitted(false))

            action.onError && action.onError()
        } finally {
            dispatch(
                runtimeStopLoading("calculatorRunBulkCoverageChecksLoading")
            )

            action.onFinally && action.onFinally()
        }
    }
)

/**
 * Run smart check for calculator
 */
export const calculatorRunSmartCheck = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        requestData: IGetCoverageEstimateData
        inNetwork: boolean
    }>
>("calculatorRunSmartCheckPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("calculatorRunSmartCheckLoading"))

    try {
        const result = await CalculatorService.runSmartScanCoverageCheck(
            action.payload.requestData,
            action.practice,
            action.payload.inNetwork
        )

        if (result) {
            dispatch(
                calculatorReceivedCoverageResult({
                    ...result,

                    resultId: action.payload.requestData.id,
                    isSmartScanResult: true,
                })
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            ToastrHelper.warning(
                "Unfortunately, Nirvana was not able to recover selected policy"
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
        action.onError && action.onError()
    } finally {
        dispatch(runtimeStopLoading("calculatorRunSmartCheckLoading"))

        action.onFinally && action.onFinally()
    }
})
