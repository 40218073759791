/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    AvatarElement,
    PrimaryText,
    NIRVANA_COLORS,
    ConfirmationHelper,
} from "nirvana-react-elements"
import moment from "moment-timezone"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { profileSelector } from "../../../store/selectors/profile.selector"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { UserCommentCardActionsComponent } from "./userCommentCardActions.component"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { policiesDeleteSinglePolicyComment } from "../../../store/thunks/policyComments.thunks"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"

export const UserCommentCardComponent: React.FunctionComponent<
    IUserCommentCardComponentProps
> = props => {
    const profile = useAppSelector(profileSelector)

    const dispatch = useAppDispatch()
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)

    const isAuthor = useMemo<boolean>(
        () => props.item.user?.id === profile?.id,
        [props.item, profile?.id]
    )

    useEffect(() => {
        setIsDeleteLoading(false)
    }, [props.item.id])

    const onDeleteComment = () => {
        ConfirmationHelper.enableConfirmation(
            () => {
                if (!isAuthor || !selectedPracticeRole?.practice) {
                    return
                }

                setIsDeleteLoading(true)

                dispatch(
                    policiesDeleteSinglePolicyComment({
                        practice: selectedPracticeRole.practice,
                        payload: {
                            commentId: props.item.id,
                        },
                        onFinally: () => {
                            setIsDeleteLoading(false)
                        },
                    })
                )
            },
            undefined,
            "Comment will be permanently removed"
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div
                className={`
                    p-16px
                    border-b border-solid border-brand-warmLight
                    ${
                        props.isReply
                            ? "bg-brand-offWhite ml-16px"
                            : "bg-brand-white"
                    }
                `}
            >
                <div className="flex items-center">
                    <AvatarElement
                        className="mr-8px"
                        user={props.item.user}
                        width={24}
                        fontClassName="fs-11px lh-11px pt-3px"
                    />

                    <PrimaryText
                        className={`
                            flex-1 min-w-0 truncate mr-16px
                            ${isAuthor ? "mr-16px" : ""}
                        `}
                        typography="caption"
                    >
                        {props.item.user?.name}
                    </PrimaryText>

                    {/*ACTIONS*/}
                    {isAuthor ? (
                        <UserCommentCardActionsComponent
                            onEditComment={() =>
                                props.onEditComment(props.item)
                            }
                            // delete should be disabled if there are active replies under comment
                            onDeleteComment={
                                !props.item.replies?.length
                                    ? onDeleteComment
                                    : undefined
                            }
                            isActionLoading={isDeleteLoading}
                        />
                    ) : null}
                </div>

                <div className="mt-8px whitespace-pre-line">
                    <PrimaryText>{props.item.comment}</PrimaryText>
                </div>

                <div className="mt-8px flex items-center">
                    <div className="flex-1">
                        <PrimaryText typography="caption">
                            {moment(props.item.createdAt).format(
                                GENERAL_CONFIG.defaultMomentDateTimeFormat
                            )}
                        </PrimaryText>
                    </div>

                    {!props.isReply && props.onReplyToComment ? (
                        <div
                            className="cursor-pointer"
                            onClick={() => props.onReplyToComment?.(props.item)}
                        >
                            <PrimaryText
                                typography="captionSemibold"
                                color={NIRVANA_COLORS.brand.vibrantPurple}
                            >
                                Reply to
                            </PrimaryText>
                        </div>
                    ) : null}
                </div>
            </div>

            {props.item.replies?.map((reply, index) => (
                <UserCommentCardComponent
                    key={index}
                    isReply
                    item={reply}
                    onEditComment={props.onEditComment}
                />
            ))}
        </div>
    )
}
