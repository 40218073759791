import { IsEnum, IsNotEmpty, IsOptional, IsString } from "class-validator"
import { Transform } from "class-transformer"

import {
    CoveragePatientType,
    PayerCoverageCheckNetwork,
} from "../config/coverage.config"

export class RawCsvInputDTO {
    @IsNotEmpty({
        message: "Member ID should not be empty",
    })
    @IsString()
    memberId: string

    @IsNotEmpty({
        message: "Member DOB should not be empty",
    })
    @IsString()
    dob: string

    @IsOptional()
    @IsString()
    firstName?: string

    @IsOptional()
    @IsString()
    lastName?: string

    @IsNotEmpty({
        message: "Provider Session Cost should not be empty",
    })
    @IsString()
    sessionCharge: string

    @IsNotEmpty({
        message: "CPT code should not be empty",
    })
    @IsString()
    cptCode: string

    @IsOptional()
    @IsString()
    network?: PayerCoverageCheckNetwork

    @IsNotEmpty({
        message: "Provider NPI should not be empty",
    })
    @IsString()
    healthProviderNpi: string

    @IsNotEmpty({
        message: "Payer ID should not be empty",
    })
    @IsString()
    payerExternalId: string

    // To handle empty strings in CSV for patient type column
    @Transform(({ value }) => value || undefined)
    @IsOptional()
    @IsEnum(CoveragePatientType)
    customerPatientType?: CoveragePatientType

    @IsOptional()
    @IsString()
    customerPatientId?: string

    @IsOptional()
    @IsString()
    customerPatientNextAppointmentDate?: string
}
