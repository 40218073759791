import { IStepperStep } from "nirvana-react-elements"

// REGISTER
import { EmailStep } from "../components/auth/register/steps/email.step"
import { PasswordStep } from "../components/auth/register/steps/password.step"

export const STEPPER_CONFIG = {
    registerSteps: [
        {
            key: "emailData",
            stepComponent: EmailStep,
        },
        {
            key: "passwordData",
            stepComponent: PasswordStep,
        },
    ] as IStepperStep[],
}
