import React from "react"
import { PrimaryText } from "nirvana-react-elements"

export const VerticalTabsElement: React.FunctionComponent<
    IVerticalTabsElementProps
> = props => {
    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.availableTabs.map((tab, index) => {
                const isSelected = tab.key === props.selectedTab?.key

                if (isSelected && props.hideSelectedTab) {
                    return null
                }

                return (
                    <div
                        key={index}
                        className={`
                            flex items-center px-16px py-8px
                            border-b border-solid border-brand-warmLight
                            ${
                                isSelected
                                    ? "bg-brand-lilacLight"
                                    : "cursor-pointer bg-brand-white hover:bg-brand-whitePurple"
                            }
                        `}
                        onClick={() => !isSelected && props.onTabSelected(tab)}
                    >
                        <PrimaryText
                            typography={props.typography || "textSemibold"}
                        >
                            {tab.label}
                        </PrimaryText>
                    </div>
                )
            })}
        </div>
    )
}
