/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"

import { BillerGroupNameComponent } from "./billerGroupName.component"
import { billerGroupsCreate } from "../../../store/thunks/billerGroups.thunks"
import { BillerGroupUsersAddComponent } from "./billerGroupUsersAdd.component"
import { BillerGroupUsersListComponent } from "./billerGroupUsersList.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import arrowLeftIcon from "../../../assets/images/icons/arrow-left-dark.svg"

export const BillerGroupCreationComponent: React.FunctionComponent<
    IBillerGroupCreationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [selectedUsers, setSelectedUsers] = useState<IOrganizationUser[]>([])

    const onUsersAdded = (users: IOrganizationUser[]) => {
        setSelectedUsers(current => [...users, ...current])
    }

    const onUserRemovedFromList = (user: IOrganizationUser) => {
        setSelectedUsers(current => current.filter(item => item.id !== user.id))
    }

    const onCreateBillerGroup = (name: string) => {
        if (!selectedPracticeRole || !name) {
            return
        }

        dispatch(
            billerGroupsCreate({
                payload: {
                    name,
                    userIds: selectedUsers.map(item => item.id),
                },
                practice: selectedPracticeRole.practice,
            })
        )
    }

    return (
        <div
            className={`
                relative
                md:fixed md:top-0 md:left-0 md:w-screen md:h-screen md:bg-brand-white md:z-10
                ${props.className}
            `}
        >
            <div className="p-16px flex items-start border-b-1px border-solid border-brand-warmLight">
                <div
                    className="cursor-pointer mr-16px mt-11px"
                    onClick={props.onCancel}
                >
                    <img src={arrowLeftIcon} alt="Back" />
                </div>

                <BillerGroupNameComponent
                    className="flex-1"
                    isDefaultEdit
                    onEditCancel={props.onCancel}
                    onEditSubmit={onCreateBillerGroup}
                />
            </div>

            <BillerGroupUsersAddComponent
                className="p-16px border-b-1px border-solid border-brand-warmLight"
                existingUsers={selectedUsers}
                onUsersAdded={onUsersAdded}
            />

            <BillerGroupUsersListComponent
                users={selectedUsers}
                onUserRemoved={onUserRemovedFromList}
            />
        </div>
    )
}
