import React from "react"
import {
    AvailableTextTypography,
    HtmlHelper as NirvanaHtmlHelper,
    PrimaryText,
    TooltipElement,
} from "nirvana-react-elements"

import { CoverageInputDataDifferenceComponent } from "../components/coverageChecker/result/coverageInputDataDifference.component"

export class HtmlHelper extends NirvanaHtmlHelper {
    /**
     * Get HTML wrapped badge
     */
    static getHtmlWrappedBadge(
        label: string | JSX.Element,
        value?: string | number | JSX.Element,
        tooltip?: string,
        isActive = false,
        customBgClassName?: string,
        labelTypography?: AvailableTextTypography,
        valueTypography?: AvailableTextTypography
    ): JSX.Element {
        return (
            <div
                className={`
                    flex items-center
                    mr-8px px-8px pt-3px rounded-4px
                    border border-solid w-max
                    ${
                        customBgClassName
                            ? customBgClassName
                            : isActive
                            ? "bg-brand-lilacLight border-brand-lilac"
                            : "bg-brand-offWhite border-brand-black-transparent-010"
                    }
                `}
            >
                {typeof label === "string" ? (
                    <PrimaryText typography={labelTypography || "textSemibold"}>
                        {label}
                    </PrimaryText>
                ) : (
                    label
                )}

                {value ? (
                    <div className="ml-8px">
                        {typeof value === "string" ? (
                            <PrimaryText typography={valueTypography || "text"}>
                                {value}
                            </PrimaryText>
                        ) : (
                            value
                        )}
                    </div>
                ) : null}

                {tooltip && (
                    <TooltipElement
                        className="ml-8px"
                        isInlined
                        text={tooltip}
                    />
                )}
            </div>
        )
    }

    /**
     * Get formatted single coverage breakdown item
     */
    static getCoverageBreakdownItem(
        data: ICoverageBreakdownData,
        key?: number | string,
        className?: string,
        shorterLabelWidth?: boolean
    ): JSX.Element {
        return (
            <div
                key={key}
                className={`
                    mt-6px flex items-start
                    ${className}
                `}
            >
                <div
                    className={`
                        mr-16px flex-shrink-0
                        ${shorterLabelWidth ? "min-w-174px" : "min-w-190px"}
                    `}
                >
                    <PrimaryText typography="textSemibold">
                        {data.label}
                    </PrimaryText>
                </div>

                {data.valueDifference && typeof data.value === "string" ? (
                    <CoverageInputDataDifferenceComponent
                        className="mouseflow-ignore"
                        initialData={data.value || undefined}
                        overridenData={data.valueDifference}
                    />
                ) : (
                    <div
                        className={`
                            mouseflow-ignore relative
                            ${
                                data.isHighlighted &&
                                typeof data.value === "string"
                                    ? `
                                        bg-brand-greenHighlight px-8px
                                        border-b-2px border-solid border-brand-deepPurple
                                    `
                                    : ""
                            }
                        `}
                    >
                        {typeof data.value === "string" ? (
                            <PrimaryText
                                typography="text"
                                className={`
                                    ${
                                        data.isHighlighted
                                            ? "relative top-2px"
                                            : ""
                                    }
                                `}
                            >
                                {data.value}
                            </PrimaryText>
                        ) : (
                            data.value
                        )}
                    </div>
                )}

                {/*INDICATOR for override in case of data being not string, for example badge*/}
                {data.isHighlighted && typeof data.value !== "string" ? (
                    <div className="relative top-4px ml-4px w-14px h-14px rounded-full bg-brand-greenHighlight" />
                ) : null}

                {data.tooltip && (
                    <TooltipElement
                        className={`
                            ml-8px relative
                            ${data.isHighlighted ? "top-4px" : "top-2px"}
                        `}
                        isInlined
                        text={data.tooltip}
                    />
                )}
            </div>
        )
    }
}
