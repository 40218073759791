/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react"
import { Chart } from "chart.js"
import { Doughnut } from "react-chartjs-2"

export const DoughnutChartElement: React.FunctionComponent<
    IDoughnutChartElementProps
> = props => {
    const chartRef = useRef<Chart<"doughnut">>(null)

    // Once active (hovered) result type changes - change active element in chart and show tooltip for it
    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const defaultPoint = {
            x: 0,
            y: 0,
        }

        if (!props.hoveredChartLabel) {
            chartRef.current.tooltip?.setActiveElements([], defaultPoint)

            return
        }

        const neededIndex = props.data.labels?.findIndex(
            item => item === props.hoveredChartLabel
        )

        if (typeof neededIndex === "undefined" || neededIndex < 0) {
            return
        }

        chartRef.current.tooltip?.setActiveElements(
            [
                {
                    datasetIndex: 0,
                    index: neededIndex,
                },
            ],
            defaultPoint
        )
    }, [props.data, props.hoveredChartLabel])

    return (
        <div className="relative w-full">
            <Doughnut
                ref={chartRef}
                data={props.data}
                redraw={props.redraw}
                options={{
                    plugins: {
                        tooltip: {
                            boxPadding: 5,
                            callbacks: {
                                label: props.tooltipGetLabelCallback,
                            },
                        },
                    },
                }}
            />
        </div>
    )
}
