/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useScrollFix } from "nirvana-react-elements"
import { useParams } from "react-router-dom"

import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { SinglePolicyViewComponent } from "./singlePolicyView.component"
import { NotFoundComponent } from "../../general/notFound.component"
import { PoliciesViewType } from "../../../config/policies.config"

export const SinglePolicyPlanYearResetsComponent: React.FunctionComponent =
    () => {
        useScrollFix()

        useHasPermissions([
            AvailableCoveragePortalPermission.viewPlanYearResets,
        ])

        const { nirvanaRequestId } = useParams()

        return nirvanaRequestId ? (
            <div className="relative">
                <SinglePolicyViewComponent
                    nirvanaRequestId={nirvanaRequestId}
                    viewType={PoliciesViewType.PLAN_YEAR_RESETS}
                />
            </div>
        ) : (
            <NotFoundComponent />
        )
    }
