import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    PrimaryText,
    ISupportContentCategory,
    useFaqs,
    AvailableFAQType,
} from "nirvana-react-elements"

import { supportSetSelectedContentCategory } from "../../store/slices/support.slice"
import { useAppSelector } from "../../store/selectors/app.selector"
import { supportSelector } from "../../store/selectors/support.selector"
import { SUPPORT_CONFIG } from "../../config/support.config"
import { useAppDispatch } from "../../store/appDispatch.hook"

import chevronDownIcon from "../../assets/images/icons/chevron-down-dark.svg"
import chevronUpIcon from "../../assets/images/icons/chevron-up-dark.svg"

export const SupportNavbarComponent: React.FunctionComponent<{
    className?: string
    contentCategories: ISupportContentCategory[]
}> = props => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    const { faqs } = useFaqs<ISupportContentCategory>(
        AvailableFAQType.coveragePortalMain
    )

    const supportState = useAppSelector(supportSelector)

    const [expandedSlugs, setExpandedSlugs] = useState<string[]>([] as string[])

    // Once list of support categories changes in state and we have url query param
    // Make sure to select needed article and show it
    // Also look if slug is children's category
    // If so, expand parent
    useEffect(() => {
        const neededCategorySlug = searchParams.get(
            SUPPORT_CONFIG.supportArticleUrlQueryKey
        )

        // IF only one category in list -> select first
        // otherwise unset
        if (!neededCategorySlug) {
            onCategoryClick(faqs.length === 1 ? faqs[0] : null)

            return
        }

        // Recursive function to generate tree backwards
        // So from slug of the deepest level to top parent
        const generateCategoriesSlugTree = (
            categories: ISupportContentCategory[],
            result: ISupportContentCategory[],
            parent?: ISupportContentCategory
        ): boolean => {
            for (let i = 0; i < categories.length; i++) {
                // IF category itself is what we need
                // Then we want to record parent in result (if available) and return true
                // Indicating to the recursive caller of this function that it was found
                // So they will do the same - record parent and return true to continue recursive process for recording all parents
                if (categories[i].urlSlug === neededCategorySlug) {
                    if (parent) {
                        result.push(parent)
                    }

                    result.push(categories[i])

                    return true
                }

                // Check child categories, current category will be parent for recursive function call
                // IF result is true, it means that slug was found somewhere in children
                // So we need to record parent of current call to the beginning of array and return true
                if (categories[i].childCategories?.length) {
                    const childCheckResult = generateCategoriesSlugTree(
                        categories[i].childCategories || [],
                        result,
                        categories[i]
                    )

                    if (childCheckResult) {
                        if (parent) {
                            result.unshift(parent)
                        }

                        return true
                    }
                }
            }

            // Not found in current branch of tree
            return false
        }

        // Latest category in array will be what we need to set
        // All other (before in array) are parents and parents of parents
        const treeResult = [] as ISupportContentCategory[]

        generateCategoriesSlugTree(faqs, treeResult)

        // IF found category -> select it
        // IF only one category in list -> select first
        // otherwise unset
        if (!treeResult.length) {
            onCategoryClick(faqs.length === 1 ? faqs[0] : null)

            return
        }

        // Last item in array is our needed category
        onCategoryClick(treeResult[treeResult.length - 1])

        // Other items in array are parents of our needed category
        for (let i = 0; i < treeResult.length - 1; i++) {
            expandCategory(treeResult[i])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqs])

    // IF category is selected itself, or one of it's children
    const isCategoryActive = (category: ISupportContentCategory): boolean =>
        category.urlSlug === supportState.selectedContentCategory?.urlSlug ||
        (!!category.childCategories?.length &&
            !!category.childCategories.filter(isCategoryActive).length)

    const isCategoryExpanded = (category: ISupportContentCategory): boolean =>
        expandedSlugs.includes(category.urlSlug)

    const expandCategory = (category: ISupportContentCategory) => {
        if (!category.childCategories?.length) {
            return
        }

        setExpandedSlugs(current => [...current, category.urlSlug])
    }

    const collapseCategory = (category: ISupportContentCategory) =>
        setExpandedSlugs(current =>
            current.filter(item => item !== category.urlSlug)
        )

    const onCategoryClick = (category: ISupportContentCategory | null) => {
        // Unset category
        if (!category) {
            dispatch(supportSetSelectedContentCategory(category))

            // Collapse everything
            setExpandedSlugs([])

            return
        }

        // IF category is parent and is expanded right now, just collapse it
        // Otherwise, select it and if parent then expand
        // Collapsing means just removing category slug from expanded slugs

        if (isCategoryExpanded(category)) {
            collapseCategory(category)
        } else {
            dispatch(supportSetSelectedContentCategory(category))

            expandCategory(category)
        }
    }

    const getMenuTreeLevel = (
        categories: ISupportContentCategory[],
        level = 0
    ): JSX.Element => {
        return (
            <div
                style={{
                    marginLeft: `${level * 20}px`,
                }}
            >
                {categories.map((item, index) => {
                    const isCurrentExpanded = isCategoryExpanded(item)
                    const isCurrentActive = isCategoryActive(item)

                    return (
                        <div key={index}>
                            <div
                                className={`
                                    px-12px py-8px
                                    rounded-8px mt-8px
                                    flex items-center
                                    ${
                                        isCurrentActive
                                            ? "bg-brand-offWhite"
                                            : "cursor-pointer"
                                    }
                                `}
                                onClick={onCategoryClick.bind({}, item)}
                            >
                                <PrimaryText
                                    className="flex-1 mr-16px relative top-2px"
                                    typography={
                                        isCurrentActive
                                            ? "textSemibold"
                                            : "text"
                                    }
                                >
                                    {item.shortTitle}
                                </PrimaryText>

                                {item.childCategories?.length ? (
                                    <div>
                                        <img
                                            src={
                                                isCurrentExpanded
                                                    ? chevronUpIcon
                                                    : chevronDownIcon
                                            }
                                            alt="Expand"
                                        />
                                    </div>
                                ) : null}
                            </div>

                            {item.childCategories?.length ? (
                                <div
                                    className={`
                                        overflow-hidden
                                        ${!isCurrentExpanded ? "max-h-0" : ""}
                                    `}
                                >
                                    {getMenuTreeLevel(
                                        item.childCategories,
                                        level + 1
                                    )}
                                </div>
                            ) : null}
                        </div>
                    )
                })}
            </div>
        )
    }

    const categoriesTree: JSX.Element = useMemo(
        () => getMenuTreeLevel(props.contentCategories),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            props.contentCategories,
            expandedSlugs,
            supportState.selectedContentCategory,
        ]
    )

    // IF THERE IS MORE THAN 1 CATEGORY only then show menu
    // Or if there's 1 category, but it has child categories
    return faqs.length > 1 || faqs[0]?.childCategories?.length ? (
        <div className={`mt-30px ${props.className}`}>{categoriesTree}</div>
    ) : null
}
