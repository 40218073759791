import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { FeatureFlag } from "../config/featureFlags.config"

export class FeatureFlagsService {
    /**
     * Get enabled feature flags for practice/user
     */
    static async getEnabledFeatureFlags(
        practice: IPractice
    ): Promise<FeatureFlag[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.FEATURE_FLAGS_GET_ENABLED_LIST.replace(
                ":practiceId",
                practice.id.toString()
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
