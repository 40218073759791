/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    PrimaryText,
    UtilHelper,
    TooltipElement,
    IStepperStepProps,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { authCheckEmailAvailable } from "../../../../store/thunks/auth.thunks"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { authSelector } from "../../../../store/selectors/auth.selector"
import { TOOLTIPS_CONFIG } from "../../../../config/tooltips.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { useAppDispatch } from "../../../../store/appDispatch.hook"

import mainImage from "../../../../assets/images/shapes/register.svg"
import mailIcon from "../../../../assets/images/icons/mail-dark.svg"
import arrowRightIcon from "../../../../assets/images/icons/arrow-right.svg"

export const EmailStep: React.FunctionComponent<IStepperStepProps> = props => {
    const dispatch = useAppDispatch()

    const authState = useAppSelector(authSelector)

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm()

    const providedEmail = watch("email")

    const [predefinedProcessed, setPredefinedProcessed] =
        useState<boolean>(false)

    useEffect(() => {
        if (predefinedProcessed) {
            return
        }

        setPredefinedProcessed(true)

        const predefinedEmail =
            props.defaultValues?.email ||
            UtilHelper.getRawUrlSearchParam(
                GENERAL_CONFIG.urlSearchParamsKeys.email
            )

        if (predefinedEmail) {
            setValue("email", predefinedEmail)
        }
    }, [props.defaultValues])

    const onSubmit = data => {
        dispatch(
            authCheckEmailAvailable({
                payload: data.email,

                onSuccess: () => {
                    props.onStepSubmit({
                        email: providedEmail,
                    } as IRegisterEmailData)
                },
            })
        )
    }

    return (
        <div className="relative">
            <div className="w-full text-center">
                <img
                    src={mainImage}
                    alt="Nirvana Health"
                    title="Nirvana Health"
                    className="margin-x-center sm:w-150px"
                />
            </div>

            <PrimaryText typography="h1" centered className="mt-32px">
                Welcome to Nirvana Health!
            </PrimaryText>

            <PrimaryText centered typography="subtitle" className="mt-32px">
                Insurance coverage solutions optimized for your specialty and
                scale. Creating your account is quick and easy and{" "}
                <br className="sm:hidden" /> should take less than 5 minutes.
            </PrimaryText>

            <PrimaryText
                centered
                typography="subtitle"
                className="mt-32px flex items-center justify-center"
            >
                To begin, simply enter your email below:
                <TooltipElement
                    className="ml-8px"
                    text={TOOLTIPS_CONFIG.auth.registerEmailCollectionNote}
                />
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div className="mt-44px flex items-center md:block">
                    <InputElement
                        className="mr-24px flex-1 md:mr-0px"
                        name="email"
                        label="Email address"
                        defaultValue={providedEmail}
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                    />

                    <ButtonElement
                        className="md:mt-32px md:text-right"
                        label="Get started"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
