import React from "react"
import {
    PrimaryText,
    ButtonElement,
    FixedTopRightActionElement,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"
import { AuthHelper } from "../../helpers/auth.helper"

import logo from "../../assets/images/logo.svg"
import mainImage from "../../assets/images/shapes/locked.svg"

export const PracticeRoleMissingLockComponent: React.FunctionComponent = () => {
    return (
        <div className="fixed z-1070 let-0 top-0 bg-brand-offWhite w-screen h-screen pt-40px px-40px">
            <div className="pb-42px">
                <img src={logo} alt="Nirvana" />
            </div>

            <img
                src={mainImage}
                alt="Nirvana Health"
                title="Nirvana Health"
                className="margin-x-center sm:w-150px"
            />

            <PrimaryText className="mt-44px" typography="h4" centered>
                You’ll need to be part of an organization to proceed.
            </PrimaryText>

            <PrimaryText className="mt-32px" typography="subtitle" centered>
                Please accept an organizational invite, <br />
                or reach out to create an organization yourself.
            </PrimaryText>

            <div className="text-center mt-32px">
                <a href={`mailto:${GENERAL_CONFIG.supportEmail}`}>
                    <ButtonElement
                        label="Contact us"
                        type="primary"
                        htmlType="button"
                        size="large"
                    />
                </a>
            </div>

            {/*Logout*/}
            <FixedTopRightActionElement
                ctaLabel="Log out"
                ctaAction={() => AuthHelper.logout()}
            />
        </div>
    )
}
