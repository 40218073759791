/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"

export const useScrollableElementBoundaries = (
    elementRef?: React.RefObject<HTMLDivElement>,
    additionalScrollTrigger?: React.RefObject<HTMLDivElement>
) => {
    const [boundaries, setBoundaries] = useState<DOMRect>()

    useEffect(() => {
        if (!elementRef) {
            return
        }

        const onScroll = () => {
            const boundaries = elementRef?.current?.getBoundingClientRect()

            if (!boundaries) {
                return
            }

            setBoundaries(boundaries)
        }

        window.addEventListener("scroll", onScroll)
        additionalScrollTrigger?.current?.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)

            additionalScrollTrigger?.current?.removeEventListener(
                "scroll",
                onScroll
            )
        }
    }, [elementRef?.current])

    return boundaries
}
