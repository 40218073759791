import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    PrimaryText,
    IStepperStepProps,
    CheckboxElement,
    InputElement,
    VALIDATION_CONFIG,
    PasswordStrengthElement,
    useIsMobile,
} from "nirvana-react-elements"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { authSelector } from "../../../../store/selectors/auth.selector"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { InvitedPracticeElement } from "../../../../elements/invitedPractice.element"

import safetyIcon from "../../../../assets/images/icons/safety-white.svg"

export const PasswordStep: React.FunctionComponent<
    IStepperStepProps
> = props => {
    const isMobile = useIsMobile()

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm()

    const authState = useAppSelector(authSelector)

    const password: any = useRef({})
    password.current = watch("password", "")

    const [tosAccepted, setTosAccepted] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        props.onStepSubmit(data as IRegisterPasswordData)
    }

    const onStepBack = () => {
        props.onStepBack && props.onStepBack()
    }

    return (
        <div className="relative">
            <PrimaryText typography="h1">Create an Account</PrimaryText>

            <PrimaryText typography="subtitle" className="mt-16px">
                Finish creating your basic account by setting a password.
            </PrimaryText>

            {authState.userInvitation?.practice && (
                <div className="mt-32px">
                    <PrimaryText typography="caption">Organization</PrimaryText>

                    <div className="flex mt-6px">
                        <InvitedPracticeElement
                            className="flex-1"
                            practice={authState.userInvitation.practice}
                        />

                        <div className="md:hidden flex-1" />
                    </div>
                </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div className="flex items-end sm:block">
                    <div className="mr-27px flex-1 sm:mr-0px">
                        <InputElement
                            className="mt-44px"
                            name="firstName"
                            label="First name"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                        />

                        <InputElement
                            className="mt-44px"
                            name="lastName"
                            label="Last name"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                        />

                        <InputElement
                            className="mt-44px"
                            name="password"
                            label="Set password"
                            type="password"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                validate:
                                    VALIDATION_CONFIG.passwordSpecialValidations,
                            }}
                        />
                    </div>

                    <PasswordStrengthElement
                        className="sm:mt-32px"
                        width={isMobile ? undefined : 260}
                        currentValue={password.current}
                        trianglePlacement={isMobile ? undefined : "bottomLeft"}
                    />
                </div>

                <CheckboxElement
                    className="mt-32px"
                    name="tos"
                    onChange={setTosAccepted}
                    label={
                        <PrimaryText>
                            I agree to Nirvana’s Health Providers{" "}
                            <a
                                href={`${GENERAL_CONFIG.mainWebsiteUrl}/terms-and-conditions`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-bold"
                            >
                                Terms and Conditions
                            </a>{" "}
                            and{" "}
                            <a
                                href={`${GENERAL_CONFIG.mainWebsiteUrl}/baa`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-bold"
                            >
                                BAA
                            </a>
                            .
                        </PrimaryText>
                    }
                />

                <div className="mt-32px flex items-center">
                    <div
                        className="flex-1 mr-24px cursor-pointer"
                        onClick={onStepBack}
                    >
                        ‹ Back
                    </div>

                    <ButtonElement
                        label="Create an Account"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={safetyIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                        disabled={!tosAccepted}
                    />
                </div>
            </form>
        </div>
    )
}
