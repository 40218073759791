import { createAsyncThunk } from "@reduxjs/toolkit"
import PapaParse from "papaparse"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    policiesIncreaseGetFullListProgress,
    policiesSetGettingFullListProgress,
    policiesSetHistoryList,
    policiesSetOverridesList,
    policiesSetPlanYearResetsList,
    policiesSetReportsList,
    policiesSetSelectedItem,
    policiesSetSelectedItemComments,
} from "../slices/policies.slice"
import { PoliciesService } from "../../services/policies.service"
import { RootState } from "../store"
import { UtilHelper } from "../../helpers/util.helper"
import { PoliciesHelper } from "../../helpers/policies.helper"
import { CsvHelper } from "../../helpers/csv.helper"
import { POLICIES_CONFIG, PoliciesViewType } from "../../config/policies.config"
import { CoverageHistoryHelper } from "../../helpers/coverageHistory.helper"
import { policiesGetSinglePolicyComments } from "./policyComments.thunks"

export const policiesReloadList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        policiesViewType: PoliciesViewType
    }>
>("policiesReloadListPrefix", async (action, { dispatch, getState }) => {
    const globalState = getState() as RootState

    switch (action.payload.policiesViewType) {
        case PoliciesViewType.REPORTS:
            dispatch(
                policiesGetReportsList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.reportsPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.reportsPagination.start -
                                    globalState.policies.reportsPagination.count
                            ),
                        },
                        filters: globalState.policies.reportsFilters,
                        sort: globalState.policies.reportsSort,
                    },
                })
            )

            break

        case PoliciesViewType.OVERRIDES:
            dispatch(
                policiesGetOverridesList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.overridesPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.overridesPagination.start -
                                    globalState.policies.overridesPagination
                                        .count
                            ),
                        },
                        filters: globalState.policies.overridesFilters,
                        sort: globalState.policies.overridesSort,
                    },
                })
            )

            break

        case PoliciesViewType.HISTORY_SEARCH:
            if (UtilHelper.isEmptyObject(globalState.policies.historyFilters)) {
                return
            }

            dispatch(
                policiesGetHistoryList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.historyPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.historyPagination.start -
                                    globalState.policies.historyPagination.count
                            ),
                        },
                        filters: globalState.policies.historyFilters,
                        sort: globalState.policies.historySort,
                    },
                })
            )

            break

        case PoliciesViewType.PLAN_YEAR_RESETS:
            dispatch(
                policiesGetPlanYearResetsList({
                    ...action,

                    payload: {
                        pagination: {
                            ...globalState.policies.planYearResetsPagination,

                            // Reload same page
                            start: Math.max(
                                0,
                                globalState.policies.planYearResetsPagination
                                    .start -
                                    globalState.policies
                                        .planYearResetsPagination.count
                            ),
                        },
                        filters: globalState.policies.planYearResetsFilters,
                        sort: globalState.policies.planYearResetsSort,
                    },
                })
            )

            break
    }
})

/**
 * Get list of policies for reports
 */
export const policiesGetReportsList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetReportsListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetReportsListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetReportsList(defaultResult))

    try {
        const result = await PoliciesService.getReportsList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetReportsListLoading"))
    }
})

/**
 * Get list of historical policies
 */
export const policiesGetHistoryList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetHistoryListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetHistoryListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetHistoryList(defaultResult))

    try {
        const result = await PoliciesService.getHistoryList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetHistoryListLoading"))
    }
})

/**
 * Get list of overridden policies
 */
export const policiesGetOverridesList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>("policiesGetOverridesListPrefix", async (action, { dispatch, signal }) => {
    dispatch(runtimeStartLoading("policiesGetOverridesListLoading"))

    const defaultResult: ICoverageHistoryListResult = {
        items: [],
        newPagination: action.payload.pagination,
        isError: false,
    }

    // reset items
    dispatch(policiesSetOverridesList(defaultResult))

    try {
        const result = await PoliciesService.getOverridesList(
            action.practice,
            action.payload.pagination,
            action.payload.filters,
            action.payload.sort,
            signal
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || defaultResult
    } catch (e) {
        action.onError?.()

        return defaultResult
    } finally {
        // Only stop loading if it wasn't aborted by other trigger
        !signal.aborted &&
            dispatch(runtimeStopLoading("policiesGetOverridesListLoading"))
    }
})

/**
 * Get list of plan year resets policies
 */
export const policiesGetPlanYearResetsList = createAsyncThunk<
    ICoverageHistoryListResult,
    IThunkActionWithPracticeData<{
        pagination: IPagination
        filters?: IPoliciesListFiltersData
        sort?: IPoliciesListSortData
    }>
>(
    "policiesGetPlanYearResetsListPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(runtimeStartLoading("policiesGetPlanYearResetsListLoading"))

        const defaultResult: ICoverageHistoryListResult = {
            items: [],
            newPagination: action.payload.pagination,
            isError: false,
        }

        // reset items
        dispatch(policiesSetPlanYearResetsList(defaultResult))

        try {
            const result = await PoliciesService.getPlanYearResetsList(
                action.practice,
                action.payload.pagination,
                action.payload.filters,
                action.payload.sort,
                signal
            )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || defaultResult
        } catch (e) {
            action.onError?.()

            return defaultResult
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading("policiesGetPlanYearResetsListLoading")
                )
        }
    }
)

/**
 * Get aggregations of reports by status codes
 */
export const policiesGetReportsAggregationsStatusCodes = createAsyncThunk<
    IGetCoverageChecksAggregationsStatusCodes | null,
    IThunkActionWithPracticeData<{
        filters?: IPoliciesListFiltersData
    }>
>(
    "policiesGetReportsAggregationsStatusCodesPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(
            runtimeStartLoading(
                "policiesGetReportsAggregationsStatusCodesLoading"
            )
        )

        try {
            const result =
                await PoliciesService.getReportsAggregationStatusCodes(
                    action.practice,
                    action.payload.filters,
                    signal
                )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || null
        } catch (e) {
            action.onError?.()

            return null
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading(
                        "policiesGetReportsAggregationsStatusCodesLoading"
                    )
                )
        }
    }
)

/**
 * Get aggregations of reports by flags
 */
export const policiesGetReportsAggregationsFlags = createAsyncThunk<
    IGetCoverageChecksAggregationsFlags | null,
    IThunkActionWithPracticeData<{
        filters?: IPoliciesListFiltersData
    }>
>(
    "policiesGetReportsAggregationsFlagsPrefix",
    async (action, { dispatch, signal }) => {
        dispatch(
            runtimeStartLoading("policiesGetReportsAggregationsFlagsLoading")
        )

        try {
            const result = await PoliciesService.getReportsAggregationFlags(
                action.practice,
                action.payload.filters,
                signal
            )

            if (result) {
                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }

            return result || null
        } catch (e) {
            action.onError?.()

            return null
        } finally {
            // Only stop loading if it wasn't aborted by other trigger
            !signal.aborted &&
                dispatch(
                    runtimeStopLoading(
                        "policiesGetReportsAggregationsFlagsLoading"
                    )
                )
        }
    }
)

/**
 * Get single historical policy
 */
export const policiesGetSinglePolicy = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        triesCount?: number
        retryMaxCount?: number
        nirvanaRequestId: string
        withPolicyDataReFetch?: boolean
    }>
>("policiesGetSinglePolicyPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesGetSinglePolicyLoading"))

    const triesCount = action.payload.triesCount || 0

    const { retryMaxCount, nirvanaRequestId, withPolicyDataReFetch } =
        action.payload

    try {
        // Reset comments
        dispatch(policiesSetSelectedItemComments([]))

        const needRetry = !!retryMaxCount && triesCount <= retryMaxCount

        const result = await PoliciesService.getSingle(
            action.practice,
            nirvanaRequestId,
            withPolicyDataReFetch,
            needRetry ? [400] : undefined
        )

        if (result) {
            dispatch(policiesSetSelectedItem(result))

            action.onSuccess && action.onSuccess(result)

            // Get comments for policy/patient if we have needed data
            const patient =
                CoverageHistoryHelper.getPatientIdentificationData(result) ||
                undefined

            const policy =
                CoverageHistoryHelper.getPolicyIdentificationData(result) ||
                undefined

            if (patient || policy) {
                dispatch(
                    policiesGetSinglePolicyComments({
                        practice: action.practice,
                        payload: {
                            patient,
                            policy,
                        },
                    })
                )
            }
        } else {
            // Retry if needed
            if (needRetry) {
                await UtilHelper.sleep(1)

                dispatch(
                    policiesGetSinglePolicy({
                        ...action,

                        payload: {
                            ...action.payload,
                            triesCount: triesCount + 1,
                        },
                    })
                )

                return
            }

            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesGetSinglePolicyLoading"))
    }
})

/**
 * Retry single policy
 */
export const policiesRetrySinglePolicy = createAsyncThunk<
    ICoverageResult | null,
    IThunkActionWithPracticeData<{
        nirvanaRequestId: string
    }>
>("policiesRetrySinglePolicyPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesRetrySinglePolicyLoading"))

    try {
        const result = await PoliciesService.retrySingle(
            action.practice,
            action.payload.nirvanaRequestId
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }

        return result || null
    } catch (e) {
        action.onError?.()

        return null
    } finally {
        dispatch(runtimeStopLoading("policiesRetrySinglePolicyLoading"))
    }
})

/**
 * Resolve policy flag
 */
export const policiesResolveFlag = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        flag: IPolicyFlag
    }>
>("policiesResolveFlagPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesResolveFlagLoading"))

    try {
        const result = await PoliciesService.resolveFlag(
            action.practice,
            action.payload.flag.id
        )

        if (result) {
            dispatch(policiesSetSelectedItem(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesResolveFlagLoading"))
    }
})

/**
 * Export all policies with applied filters and sort to CSV
 */
export const policiesExportList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        viewType: PoliciesViewType
        columns: IPolicyColumnConfiguration[]
    }>
>("policiesExportListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesExportListLoading"))

    try {
        const fetchedItems = await dispatch(
            policiesGetFullList({
                practice: action.practice,
                payload: {
                    type: action.payload.viewType,
                },
            })
        ).unwrap()

        const csvData: any[][] = []

        // headers
        csvData.push(action.payload.columns.map(item => item.label))

        // data
        for (const coverageCheck of fetchedItems) {
            const row: any[] = []

            for (const column of action.payload.columns) {
                row.push(
                    PoliciesHelper.getMappedColumnValue(
                        coverageCheck,
                        column,
                        "",
                        false
                    )
                )
            }

            csvData.push(row)
        }

        CsvHelper.createAndDownloadCsvDocument(
            PapaParse.unparse(csvData),
            POLICIES_CONFIG.exportPoliciesBaseName
        )

        if (fetchedItems.length) {
            action.onSuccess && action.onSuccess(fetchedItems)
        } else {
            action.onError && action.onError(fetchedItems)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesExportListLoading"))

        action.onFinally?.()
    }
})

/**
 * Get full list of policies of some type - based on filters and sort in state
 */
export const policiesGetFullList = createAsyncThunk<
    ICoverageCheckHistory[],
    IThunkActionWithPracticeData<{
        type: PoliciesViewType
    }>
>(
    "policiesGetFullListPrefix",
    async (
        action,
        { dispatch, getState }
    ): Promise<ICoverageCheckHistory[]> => {
        dispatch(policiesSetGettingFullListProgress(0))
        dispatch(runtimeStartLoading("policiesGetFullListLoading"))

        const globalState = getState() as RootState

        // setInterval until we get first chunk
        // done so it doesn't stuck on 0 for single chunk export
        // Also so it doesn't jump a lot
        const intervalId = setInterval(() => {
            dispatch(policiesIncreaseGetFullListProgress())
        }, 500)

        const fetchedItems: ICoverageCheckHistory[] = []

        try {
            let result:
                | {
                      items: ICoverageCheckHistory[]
                      newPagination: IPagination
                      isError: boolean
                  }
                | undefined

            const neededPaginationLength =
                POLICIES_CONFIG.availablePaginationOptions[
                    POLICIES_CONFIG.availablePaginationOptions.length - 1
                ].value

            do {
                switch (action.payload.type) {
                    case PoliciesViewType.REPORTS:
                        result = await PoliciesService.getReportsList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.reportsFilters,
                            globalState.policies.reportsSort
                        )

                        break

                    case PoliciesViewType.HISTORY_SEARCH:
                        result = await PoliciesService.getHistoryList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.historyFilters,
                            globalState.policies.historySort
                        )

                        break

                    case PoliciesViewType.OVERRIDES:
                        result = await PoliciesService.getOverridesList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.overridesFilters,
                            globalState.policies.overridesSort
                        )

                        break

                    case PoliciesViewType.PLAN_YEAR_RESETS:
                        result = await PoliciesService.getPlanYearResetsList(
                            action.practice,
                            result?.newPagination ||
                                UtilHelper.getPagination(
                                    neededPaginationLength
                                ),
                            globalState.policies.planYearResetsFilters,
                            globalState.policies.planYearResetsSort
                        )

                        break
                }

                if (result?.items.length) {
                    fetchedItems.push(...result.items)
                }

                // Notify progress
                if (result?.newPagination.total) {
                    dispatch(
                        policiesSetGettingFullListProgress(
                            Math.max(
                                globalState.policies
                                    .policiesGettingFullListProgress,
                                Math.floor(
                                    (fetchedItems.length /
                                        result.newPagination.total) *
                                        100
                                )
                            )
                        )
                    )
                }
            } while (result?.newPagination.moreAvailable)

            if (fetchedItems.length) {
                action.onSuccess && action.onSuccess(fetchedItems)
            } else {
                action.onError && action.onError(fetchedItems)
            }

            return fetchedItems
        } catch (e) {
            return fetchedItems
        } finally {
            clearInterval(intervalId)

            dispatch(policiesSetGettingFullListProgress(100))
            dispatch(runtimeStopLoading("policiesGetFullListLoading"))

            action.onFinally?.()
        }
    }
)
