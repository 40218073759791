/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from "react"
import { useFormContext } from "react-hook-form"
import {
    InputElement,
    ISelectRenderedOption,
    PrimaryText,
    SelectElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import {
    BenefitsStructureConfigFilter,
    OVERRIDES_CONFIG,
} from "../../../config/overrides.config"

export const InlinedOverrideFormBenefitStructureComponent: React.FunctionComponent<
    IInlinedOverrideFormBenefitStructureComponentProps
> = props => {
    const {
        register,
        control,
        watch,
        unregister,
        setValue,
        formState: { errors, touchedFields },
    } = useFormContext()

    const benefitStructure = watch("benefitStructure")

    const coinsurance = watch(
        OVERRIDES_CONFIG.benefitStructureDependentFieldsConfig.coinsurance
    )
    const copayment = watch(
        OVERRIDES_CONFIG.benefitStructureDependentFieldsConfig.copayment
    )
    const oopMax = watch(
        OVERRIDES_CONFIG.benefitStructureDependentFieldsConfig.oopMax
    )
    const deductible = watch(
        OVERRIDES_CONFIG.benefitStructureDependentFieldsConfig.deductible
    )

    const isComplexBenefitRequired = useRef(false)

    const onResetBenefitStructure = () => {
        setValue("benefitStructure", undefined, {
            shouldValidate: true,
        })
    }

    const validateBenefitsStructureConditions = (
        values: IBenefitsStructureDependingValues,
        validations: IBenefitsStructureConfigValidation[]
    ) => {
        const defaultValue = 0

        return validations.every(validation => {
            const value = Number(values[validation.name]) || defaultValue
            const valueToCompare = validation?.value || defaultValue

            switch (validation.valueOperator) {
                case BenefitsStructureConfigFilter.equals:
                    return value === valueToCompare

                case BenefitsStructureConfigFilter.greaterThan:
                    return value > valueToCompare

                case BenefitsStructureConfigFilter.lessThan:
                    return value < valueToCompare

                default:
                    return false
            }
        })
    }

    const isSelectedBenefitStructureValid = (benefitStructure: string) => {
        return benefitsStructureOptions.find(
            item => item.value === benefitStructure
        )
    }

    // get benefits structure options filtered by coinsurance, copayment, deductible, and oopMax
    const benefitsStructureOptions = useMemo<ISelectRenderedOption[]>(() => {
        return OVERRIDES_CONFIG.benefitsStructuresConfig
            .filter(field => {
                return (
                    field.default ||
                    validateBenefitsStructureConditions(
                        { coinsurance, copayment, oopMax, deductible },
                        field.requiredFields
                    )
                )
            })
            .map(({ title, subTitle }) => ({
                key: title,
                value: title,
                displayValue: title,
                dropdownDisplayValueAdditional: subTitle,
            }))
    }, [coinsurance, copayment, oopMax, deductible])

    const defaultBenefitStructure = useMemo<string | undefined>(() => {
        if (!props.defaultBenefitStructure) {
            return undefined
        }

        const isValidOption = isSelectedBenefitStructureValid(
            props.defaultBenefitStructure
        )

        return isValidOption ? props.defaultBenefitStructure : undefined
    }, [benefitsStructureOptions, props.defaultBenefitStructure])

    // reset value of complexBenefits if benefitsStructureOptions changed and current selected doesn't exist in new options
    useEffect(() => {
        const exists = isSelectedBenefitStructureValid(benefitStructure)

        if (!exists) {
            onResetBenefitStructure()
        }
    }, [benefitsStructureOptions, benefitStructure])

    // Monitor touched fields and check if complex benefit needs to have values\
    // If one of the related fields was touched - it's required
    useEffect(() => {
        isComplexBenefitRequired.current =
            !props.benefitStructureNotRequired &&
            Object.values(
                OVERRIDES_CONFIG.benefitStructureDependentFieldsConfig
            ).some(key => !!touchedFields[key])
    }, [JSON.stringify(touchedFields), props.benefitStructureNotRequired])

    return (
        <div className={props.className}>
            <SelectElement
                className="w-290px"
                size="small"
                name="benefitStructure"
                shouldValidate
                renderedOptions={benefitsStructureOptions}
                reactHookFormRegister={register}
                reactHookFormUnregister={unregister}
                reactHookFormSet={setValue}
                reactHookFormErrors={errors}
                reactHookFormControl={control}
                validations={{
                    validate: value => {
                        return (
                            !isComplexBenefitRequired.current ||
                            !!value ||
                            "Benefit Structure is required when financials are updated"
                        )
                    },
                }}
                defaultValue={defaultBenefitStructure}
                disabled={props.isDisabled}
            />

            {benefitStructure === OVERRIDES_CONFIG.defaultBenefitsStructure ? (
                <div className="mt-8px w-290px">
                    <InputElement
                        className="w-full"
                        name="customBenefitStructure"
                        size="small"
                        placeholder="Write other description in here"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            minLength: VALIDATION_CONFIG.minLength,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        defaultValue={props.defaultCustomBenefitStructure}
                        disabled={props.isDisabled}
                    />

                    <PrimaryText className="mt-8px" typography="caption">
                        <span className="text-bold">Note:</span> Nirvana does
                        not handle the member obligation calculation for
                        policies tagged with “
                        {OVERRIDES_CONFIG.defaultBenefitsStructure}”
                    </PrimaryText>
                </div>
            ) : null}
        </div>
    )
}
