/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"

import {
    policiesCreateSinglePolicyComment,
    policiesUpdateSinglePolicyComment,
} from "../../../store/thunks/policyComments.thunks"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"

import closeIcon from "../../../assets/images/icons/close-dark.svg"
import paperPlaneIcon from "../../../assets/images/icons/paper-plane-dark.svg"

export const AddCommentComponent: React.FunctionComponent<
    IAddCommentComponentProps
> = props => {
    const containerRef = useOnclickOutside(() => onBlur())

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const dispatch = useAppDispatch()
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState<string>()

    const isLoading = useMemo<boolean>(() => {
        return (
            RuntimeHelper.isPolicyCommentCreationLoading() ||
            RuntimeHelper.isPolicyCommentUpdateLoading()
        )
    }, [runtimeState.isLoading])

    // Monitor change of replyToComment and focus input if it was set
    useEffect(() => {
        if (!props.replyToComment) {
            return
        }

        textareaRef.current?.focus()
    }, [props.replyToComment])

    // Monitor change of editComment and focus input if it was set and set current value
    useEffect(() => {
        if (!props.editComment) {
            return
        }

        setCurrentValue(props.editComment.comment)

        textareaRef.current?.focus()
    }, [props.editComment])

    const onFocus = () => {
        setIsFocused(true)
    }

    const onBlur = () => {
        setIsFocused(false)
    }

    const onDone = () => {
        props.onClearEditComment()
        props.onClearReplyToComment()
        setCurrentValue("")
        textareaRef.current?.blur()
        onBlur()
    }

    const onSubmit = () => {
        const trimmedValue = currentValue?.trim()

        if (
            !trimmedValue?.length ||
            !selectedPracticeRole?.practice ||
            !(props.patient || props.policy || props.editComment)
        ) {
            return
        }

        // if editing and same value
        if (trimmedValue === props.editComment?.comment) {
            onDone()

            return
        }

        // Edit or create
        props.editComment
            ? dispatch(
                  policiesUpdateSinglePolicyComment({
                      practice: selectedPracticeRole.practice,
                      payload: {
                          commentId: props.editComment.id,
                          comment: trimmedValue,
                      },
                      onSuccess: onDone,
                  })
              )
            : dispatch(
                  policiesCreateSinglePolicyComment({
                      practice: selectedPracticeRole.practice,
                      payload: {
                          comment: trimmedValue,
                          patient: props.patient,
                          policy: props.policy,
                          replyToCommentId: props.replyToComment?.id,
                      },
                      onSuccess: onDone,
                  })
              )
    }

    return (
        <div
            ref={containerRef}
            className={`
                relative
                p-8px pb-0 bg-brand-warmLight shadow-s
                border-t border-solid border-brand-warmLight
                ${props.className}
            `}
        >
            {props.replyToComment || props.editComment ? (
                <div className="flex items-start p-8px rounded-t-md bg-brand-offWhite relative bottom--3px">
                    <PrimaryText
                        typography="caption"
                        className="flex-1 mr-16px whitespace-pre-line"
                    >
                        <p className="text-semibold">
                            {props.editComment ? "Edit:" : "Reply to:"}
                        </p>

                        {(props.editComment || props.replyToComment)?.comment}
                    </PrimaryText>

                    <div className="cursor-pointer" onClick={onDone}>
                        <img src={closeIcon} alt="Cancel" />
                    </div>
                </div>
            ) : null}

            <textarea
                ref={textareaRef}
                className={`
                    transition-all duration-500 ease-in-out
                    h-44px p-12px w-full resize-none rounded-6px
                    ${isFocused ? "h-225px" : "h-44px overflow-hidden"}
                `}
                placeholder="Write your message here"
                onFocus={onFocus}
                value={currentValue}
                onChange={e => setCurrentValue(e.target.value)}
            />

            {isFocused && (
                <div
                    className="absolute right-20px bottom-20px z-10 cursor-pointer"
                    onClick={!isLoading ? onSubmit : undefined}
                >
                    {isLoading ? (
                        <SpinnerElement size="small" />
                    ) : (
                        <img src={paperPlaneIcon} alt="Create" />
                    )}
                </div>
            )}
        </div>
    )
}
