import React, { useState } from "react"
import { PrimaryText, MarkdownElement } from "nirvana-react-elements"

import chevronDownIcon from "../../assets/images/icons/chevron-down-dark.svg"
import chevronUpIcon from "../../assets/images/icons/chevron-up-dark.svg"

export const SupportContentArticleViewComponent: React.FunctionComponent<
    ISupportContentArticleViewComponentProps
> = props => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.item.subcategoryLabel ? (
                <div className="mt-70px mb-16px">
                    <PrimaryText typography="h3">
                        {props.item.subcategoryLabel}
                    </PrimaryText>
                </div>
            ) : null}

            <div
                className={`
                    bg-brand-warmLight05 p-16px
                    ${
                        props.item.isCollapsible && isCollapsed
                            ? "cursor-pointer"
                            : ""
                    }
                `}
                onClick={() =>
                    props.item.isCollapsible &&
                    isCollapsed &&
                    setIsCollapsed(current => !current)
                }
            >
                <div
                    className={`
                        flex items-center
                        ${
                            props.item.isCollapsible && !isCollapsed
                                ? "cursor-pointer"
                                : ""
                        }
                    `}
                    onClick={() =>
                        props.item.isCollapsible &&
                        !isCollapsed &&
                        setIsCollapsed(current => !current)
                    }
                >
                    {props.item.title && !props.item.isArticleTitleHidden ? (
                        <>
                            {props.item.isCollapsible ? (
                                <PrimaryText
                                    typography="textSemibold"
                                    className="flex-1 mr-24px"
                                >
                                    {props.item.title}
                                </PrimaryText>
                            ) : (
                                <PrimaryText typography="h3">
                                    {props.item.title}
                                </PrimaryText>
                            )}
                        </>
                    ) : (
                        <div className="flex-1" />
                    )}

                    {props.item.isCollapsible ? (
                        <div>
                            <img
                                src={
                                    isCollapsed
                                        ? chevronDownIcon
                                        : chevronUpIcon
                                }
                                alt="Collapse"
                            />
                        </div>
                    ) : null}
                </div>

                {props.item.description &&
                (!props.item.isCollapsible || !isCollapsed) ? (
                    <MarkdownElement
                        className="contentful-markdown block mt-24px"
                        text={props.item.description}
                    />
                ) : null}

                {props.item.youTubeEmbedVideoUrl ? (
                    <div className="mt-24px mb-24px">
                        <iframe
                            width="690"
                            height="397"
                            src={props.item.youTubeEmbedVideoUrl}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="rounded-8px"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
