/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import {
    AutoCompleteElement,
    ButtonElement,
    LookupHelper,
    PrimaryText,
    RadioElement,
    useStateCallback,
    VALIDATION_CONFIG,
    PopupWrapperElement,
} from "nirvana-react-elements"

import {
    coveragePayersCreate,
    coveragePayersUpdate,
} from "../../store/thunks/coveragePayers.thunks"
import { COVERAGE_CONFIG } from "../../config/coverage.config"
import { LookupService } from "../../services/lookup.service"
import { CoveragePayersRatesManagementComponent } from "../organization/coveragePayers/coveragePayersRatesManagement.component"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import arrowRightImg from "../../assets/images/icons/arrow-right.svg"
import removeIcon from "../../assets/images/icons/trash-red.svg"
import searchIcon from "../../assets/images/icons/search-dark.svg"

const PAYERS_INPUT_ROOT_KEY = "payers"

export const UpsertCoveragePayerPopupComponent: React.FunctionComponent<
    IUpsertCoveragePayerPopupComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        register,
        unregister,
        watch,
    } = useForm()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    // Set initial amount of rows: default to 1 row (so [0])
    const [inputDataIndexes, setInputDataIndexes] = useStateCallback<number[]>([
        0,
    ])

    const canManagePayers = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.managePayersConfiguration,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    // Reset indexes when hiding component
    useEffect(() => {
        if (!props.isActive) {
            return
        }

        return () => {
            setInputDataIndexes([0])
        }
    }, [props.isActive])

    // Set default values for editing existing payer
    useEffect(() => {
        if (!props.existingPayer) {
            return
        }

        // change indexes in state
        // cb here is called after this specific state update is done
        setInputDataIndexes([0], newValues => {
            setTimeout(() => {
                try {
                    const fieldGroupName = `${PAYERS_INPUT_ROOT_KEY}[0]`

                    if (props.existingPayer?.payer) {
                        setValue(
                            `${fieldGroupName}.payer`,
                            props.existingPayer?.payer
                        )
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        })
    }, [props.existingPayer])

    // UNCOMMENT BELOW TO ADD ABILITY TO ADD MULTIPLE PAYERS AT A TIME
    // COMMENTED OUT FOR NOW BECAUSE NOT IN DESIGN, BUT CAN BE A USEFUL FEATURE
    // const onAddPayerRow = () => {
    //     if (
    //         props.existingPayer ||
    //         inputDataIndexes.length >=
    //             COVERAGE_CONFIG.payerCreationMaxBulkAmount
    //     ) {
    //         return
    //     }
    //
    //     setInputDataIndexes(prevIndexes => [
    //         ...prevIndexes,
    //         Math.max(...prevIndexes, 0) + 1,
    //     ])
    // }

    const onRemovePayerRow = (index: number) => {
        if (inputDataIndexes.length < 2) {
            return
        }

        setInputDataIndexes(prevIndexes => [
            ...prevIndexes.filter(item => item !== index),
        ])
    }

    const onSave = (data: any) => {
        if (!selectedPracticeRole || !canManagePayers) {
            return
        }

        // Removing empty items that appear after add / remove indexes
        // Also remove data that is not needed anymore
        const coveragePayers = data[PAYERS_INPUT_ROOT_KEY].filter(
            item => !!item
        ).map(item => {
            const neededValues: IPayerUpsertData = {
                ...item,

                // Removing empty items that appear after add / remove indexes
                rates: item.rates.filter(item => !!item),
            }

            return neededValues
        }) as IPayerUpsertData[]

        if (!coveragePayers.length) {
            return
        }

        // If existing payer passed - update it
        // Otherwise create payers in bulk
        if (props.existingPayer) {
            dispatch(
                coveragePayersUpdate({
                    payload: {
                        payerId: props.existingPayer.id,
                        updateData: coveragePayers[0],
                    },
                    practice: selectedPracticeRole.practice,
                })
            )
        } else {
            for (const payer of coveragePayers) {
                dispatch(
                    coveragePayersCreate({
                        payload: payer,
                        practice: selectedPracticeRole.practice,
                    })
                )
            }
        }

        props.onDone()
    }

    return (
        <PopupWrapperElement
            isActive={!!props.isActive && canManagePayers}
            className={props.className}
            onDone={props.onDone}
            popupWidthClassName="w-535px rounded-24px!"
        >
            <div className="flex items-center">
                <PrimaryText typography="h3" className="flex-1 mr-24px">
                    {props.existingPayer ? "Edit" : "Add"} Payer
                </PrimaryText>

                <div className="cursor-pointer" onClick={props.onDone}>
                    <img src={closeIcon} alt="Close" title="Close" />
                </div>
            </div>

            <form className="relative" onSubmit={handleSubmit(onSave)}>
                {inputDataIndexes.map(index => {
                    const fieldGroupName = `${PAYERS_INPUT_ROOT_KEY}[${index}]`
                    const fieldGroupErrors =
                        errors?.[PAYERS_INPUT_ROOT_KEY]?.[index]

                    return (
                        <div key={index} className="relative mt-44px">
                            {/*REMOVE if more than one*/}
                            {inputDataIndexes.length > 1 && (
                                <div className="absolute top-16px right--24px z-10">
                                    <img
                                        className="cursor-pointer"
                                        src={removeIcon}
                                        alt="Remove"
                                        onClick={() => onRemovePayerRow(index)}
                                    />
                                </div>
                            )}

                            <fieldset
                                name={fieldGroupName}
                                key={fieldGroupName}
                            >
                                <AutoCompleteElement
                                    name="payer"
                                    fieldGroupName={fieldGroupName}
                                    label="Payer (ID or Name)"
                                    placeholder=""
                                    inputSuffix={searchIcon}
                                    shouldValidate={true}
                                    dataFetcher={(search?: string) =>
                                        LookupService.lookupPayers(search)
                                    }
                                    itemRenderer={item =>
                                        LookupHelper.getRenderedPayer(item)
                                    }
                                    defaultValue={
                                        inputDataIndexes.length === 1 &&
                                        props.existingPayer?.payer
                                            ? LookupHelper.getRenderedPayer(
                                                  props.existingPayer.payer
                                              ).displayValue
                                            : undefined
                                    }
                                    debounceMilliseconds={250}
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookFormErrors={fieldGroupErrors}
                                    reactHookFormSet={setValue}
                                    validations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                />

                                <CoveragePayersRatesManagementComponent
                                    className="mt-52px"
                                    predefinedRates={props.existingPayer?.rates}
                                    fieldGroupName={fieldGroupName}
                                    reactHookErrors={fieldGroupErrors}
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookFormSet={setValue}
                                    reactHookControl={control}
                                    reactHookFormWatch={watch}
                                />

                                <PrimaryText className="mt-32px">
                                    Type of check(s) run for this NPI, by
                                    default:
                                </PrimaryText>

                                <RadioElement
                                    className="mt-16px"
                                    name="defaultCoverageCheckNetwork"
                                    direction="vertical"
                                    fieldGroupName={fieldGroupName}
                                    items={Object.values(
                                        COVERAGE_CONFIG.payerCoverageCheckNetworkMapped
                                    ).map(item => ({
                                        label:
                                            item.dropdownDisplayValue ||
                                            item.displayValue,
                                        value: item.value,
                                    }))}
                                    defaultValue={
                                        props.existingPayer
                                            ?.defaultCoverageCheckNetwork ||
                                        COVERAGE_CONFIG
                                            .payerCoverageCheckNetworkMapped.IN
                                            .value
                                    }
                                    reactHookFormSet={setValue}
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                />
                            </fieldset>

                            <PrimaryText
                                typography="caption"
                                className="mt-32px"
                            >
                                You can always modify this before you run the
                                check.
                            </PrimaryText>
                        </div>
                    )
                })}

                {/*UNCOMMENT BELOW TO ADD ABILITY TO ADD MULTIPLE PAYERS AT A TIME*/}
                {/*COMMENTED OUT FOR NOW BECAUSE NOT IN DESIGN, BUT CAN BE A USEFUL FEATURE*/}
                {/*{!props.existingPayer &&*/}
                {/*inputDataIndexes.length <*/}
                {/*    COVERAGE_CONFIG.payerCreationMaxBulkAmount ? (*/}
                {/*    <ButtonElement*/}
                {/*        className="mt-24px"*/}
                {/*        buttonCss={{*/}
                {/*            width: "100%",*/}
                {/*            display: "flex !important",*/}
                {/*            justifyContent: "center",*/}
                {/*        }}*/}
                {/*        label="Add another payer"*/}
                {/*        icon={userPlusIcon}*/}
                {/*        onClick={onAddPayerRow}*/}
                {/*    />*/}
                {/*) : null}*/}

                <div className="mt-32px flex">
                    <div className="flex-1" />

                    {/*SUBMIT BTN*/}
                    <ButtonElement
                        label="Save"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        icon={arrowRightImg}
                        isRightIcon
                    />
                </div>
            </form>
        </PopupWrapperElement>
    )
}
