/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import { HtmlHelper } from "../../helpers/html.helper"

export const CoverageBreakdownBadgeElement: React.FunctionComponent<
    ICoverageBreakdownBadgeElementProps
> = props => {
    const context = useMemo<JSX.Element>(() => {
        return HtmlHelper.getHtmlWrappedBadge(
            props.label,
            props.value,
            props.tooltip,
            props.isActive,
            props.customBgClassName,
            props.labelTypography,
            props.valueTypography
        )
    }, [props])

    return <div className={props.className}>{context}</div>
}
