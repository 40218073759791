import { createAsyncThunk } from "@reduxjs/toolkit"
import { UtilHelper } from "nirvana-react-elements"

import {
    authReset,
    authSetCheckUserInvitation,
    authSetForgotPasswordEmail,
    authSetIsLoading,
    authSetVerificationData,
} from "../slices/auth.slice"
import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { AuthService } from "../../services/auth.service"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { AuthHelper } from "../../helpers/auth.helper"
import { ToastrHelper } from "../../helpers/toastr.helper"

/**
 * Login
 */
export const authLogin = createAsyncThunk<void, IThunkActionData<ILoginData>>(
    "authLoginPrefix",
    async (action, { dispatch }) => {
        dispatch(authSetIsLoading(true))

        try {
            const result = await AuthService.login(action.payload)

            if (result) {
                if (result.errorData?.needEmailVerification) {
                    dispatch(authSetVerificationData(action.payload))

                    // So it's processed in next tick
                    setTimeout(() => {
                        UtilHelper.redirectTo(
                            ROUTES_CONFIG.verifyEmailUrl,
                            action.navigate
                        )
                    })

                    return
                }

                if (result.token) {
                    AuthHelper.login(result.token, action.navigate)
                }

                action.onSuccess && action.onSuccess(result)
            } else {
                action.onError && action.onError(result)
            }
        } catch (e) {
        } finally {
            dispatch(authSetIsLoading(false))

            action.onFinally && action.onFinally()
        }
    }
)

/**
 * Register
 */
export const authRegister = createAsyncThunk<
    void,
    IThunkActionData<IRegisterData>
>("authRegisterPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.register(action.payload)

        if (result) {
            dispatch(authReset())

            UtilHelper.redirectTo(
                ROUTES_CONFIG.registerSuccessUrl +
                    `?email=${action.payload.emailData?.email}`,
                action.navigate
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Check if email is available
 */
export const authCheckEmailAvailable = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authCheckEmailAvailablePrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.emailAvailable(action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Resend verification link
 */
export const authResendVerificationLink = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authResendVerificationLinkPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.resendVerificationLink(action.payload)

        if (result) {
            ToastrHelper.success("Verification link was resent")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Init forgot password flow
 */
export const authForgotPassword = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authForgotPasswordPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.forgotPassword(action.payload)

        if (result) {
            dispatch(
                authSetForgotPasswordEmail({
                    email: action.payload,
                })
            )

            UtilHelper.redirectTo(
                ROUTES_CONFIG.restoreProcessUrl,
                action.navigate
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Process forgot password flow
 */
export const authForgotPasswordProcess = createAsyncThunk<
    void,
    IThunkActionData<IPasswordRestoreFinishData>
>("authForgotPasswordProcessPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.forgotPasswordProcess(action.payload)

        if (result) {
            if (result.errorData?.needEmailVerification) {
                dispatch(authSetVerificationData(action.payload))

                return UtilHelper.redirectTo(
                    ROUTES_CONFIG.verifyEmailUrl,
                    action.navigate
                )
            }

            if (result.token) {
                AuthHelper.login(result.token, action.navigate)
            }

            ToastrHelper.success("Your password was successfully changed!")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Verify email
 */
export const authVerifyEmail = createAsyncThunk<
    void,
    IThunkActionData<IVerifyEmailData>
>("authVerifyEmailPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.verifyEmail(action.payload)

        if (result) {
            AuthHelper.login(result.token, action.navigate)

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Confirm user
 */
export const authConfirmUser = createAsyncThunk<
    void,
    IThunkActionData<IConfirmUserData>
>("authConfirmUserPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.confirmUser(
            action.payload.email,
            action.payload.confirmationCode
        )

        if (result) {
            ToastrHelper.success(
                "Congratulations, your account was confirmed. You can now login using your credentials"
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()

        UtilHelper.redirectTo(ROUTES_CONFIG.loginUrl, action.navigate)
    }
})

/**
 * Check invite token
 */
export const authCheckInviteToken = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authCheckInviteTokenPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("authCheckInviteTokenLoading"))

    try {
        const result = await AuthService.checkInviteToken(action.payload)

        dispatch(authSetCheckUserInvitation(result))

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("authCheckInviteTokenLoading"))

        action.onFinally && action.onFinally()
    }
})
