import { useMemo, useState } from "react"

import { AvailableCoveragePortalPermission } from "../config/rolesPermissions.config"
import { PermissionsHelper } from "../helpers/permissions.helper"
import { useAppSelector } from "../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../store/selectors/selectedPracticeRole.selector"
import { useIsFeatureFlagEnabled } from "./isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../config/featureFlags.config"
import { useBulkOverrides } from "./bulkOverrides.hooks"

export const usePoliciesList = () => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { isOverridesEnabled } = useBulkOverrides()

    const isFlagsResolutionEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_FLAGS_RESOLUTION
    )

    const [selectedPolicies, setSelectedPolicies] = useState<
        ICoverageCheckHistory[]
    >([])

    const canManageFlags = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.managePolicyFlags,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const canManageOverrides = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.overrideCoverageData,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const isPolicySelected = (policy: ICoverageCheckHistory): boolean => {
        return !!selectedPolicies.find(
            item =>
                item.coverageResult.resultId === policy.coverageResult.resultId
        )
    }

    const onPoliciesSelected = (items: ICoverageCheckHistory[]) => {
        const newNotSelected = items.filter(item => !isPolicySelected(item))

        setSelectedPolicies(current => [...current, ...newNotSelected])
    }

    const onPoliciesDeselected = (items: ICoverageCheckHistory[]) => {
        const notNeededRequestIds = items.map(
            item => item.coverageResult.resultId
        )

        setSelectedPolicies(current =>
            current.filter(
                policy =>
                    !notNeededRequestIds.includes(
                        policy.coverageResult.resultId
                    )
            )
        )
    }

    const onDeselectAllPolicies = () => {
        setSelectedPolicies([])
    }

    return {
        // vars
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageFlags,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        isPolicySelected,
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    }
}
