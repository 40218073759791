/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    SpinnerElement,
    UtilHelper,
    PageHelmetElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { GENERAL_CONFIG } from "../../config/general.config"
import { authConfirmUser } from "../../store/thunks/auth.thunks"
import { useAppDispatch } from "../../store/appDispatch.hook"

export const ConfirmUserComponent: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const email = UtilHelper.getRawUrlSearchParam(
            GENERAL_CONFIG.urlSearchParamsKeys.email
        )

        const confirmationCode = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.verificationCode
        )

        if (!email || !confirmationCode) {
            ToastrHelper.error("Bad data provided, please try again")

            return navigate(ROUTES_CONFIG.loginUrl)
        }

        dispatch(
            authConfirmUser({
                payload: {
                    email,
                    confirmationCode,
                },
                navigate,
            })
        )
    }, [])

    return (
        <div>
            <PageHelmetElement
                title="Confirm Account"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <SpinnerElement containerClassName="text-center mt-20px" />
        </div>
    )
}
