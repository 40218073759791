import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { SupportService } from "../../services/support.service"
import { ToastrHelper } from "../../helpers/toastr.helper"

/**
 * Report coverage result
 */
export const supportReportCoverageResult = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IReportCoverageResultData>
>("supportReportCoverageResultPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("supportReportCoverageResultLoading"))

    try {
        const result = await SupportService.reportCoverageResult(
            action.payload.message,
            action.payload.coverageResult,
            action.practice
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)

            ToastrHelper.success(
                "Thank you. Nirvana Team received your request and will process it soon"
            )
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("supportReportCoverageResultLoading"))

        action.onFinally && action.onFinally()
    }
})
