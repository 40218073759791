import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import moment from "moment-timezone"

import {
    AvailableCoverageHistorySortDirection,
    AvailableCoverageHistorySortField,
    POLICIES_CONFIG,
} from "../../config/policies.config"
import {
    policiesGetHistoryList,
    policiesGetOverridesList,
    policiesGetPlanYearResetsList,
    policiesGetReportsAggregationsFlags,
    policiesGetReportsAggregationsStatusCodes,
    policiesGetReportsList,
} from "../thunks/policies.thunks"
import { UtilHelper } from "../../helpers/util.helper"
import { BrowserStorageHelper } from "../../helpers/browserStorageHelper"

const initialState: IPoliciesState = {
    // GENERAL
    visualizationsCollapseTrigger: null,
    policiesHeaderDragDropDisabled: false,
    policiesGettingFullListProgress: 0,
    // SINGLE POLICY
    selectedItem: null,
    selectedItemComments: [],
    // REVIEW / HISTORY
    historyItems: [],
    historyFilters: {},
    historySort: {
        sortBy: AvailableCoverageHistorySortField.timestamp,
        sortDirection: AvailableCoverageHistorySortDirection.DESC,
    },
    historyPagination: UtilHelper.getPagination(),
    historyIsError: false,
    // REPORTS
    reportsItems: [],
    reportsPagination: UtilHelper.getPagination(),
    reportsFilters:
        BrowserStorageHelper.getDefaultReportsPoliciesFiltersConfiguration(
            moment()
                .subtract(POLICIES_CONFIG.defaultReportsGoBackDays, "days")
                .toDate()
        ),
    reportsSort: {
        sortBy: AvailableCoverageHistorySortField.timestamp,
        sortDirection: AvailableCoverageHistorySortDirection.DESC,
    },
    reportsIsError: false,
    reportsAggregationsStatusCodes: null,
    reportsAggregationsFlags: null,
    // OVERRIDES
    overridesItems: [],
    overridesPagination: UtilHelper.getPagination(),
    overridesFilters:
        BrowserStorageHelper.getDefaultOverridesPoliciesFiltersConfiguration(
            moment()
                .subtract(POLICIES_CONFIG.defaultReportsGoBackDays, "days")
                .toDate()
        ),
    overridesSort: {
        sortBy: AvailableCoverageHistorySortField.overrideExpirationDate,
        sortDirection: AvailableCoverageHistorySortDirection.ASC,
    },
    overridesIsError: false,
    // PLAN YEAR RESETS
    planYearResetsItems: [],
    planYearResetsPagination: UtilHelper.getPagination(),
    planYearResetsFilters:
        BrowserStorageHelper.getDefaultPlanYearResetsPoliciesFiltersConfiguration(
            moment()
                .subtract(POLICIES_CONFIG.defaultGoBackMonths, "months")
                .toDate()
        ),
    planYearResetsSort: {
        sortBy: AvailableCoverageHistorySortField.timestamp,
        sortDirection: AvailableCoverageHistorySortDirection.DESC,
    },
    planYearResetsIsError: false,
}

const policiesSlice = createSlice({
    name: "policies",
    initialState,
    reducers: {
        policiesReset: () => initialState,

        policiesSetVisualizationsCollapseTrigger: (
            state,
            action: PayloadAction<number | null | undefined>
        ) => {
            state.visualizationsCollapseTrigger =
                typeof action.payload === "undefined"
                    ? new Date().getTime()
                    : action.payload
        },

        policiesSetHeaderDragDropDisabled: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.policiesHeaderDragDropDisabled = action.payload
        },

        // SINGLE POLICY

        policiesSetSelectedItem: (
            state,
            action: PayloadAction<ICoverageCheckHistory | null>
        ) => {
            state.selectedItem = action.payload
        },

        policiesSetSelectedItemComments: (
            state,
            action: PayloadAction<IPolicyComment[]>
        ) => {
            state.selectedItemComments = action.payload
        },

        policiesAddedSelectedItemComments: (
            state,
            action: PayloadAction<IPolicyComment>
        ) => {
            const { replyToComment } = action.payload

            const isExistingComment = (
                list: IPolicyComment[],
                newComment: IPolicyComment
            ): boolean => {
                return !!list.find(item => item.id === newComment.id)
            }

            // If it wasn't reply
            if (!replyToComment) {
                if (
                    isExistingComment(
                        state.selectedItemComments,
                        action.payload
                    )
                ) {
                    return
                }

                state.selectedItemComments = [
                    action.payload,
                    ...state.selectedItemComments,
                ]

                return
            }

            state.selectedItemComments = state.selectedItemComments.map(
                item => {
                    if (
                        item.id !== replyToComment.id ||
                        isExistingComment(item.replies || [], action.payload)
                    ) {
                        return item
                    }

                    return {
                        ...item,
                        replies: [...(item.replies || []), action.payload],
                    }
                }
            )
        },

        policiesDeletedSelectedItemComment: (
            state,
            action: PayloadAction<{ deletedId: number }>
        ) => {
            state.selectedItemComments = state.selectedItemComments
                .filter(item => item.id !== action.payload.deletedId)
                .map(item =>
                    !item.replies?.length
                        ? item
                        : {
                              ...item,

                              replies: item.replies.filter(
                                  innerItem =>
                                      innerItem.id !== action.payload.deletedId
                              ),
                          }
                )
        },

        policiesUpdatedSelectedItemComment: (
            state,
            action: PayloadAction<IPolicyComment>
        ) => {
            state.selectedItemComments = state.selectedItemComments.map(item =>
                item.id === action.payload.id
                    ? action.payload
                    : item.replies?.length
                    ? {
                          ...item,
                          replies: item.replies.map(innerItem =>
                              innerItem.id === action.payload.id
                                  ? action.payload
                                  : innerItem
                          ),
                      }
                    : item
            )
        },

        // REVIEW / HISTORY

        policiesSetHistoryFilters: (
            state,
            action: PayloadAction<IPoliciesListFiltersData>
        ) => {
            state.historyFilters = action.payload
        },

        policiesSetHistorySort: (
            state,
            action: PayloadAction<IPoliciesListSortData>
        ) => {
            state.historySort = action.payload
        },

        policiesSetHistoryList: (
            state,
            action: PayloadAction<ICoverageHistoryListResult>
        ) => {
            state.historyItems = action.payload.items
            state.historyPagination = action.payload.newPagination
            state.historyIsError = action.payload.isError
        },

        // REPORTS

        policiesSetReportsList: (
            state,
            action: PayloadAction<ICoverageHistoryListResult>
        ) => {
            state.reportsItems = action.payload.items
            state.reportsPagination = action.payload.newPagination
            state.reportsIsError = action.payload.isError
        },

        policiesSetReportsFilters: (
            state,
            action: PayloadAction<IPoliciesListFiltersData>
        ) => {
            if (action.payload.flags?.length) {
                state.visualizationsCollapseTrigger = new Date().getTime()
            }

            state.reportsFilters = action.payload
        },

        policiesSetReportsSort: (
            state,
            action: PayloadAction<IPoliciesListSortData>
        ) => {
            state.reportsSort = action.payload
        },

        policiesSetGettingFullListProgress: (
            state,
            action: PayloadAction<number>
        ) => {
            state.policiesGettingFullListProgress = action.payload
        },

        policiesIncreaseGetFullListProgress: state => {
            // Don't auto increase if new value > 99
            const newValue = state.policiesGettingFullListProgress + 1

            if (newValue < 100) {
                state.policiesGettingFullListProgress = newValue
            }
        },

        policiesSetReportsAggregationsStatusCodes: (
            state,
            action: PayloadAction<IGetCoverageChecksAggregationsStatusCodes | null>
        ) => {
            state.reportsAggregationsStatusCodes = action.payload
        },

        policiesSetReportsAggregationsFlags: (
            state,
            action: PayloadAction<IGetCoverageChecksAggregationsFlags | null>
        ) => {
            state.reportsAggregationsFlags = action.payload
        },

        // OVERRIDES

        policiesSetOverridesList: (
            state,
            action: PayloadAction<ICoverageHistoryListResult>
        ) => {
            state.overridesItems = action.payload.items
            state.overridesPagination = action.payload.newPagination
            state.overridesIsError = action.payload.isError
        },

        policiesSetOverridesFilters: (
            state,
            action: PayloadAction<IPoliciesListFiltersData>
        ) => {
            state.overridesFilters = action.payload
        },

        policiesSetOverridesSort: (
            state,
            action: PayloadAction<IPoliciesListSortData>
        ) => {
            state.overridesSort = action.payload
        },

        // PLAN YEAR RESETS

        policiesSetPlanYearResetsList: (
            state,
            action: PayloadAction<ICoverageHistoryListResult>
        ) => {
            state.planYearResetsItems = action.payload.items
            state.planYearResetsPagination = action.payload.newPagination
            state.planYearResetsIsError = action.payload.isError
        },

        policiesSetPlanYearResetsFilters: (
            state,
            action: PayloadAction<IPoliciesListFiltersData>
        ) => {
            state.planYearResetsFilters = action.payload
        },

        policiesSetPlanYearResetsSort: (
            state,
            action: PayloadAction<IPoliciesListSortData>
        ) => {
            state.planYearResetsSort = action.payload
        },
    },
    // Must add such approach if async thunk needs to be aborted, and add data only if fulfilled
    extraReducers: builder => {
        builder.addCase(
            policiesGetReportsAggregationsStatusCodes.fulfilled,
            (state, action) => {
                policiesSlice.caseReducers.policiesSetReportsAggregationsStatusCodes(
                    state,
                    action
                )
            }
        )

        builder.addCase(
            policiesGetReportsAggregationsFlags.fulfilled,
            (state, action) => {
                policiesSlice.caseReducers.policiesSetReportsAggregationsFlags(
                    state,
                    action
                )
            }
        )

        builder.addCase(policiesGetHistoryList.fulfilled, (state, action) => {
            policiesSlice.caseReducers.policiesSetHistoryList(state, action)
        })

        builder.addCase(policiesGetReportsList.fulfilled, (state, action) => {
            policiesSlice.caseReducers.policiesSetReportsList(state, action)
        })

        builder.addCase(policiesGetOverridesList.fulfilled, (state, action) => {
            policiesSlice.caseReducers.policiesSetOverridesList(state, action)
        })

        builder.addCase(
            policiesGetPlanYearResetsList.fulfilled,
            (state, action) => {
                policiesSlice.caseReducers.policiesSetPlanYearResetsList(
                    state,
                    action
                )
            }
        )
    },
})

export const {
    policiesReset,
    policiesSetVisualizationsCollapseTrigger,
    policiesSetHeaderDragDropDisabled,
    policiesSetGettingFullListProgress,
    policiesIncreaseGetFullListProgress,

    policiesSetSelectedItem,
    policiesSetSelectedItemComments,
    policiesAddedSelectedItemComments,
    policiesDeletedSelectedItemComment,
    policiesUpdatedSelectedItemComment,

    policiesSetHistoryFilters,
    policiesSetHistoryList,
    policiesSetHistorySort,

    policiesSetReportsList,
    policiesSetReportsFilters,
    policiesSetReportsSort,

    policiesSetOverridesList,
    policiesSetOverridesFilters,
    policiesSetOverridesSort,

    policiesSetPlanYearResetsList,
    policiesSetPlanYearResetsFilters,
    policiesSetPlanYearResetsSort,
} = policiesSlice.actions

export const policiesReducer = policiesSlice.reducer
