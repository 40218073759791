import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeSetEnabledFeatureFlags,
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { FeatureFlagsService } from "../../services/featureFlags.service"

/**
 * Get enabled feature flags for practice / user
 */
export const runtimeGetEnabledFeatureFlags = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<undefined>
>("runtimeGetEnabledFeatureFlagsPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("runtimeGetEnabledFeatureFlagsLoading"))

    try {
        const result = await FeatureFlagsService.getEnabledFeatureFlags(
            action.practice
        )

        if (result) {
            dispatch(runtimeSetEnabledFeatureFlags(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            ToastrHelper.error(
                "Failed to get enabled feature flags. Please try again or contact support."
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("runtimeGetEnabledFeatureFlagsLoading"))

        action.onFinally && action.onFinally()
    }
})
