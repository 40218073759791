/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    SelectElement,
    ISelectRenderedOption,
    ButtonElement,
    useStateCallback,
    PrimaryText,
} from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { organizationSelector } from "../../../store/selectors/organization.selector"
import { organizationUsersGetList } from "../../../store/thunks/organization.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"

import plusIcon from "../../../assets/images/icons/plus-dark.svg"

export const BillerGroupUsersAddComponent: React.FunctionComponent<
    IBillerGroupUsersAddComponentProps
> = props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const organizationState = useAppSelector(organizationSelector)

    const [selectKey, setSelectKey] = useState<number>(new Date().getTime())
    const [selectedUsers, setSelectedUsers] = useStateCallback<
        IOrganizationUser[]
    >([])

    // On mount load all users from backend if not already loaded
    useEffect(() => {
        if (organizationState.users.length || !selectedPracticeRole) {
            return
        }

        dispatch(
            organizationUsersGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )
    }, [selectedPracticeRole?.id])

    const getUserDisplayValue = (user: IOrganizationUser): string => {
        return user.name || user.id.toString()
    }

    const availableUserOptions = useMemo<ISelectRenderedOption[]>(() => {
        return organizationState.users
            .filter(
                user =>
                    !props.existingUsers.map(item => item.id).includes(user.id)
            )
            .map(user => ({
                displayValue: getUserDisplayValue(user),
                value: user,
            }))
    }, [props.existingUsers, organizationState.users])

    const getCustomUserSelectContent = (): JSX.Element => {
        return (
            <div
                className="p-8px cursor-pointer flex items-center"
                onClick={() => {
                    navigate(
                        `${ROUTES_CONFIG.organizationUrl}?${GENERAL_CONFIG.urlSearchParamsKeys.tab}=1&${GENERAL_CONFIG.urlSearchParamsKeys.create}=true`
                    )
                }}
            >
                <img className="mr-8px" src={plusIcon} alt="Add" />

                <PrimaryText typography="h6">Add a new user</PrimaryText>
            </div>
        )
    }

    const onUserSelected = (user: IOrganizationUser) => {
        setSelectedUsers(current => [user, ...current])
    }

    const onUserDeselected = (userDisplayValue: string) => {
        setSelectedUsers(current =>
            current.filter(
                user => userDisplayValue !== getUserDisplayValue(user)
            )
        )
    }

    const reset = () => {
        setSelectedUsers([])

        // This will give warning in console
        // Related to some antd internals, not affecting our app
        setSelectKey(new Date().getTime())
    }

    const onSubmitSelectedUsers = () => {
        props.onUsersAdded(selectedUsers)
        reset()
    }

    return (
        <div
            key={selectKey}
            className={`
                relative flex items-center
                ${props.className}
            `}
        >
            <SelectElement
                className="flex-1 mr-24px"
                name="users"
                mode="multiple"
                placeholder="Add biller(s)"
                renderedOptions={availableUserOptions}
                getCustomDropdownContent={getCustomUserSelectContent}
                onSelected={onUserSelected}
                onDeselected={onUserDeselected}
            />

            <ButtonElement
                label="Add"
                htmlType="button"
                type="default"
                onClick={onSubmitSelectedUsers}
                disabled={!selectedUsers.length}
            />
        </div>
    )
}
