/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import awesomeDebouncePromise from "awesome-debounce-promise"
import {
    AvailableFAQType,
    InputElement,
    ISupportContentCategory,
    PrimaryText,
    SpinnerElement,
    useFaqs,
} from "nirvana-react-elements"

import { supportSetSelectedContentCategory } from "../../store/slices/support.slice"
import { SupportContentCategoryCardComponent } from "./supportContentCategoryCard.component"
import { SupportContentCategoryViewComponent } from "./supportContentCategoryView.component"
import { SupportNavbarComponent } from "./supportNavbar.component"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { supportSelector } from "../../store/selectors/support.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"

import searchIcon from "../../assets/images/icons/search-dark.svg"

export const SupportContentComponent: React.FunctionComponent<{
    className?: string
    withSearch?: boolean
}> = props => {
    const dispatch = useAppDispatch()

    const { faqs } = useFaqs<ISupportContentCategory>(
        AvailableFAQType.coveragePortalMain
    )

    const supportState = useAppSelector(supportSelector)

    const [searchValue, setSearchValue] = useState<string>()

    const [filteredContentCategories, setFilteredContentCategories] = useState<
        ISupportContentCategory[]
    >([])

    // On unmount unset list of selected categories
    useEffect(() => {
        return () => {
            onCategorySelected(null)
        }
    }, [])

    // Once we've got new content categories from API - set them in local state
    useEffect(() => {
        if (!faqs.length) {
            return
        }

        setFilteredContentCategories(faqs)
    }, [faqs])

    const onSearchCategories = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const search = event.target.value.toLowerCase()

        setSearchValue(search || undefined)

        // Set all categories as available
        if (!search) {
            setFilteredContentCategories(faqs)

            return
        }

        // Filter category by it's title and description
        // And also by inner article's titles and description
        // It also searches inside child categories, calling the same filter function
        // TODO once we have more articles, make through API instead
        const isValidSearchedCategory = (
            item: ISupportContentCategory
        ): boolean => {
            return (
                item.shortTitle.toLowerCase().includes(search) ||
                item.shortDescription?.toLowerCase().includes(search) ||
                item.title.toLowerCase().includes(search) ||
                item.description?.toLowerCase().includes(search) ||
                (!!item.articles?.length &&
                    !!item.articles.find(
                        article =>
                            article.title?.toLowerCase().includes(search) ||
                            article.description?.toLowerCase().includes(search)
                    )) ||
                (!!item.childCategories?.length &&
                    !!item.childCategories.filter(isValidSearchedCategory)
                        .length)
            )
        }

        setFilteredContentCategories(faqs.filter(isValidSearchedCategory))
    }

    const onCategorySelected = (category: ISupportContentCategory | null) => {
        dispatch(supportSetSelectedContentCategory(category))
    }

    return (
        <div className={props.className}>
            {faqs.length ? (
                <div className={faqs.length > 1 ? "flex" : ""}>
                    <div
                        className={
                            faqs.length > 1 ? "w-245px mr-65px" : "max-w-500px"
                        }
                    >
                        {/*LOCAL FRONT END SEARCH FOR NOW*/}
                        {props.withSearch && (
                            <InputElement
                                name="search"
                                placeholder="Search"
                                onChange={awesomeDebouncePromise(
                                    onSearchCategories,
                                    1000
                                )}
                                inputPrefix={
                                    <img
                                        src={searchIcon}
                                        alt="Search"
                                        className="mr-8px"
                                    />
                                }
                            />
                        )}

                        <SupportNavbarComponent
                            contentCategories={filteredContentCategories}
                        />
                    </div>

                    <div className={faqs.length > 1 ? "flex-1" : "mt-32px"}>
                        {supportState.selectedContentCategory ? (
                            <SupportContentCategoryViewComponent
                                item={supportState.selectedContentCategory}
                                searchValue={searchValue}
                                isCategoryTitleHidden={true}
                            />
                        ) : (
                            <>
                                <PrimaryText
                                    typography="h4"
                                    className="mb-12px"
                                >
                                    Welcome to our Support site! <br />
                                    Browse the content below to learn more about
                                    using Concierge.
                                    <br />
                                    Have an issue that needs immediate
                                    attention?{" "}
                                    <a
                                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                    >
                                        Contact our Help Desk
                                    </a>
                                </PrimaryText>

                                <div className="flex items-stretch flex-wrap">
                                    {filteredContentCategories.map(
                                        (item, index) =>
                                            item.isShownOnMainSupportPage ? (
                                                <SupportContentCategoryCardComponent
                                                    className="flex-basis-50 mt-24px pr-24px"
                                                    item={item}
                                                    key={index}
                                                />
                                            ) : null
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <SpinnerElement
                    containerClassName="text-center mt-120px"
                    size="large"
                />
            )}
        </div>
    )
}
