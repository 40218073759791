/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"

import { BillerGroupCreationComponent } from "./billerGroupCreation.component"
import { BillerGroupInformationComponent } from "./billerGroupInformation.component"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { BillerGroupUsersInformationComponent } from "./billerGroupUsersInformation.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { billerGroupsSelector } from "../../../store/selectors/billerGroups.selector"

export const BillerGroupMainComponent: React.FunctionComponent<
    IBillerGroupMainComponentProps
> = props => {
    const [isCreation, setIsCreation] = useState<boolean>(false)

    const billerGroups = useAppSelector(billerGroupsSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const isLoading = useMemo(
        () =>
            RuntimeHelper.isBillerGroupsSingleItemLoading() ||
            RuntimeHelper.isBillerGroupsSingleItemDeleteLoading(),
        [runtimeState.isLoading]
    )

    // Enable creation when triggered from outside
    useEffect(() => {
        setIsCreation(!!props.creationTrigger)
    }, [props.creationTrigger])

    // Once clicked on new item:
    // disable creation
    useEffect(() => {
        setIsCreation(false)
    }, [billerGroups.selectedItem?.id])

    return (
        <div
            className={`
                relative flex items-center h-full
                ${props.className}
            `}
        >
            <div className="relative flex-1 bg-brand-warmLight05 h-full overflow-y-auto">
                {isLoading ? (
                    <div className="absolute screen-center w-full text-center">
                        <SpinnerElement />
                    </div>
                ) : isCreation ? (
                    <BillerGroupCreationComponent
                        onCancel={() => setIsCreation(false)}
                    />
                ) : billerGroups.selectedItem ? (
                    <BillerGroupInformationComponent />
                ) : (
                    <div className="absolute screen-center w-full text-center md:hidden">
                        <PrimaryText centered className="opacity-75">
                            Manage the members of a biller group here
                        </PrimaryText>
                    </div>
                )}
            </div>

            {billerGroups.selectedItemUser ? (
                <BillerGroupUsersInformationComponent
                    className="flex-1 ml-24px md:ml-0"
                    user={billerGroups.selectedItemUser}
                />
            ) : null}
        </div>
    )
}
