import React from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import { ROUTES_CONFIG } from "./config/routes.config"
import { GENERAL_CONFIG } from "./config/general.config"

export const PublicRoute: React.FunctionComponent<IProtectedRouteProps> = ({
    isLoggedIn,
    children,
}) => {
    const [searchParams] = useSearchParams()

    return !isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace
            to={
                // Handle redirectTo from url query
                searchParams.get(
                    GENERAL_CONFIG.urlSearchParamsKeys.redirectTo
                ) || ROUTES_CONFIG.baseApplicationUrl
            }
        />
    )
}

export const PrivateRoute: React.FunctionComponent<IProtectedRouteProps> = ({
    isLoggedIn,
    children,
}) => {
    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace
            to={`${ROUTES_CONFIG.loginUrl}?${GENERAL_CONFIG.urlSearchParamsKeys.redirectTo}=${window.location.pathname}${window.location.search}`}
        />
    )
}
