import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"
import { GENERAL_CONFIG } from "../../config/general.config"

const initialState: IUserDetailsState = {
    profile: null,
    selectedPracticeRole: undefined,
}

const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        userDetailsReset: () => initialState,

        userDetailsSetProfile: (state, action: PayloadAction<IProfile>) => {
            state.profile = action.payload
        },
        userDetailsSetSelectedPracticeRole: (
            state,
            action: PayloadAction<IPracticeRole>
        ) => {
            // Save selected practice id in local storage
            // Local storage so it persists across tabs
            BrowserStorageHelper.set(
                GENERAL_CONFIG.browserStorageKeys.selectedPracticeId,
                action.payload.practice.id,
                BrowserStorageType.localStorage
            )

            // Reload if we are switching practice in the middle
            if (
                state.selectedPracticeRole &&
                state.selectedPracticeRole.practice.id !==
                    action.payload.practice.id
            ) {
                window.location.reload()
            } else {
                state.selectedPracticeRole = action.payload
            }
        },
    },
})

export const {
    userDetailsReset,
    userDetailsSetProfile,
    userDetailsSetSelectedPracticeRole,
} = userDetailsSlice.actions

export const userDetailsReducer = userDetailsSlice.reducer
