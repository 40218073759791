/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    IPopupProps,
    PopupWrapperElement,
    PrimaryText,
    TextAreaElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { supportReportCoverageResult } from "../../store/thunks/support.thunks"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"

export const ReportCoverageCheckResultPopupComponent: React.FunctionComponent<
    IPopupProps & {
        coverageResult: ICoverageResult
    }
> = props => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const canReportCoverageCheckResult = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.reportCoverageCheck,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isReportCoverageResultLoading()
    }, [runtimeState.isLoading])

    const onSubmitReport = ({ message }: any) => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            supportReportCoverageResult({
                payload: {
                    message,
                    coverageResult: props.coverageResult,
                },
                practice: selectedPracticeRole.practice,
                onSuccess: props.onDone,
            })
        )
    }

    return (
        <PopupWrapperElement
            isActive={canReportCoverageCheckResult && props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
        >
            <div className="flex">
                <PrimaryText typography="h3">
                    What are you flagging?
                </PrimaryText>

                <div className="cursor-pointer mt-16px" onClick={props.onDone}>
                    <img src={closeIcon} alt="Close" title="Close" />
                </div>
            </div>

            <PrimaryText typography="text" className="mt-24px">
                Please explain what looks wrong in result, and our team will
                review your request.
            </PrimaryText>

            <TextAreaElement
                className="mt-32px"
                name="message"
                placeholder="Please provide details"
                reactHookControl={control}
                reactHookErrors={errors}
                reactHookValidations={{
                    required: VALIDATION_CONFIG.required,
                    minLength: VALIDATION_CONFIG.minLength,
                    maxLength: VALIDATION_CONFIG.maxLengthText,
                }}
            />

            <div className="mt-32px flex">
                <div className="flex-1" />

                <ButtonElement
                    label="Submit"
                    type="primary"
                    size="large"
                    htmlType="button"
                    icon={arrowRightIcon}
                    isRightIcon
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmitReport)}
                />
            </div>
        </PopupWrapperElement>
    )
}
