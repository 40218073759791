import { FeatureFlag } from "../config/featureFlags.config"

export class FeatureFlagsHelper {
    /**
     * Check if feature enabled for current user
     * For now just simple logic, but extracted to separate function so logic can be easily made more complex
     */
    static isFeatureFlagEnabled(
        enabled: FeatureFlag[],
        featureFlagToCheck: FeatureFlag
    ): boolean {
        return enabled.includes(featureFlagToCheck)
    }
}
