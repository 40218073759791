/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import { CALCULATOR_CONFIG } from "../../../config/calculator.config"
import {
    CoveragePatientType,
    PayerCoverageCheckNetwork,
} from "../../../config/coverage.config"
import { CoverageResultBreakdownWrapperComponent } from "./coverageResultBreakdownWrapper.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { CoverageResultGeneralComponent } from "./coverageResultGeneral.component"
import { CalculatorService } from "../../../services/calculator.service"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { CoveragePortalFlagType } from "../../../config/policies.config"

export const CoverageResultComponent: React.FunctionComponent<
    ICoverageResultComponentProps
> = props => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const {
        resultDemographics,
        successResultPayer,
        successResult,
        customerData,
    } = useMemo<{
        resultDemographics?: ICoverageResultDemographics
        successResultPayer?: IPayer
        successResult?: ICoverageResult
        customerData: {
            customerPatientType?: CoveragePatientType | null
            customerPatientId?: string | null
            customerPatientNextAppointmentDate?: string | null
        }
    }>(() => {
        const inNetworkResult = props.results[PayerCoverageCheckNetwork.IN]
        const outNetworkResult = props.results[PayerCoverageCheckNetwork.OUT]

        const successResult =
            inNetworkResult?.resultType &&
            CALCULATOR_CONFIG.activeCoverageResults.includes(
                inNetworkResult?.resultType
            )
                ? inNetworkResult
                : outNetworkResult?.resultType &&
                  CALCULATOR_CONFIG.activeCoverageResults.includes(
                      outNetworkResult?.resultType
                  )
                ? outNetworkResult
                : undefined

        return {
            successResult,
            resultDemographics:
                successResult?.demographics ||
                inNetworkResult?.demographics ||
                outNetworkResult?.demographics,
            customerData: {
                customerPatientType:
                    successResult?.customerPatientType ||
                    inNetworkResult?.customerPatientType ||
                    outNetworkResult?.customerPatientType,
                customerPatientId:
                    successResult?.customerPatientId ||
                    inNetworkResult?.customerPatientId ||
                    outNetworkResult?.customerPatientId,
                customerPatientNextAppointmentDate:
                    successResult?.customerPatientNextAppointmentDate ||
                    inNetworkResult?.customerPatientNextAppointmentDate ||
                    outNetworkResult?.customerPatientNextAppointmentDate,
            },
            successResultPayer:
                successResult?.overriddenPayer ||
                successResult?.payer ||
                undefined,
        }
    }, [props.results])

    const mappedInputData = useMemo<{
        general: IGetCoverageEstimateData
        inNetwork: IGetCoverageEstimateData
        outNetwork: IGetCoverageEstimateData
    } | null>(() => {
        if (!selectedPracticeRole?.practice) {
            return null
        }

        return {
            general: CalculatorService.mapGetEstimateInputData(
                props.inputData,
                selectedPracticeRole.practice
            ),
            inNetwork: CalculatorService.mapGetEstimateInputData(
                props.inputData,
                selectedPracticeRole.practice,
                true
            ),
            outNetwork: CalculatorService.mapGetEstimateInputData(
                props.inputData,
                selectedPracticeRole.practice,
                false
            ),
        }
    }, [props.inputData, selectedPracticeRole?.practice])

    // Difference between demographics that was provided by user and that insurer has on file
    const demographicsDifference = useMemo<
        Partial<ICoverageResultDemographics>
    >(() => {
        return PoliciesHelper.getDemographicsDifference(
            props.inputData,
            resultDemographics
        )
    }, [props.inputData, resultDemographics])

    const isInputOverridden = useMemo<boolean>(() => {
        return (
            !!Object.keys(demographicsDifference).length ||
            (!!successResultPayer &&
                successResultPayer.payerId !== props.inputData.payer.payerId)
        )
    }, [resultDemographics, successResultPayer, props.inputData])

    const flags = useMemo<CoveragePortalFlagType[] | undefined>(() => {
        return props.results[PayerCoverageCheckNetwork.IN]?.flags?.length
            ? props.results[PayerCoverageCheckNetwork.IN]?.flags
            : props.results[PayerCoverageCheckNetwork.OUT]?.flags
    }, [props.results])

    return (
        <div className={`relative ${props.className}`}>
            <CoverageResultGeneralComponent
                className="
                    max-w-1200px mx-auto
                    md:max-w-none md:w-full
                "
                rounded
                isInputOverridden={isInputOverridden}
                flags={flags}
                customerPatientType={customerData.customerPatientType}
                customerPatientId={customerData.customerPatientId}
                customerPatientNextAppointmentDate={
                    customerData.customerPatientNextAppointmentDate
                }
                npi={mappedInputData?.general.providerNpi}
                cptCode={mappedInputData?.general.cptCode}
                sessionRate={mappedInputData?.general.sessionCharge}
                memberFirstName={mappedInputData?.general.firstName}
                memberFirstNameOverride={demographicsDifference.firstName}
                memberLastName={mappedInputData?.general.lastName}
                memberLastNameOverride={demographicsDifference.lastName}
                memberDob={mappedInputData?.general.dob}
                memberDobOverride={demographicsDifference.dob}
                memberGender={resultDemographics?.gender}
                memberAddress={resultDemographics?.address || undefined}
                relationshipToSubscriber={
                    successResult?.relationshipToSubscriber
                }
                subscriberDemographics={successResult?.subscriberDemographics}
            />

            {/*RESULTS BREAKDOWN*/}

            {props.results[PayerCoverageCheckNetwork.IN] && mappedInputData ? (
                <CoverageResultBreakdownWrapperComponent
                    className="
                        max-w-1200px mx-auto
                        md:max-w-none md:w-full
                    "
                    requestData={mappedInputData.inNetwork}
                    coverageResult={
                        props.results[
                            PayerCoverageCheckNetwork.IN
                        ] as ICoverageResult
                    }
                    requestPayer={props.inputData.payer}
                    responsePayer={successResultPayer || null}
                    requestedHealthProvider={null}
                    policy={null}
                />
            ) : null}

            {props.results[PayerCoverageCheckNetwork.OUT] && mappedInputData ? (
                <CoverageResultBreakdownWrapperComponent
                    className="
                        max-w-1200px mx-auto
                        md:max-w-none md:w-full
                    "
                    requestData={mappedInputData.outNetwork}
                    coverageResult={
                        props.results[
                            PayerCoverageCheckNetwork.OUT
                        ] as ICoverageResult
                    }
                    requestPayer={props.inputData.payer}
                    responsePayer={successResultPayer || null}
                    requestedHealthProvider={null}
                    policy={null}
                />
            ) : null}
        </div>
    )
}
