import React from "react"
import { toastr } from "react-redux-toastr"

import { GENERAL_CONFIG } from "../config/general.config"

export class ToastrHelper {
    /**
     * Show success message
     */
    static success(
        msg: string,
        timeOut = GENERAL_CONFIG.defaultToastrTimeout,
        withHtml = false
    ) {
        toastr.success("", withHtml ? "" : msg, {
            timeOut,
            component: withHtml
                ? () => {
                      return (
                          <div
                              className="custom-toastr"
                              dangerouslySetInnerHTML={{ __html: msg }}
                          />
                      )
                  }
                : undefined,
        })
    }

    /**
     * Show warning message
     */
    static warning(
        msg: string,
        timeOut = GENERAL_CONFIG.defaultToastrTimeout,
        withHtml = false
    ) {
        toastr.warning("", withHtml ? "" : msg, {
            timeOut,
            component: withHtml
                ? () => {
                      return (
                          <div
                              className="custom-toastr"
                              dangerouslySetInnerHTML={{ __html: msg }}
                          />
                      )
                  }
                : undefined,
        })
    }

    /**
     * Show error message
     */
    static error(
        msg: string,
        timeOut = GENERAL_CONFIG.defaultToastrTimeout,
        withHtml = false
    ) {
        toastr.error("", withHtml ? "" : msg, {
            timeOut,
            component: withHtml
                ? () => {
                      return (
                          <div
                              className="custom-toastr"
                              dangerouslySetInnerHTML={{ __html: msg }}
                          />
                      )
                  }
                : undefined,
        })
    }
}
