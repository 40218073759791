import { PayerCoverageCheckNetwork } from "../config/coverage.config"

export class CoverageHistoryHelper {
    /**
     * Get policy identification data
     */
    static getPolicyIdentificationData(
        coverageCheck: ICoverageCheckHistory | null
    ): IPolicyIdentificationData | null {
        const data: Partial<IPolicyIdentificationData> = {
            memberId: coverageCheck?.policy?.memberId,
            memberDob: coverageCheck?.policy?.memberDob,
            modality: coverageCheck?.policy?.modality,
        }

        // All the values from identification should be set in order for it to be valid
        // Valid data required for example for creating override
        const isValid =
            Object.values(data).length ===
            Object.values(data).filter(value => !!value).length

        return isValid ? (data as IPolicyIdentificationData) : null
    }

    /**
     * Get coverage check network
     */
    static getCoverageCheckNetwork(
        coverageCheck: ICoverageCheckHistory | null
    ): PayerCoverageCheckNetwork {
        return coverageCheck?.requestData.inNetwork ||
            coverageCheck?.coverageResult.isInNetworkCheck
            ? PayerCoverageCheckNetwork.IN
            : PayerCoverageCheckNetwork.OUT
    }

    /**
     * Get patient identification data
     */
    static getPatientIdentificationData(
        coverageCheck: ICoverageCheckHistory | null
    ): IPatientIdentificationData | null {
        const data: Partial<IPatientIdentificationData> = {
            practiceCustomId:
                coverageCheck?.coverageResult.customerPatientId ||
                coverageCheck?.requestData.customerPatientId,
            firstName:
                coverageCheck?.coverageResult.demographics?.firstName ||
                coverageCheck?.requestData.firstName ||
                undefined,
            lastName:
                coverageCheck?.coverageResult.demographics?.lastName ||
                coverageCheck?.requestData.lastName ||
                undefined,
            type: coverageCheck?.requestData.customerPatientType || undefined,
        }

        // Patient custom ID needs to be set for it to be valid
        const isValid = !!data.practiceCustomId

        return isValid ? (data as IPatientIdentificationData) : null
    }

    /**
     * Check if coverage check is related to comment
     */
    static isCommentRelatedCoverageCheck(
        coverageCheck: ICoverageCheckHistory | null,
        comment: IPolicyComment
    ): boolean {
        if (!coverageCheck) {
            return false
        }

        return !!(
            // id can be 0 if generated
            (
                (coverageCheck.policy?.id &&
                    comment.policy?.id &&
                    coverageCheck.policy.id === comment.policy.id) ||
                // id can be 0 if generated
                (coverageCheck.policy?.id &&
                    comment.policy?.id &&
                    coverageCheck.policy.modality === comment.policy.modality &&
                    coverageCheck.policy.memberId === comment.policy.memberId &&
                    coverageCheck.policy.memberDob ===
                        comment.policy.memberDob) ||
                (coverageCheck.policy?.patient &&
                    comment.patient &&
                    coverageCheck.policy.patient.id === comment.patient.id)
            )
        )
    }
}
