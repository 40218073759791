import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import {
    ButtonElement,
    PrimaryText,
    useScrollFix,
    PageHelmetElement,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { authVerifyEmail } from "../../store/thunks/auth.thunks"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { authSelector } from "../../store/selectors/auth.selector"
import { authReset } from "../../store/slices/auth.slice"
import { useAppDispatch } from "../../store/appDispatch.hook"

import mainImage from "../../assets/images/shapes/email-sent.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"
import lockIcon from "../../assets/images/icons/lock-dark.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"

export const VerifyEmailComponent: React.FunctionComponent = () => {
    useScrollFix()

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const authState = useAppSelector(authSelector)

    useEffect(() => {
        if (!authState.verificationEmail || !authState.verificationPassword) {
            ToastrHelper.error("Not enough data to proceed. Please try again")

            return navigate(ROUTES_CONFIG.loginUrl)
        }

        return () => {
            dispatch(authReset())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!authState.verificationEmail) {
            return
        }

        setValue("email", authState.verificationEmail)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.verificationEmail])

    const performEmailVerification = (data: any) => {
        if (!authState.verificationEmail || !authState.verificationPassword) {
            return
        }

        dispatch(
            authVerifyEmail({
                payload: {
                    verificationCode: data.verificationCode,
                    email: authState.verificationEmail,
                    password: authState.verificationPassword,
                },
                navigate,
            })
        )
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Verify Email"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <img
                src={mainImage}
                alt="Nirvana Health"
                title="Nirvana Health"
                className="margin-x-center sm:w-150px"
            />

            <PrimaryText className="mt-33px" typography="h2" centered>
                Verify your email
            </PrimaryText>

            <PrimaryText typography="subtitle" className="mt-32px" centered>
                Check your email and copy verification code
            </PrimaryText>

            <form
                onSubmit={handleSubmit(performEmailVerification)}
                noValidate={true}
            >
                <div className="mt-44px flex items-start md:block">
                    <InputElement
                        className="mr-24px flex-1"
                        name="email"
                        label="Email address"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        defaultValue={authState.verificationEmail}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                        disabled={true}
                    />

                    <InputElement
                        className="flex-1 md:mt-44px"
                        name="verificationCode"
                        label="Verification code"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        inputSuffix={
                            <img
                                src={lockIcon}
                                alt="Verification code"
                                title="Verification code"
                                className="ml-8px"
                            />
                        }
                    />
                </div>

                <div className="mt-32px flex items-center">
                    <div className="flex-1 mr-24px">
                        <Link to={ROUTES_CONFIG.loginUrl}>Cancel</Link>
                    </div>

                    <ButtonElement
                        label="Verify email"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
