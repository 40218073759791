import React, { useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    PrimaryText,
    useScrollFix,
    PageHelmetElement,
    InputElement,
    VALIDATION_CONFIG,
    PasswordStrengthElement,
    useIsMobile,
} from "nirvana-react-elements"

import { authForgotPasswordProcess } from "../../store/thunks/auth.thunks"
import { useAppSelector } from "../../store/selectors/app.selector"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { authSelector } from "../../store/selectors/auth.selector"
import { authReset } from "../../store/slices/auth.slice"
import { useAppDispatch } from "../../store/appDispatch.hook"

import mailIcon from "../../assets/images/icons/mail-dark.svg"
import verificationCodeIcon from "../../assets/images/icons/verification-code-dark.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"

export const RestoreProcessComponent: React.FunctionComponent = () => {
    useScrollFix()

    const isMobile = useIsMobile()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const authState = useAppSelector(authSelector)

    const {
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control,
    } = useForm()

    const password: any = useRef({})
    password.current = watch("password", "")

    useEffect(() => {
        if (!authState.forgotPasswordEmail) {
            ToastrHelper.error("Not enough data to proceed. Please try again")

            return navigate(ROUTES_CONFIG.loginUrl)
        }

        return () => {
            dispatch(authReset())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!authState.forgotPasswordEmail) {
            return
        }

        setValue("email", authState.forgotPasswordEmail)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.forgotPasswordEmail])

    const onFormSubmit = (data: any) => {
        if (!authState.forgotPasswordEmail) {
            return
        }

        dispatch(
            authForgotPasswordProcess({
                payload: data,
                navigate,
            })
        )
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Process Restore Password"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <PrimaryText className="md:mt-32px" typography="h1" centered>
                Change your password
            </PrimaryText>

            <form onSubmit={handleSubmit(onFormSubmit)} noValidate={true}>
                <div className="flex items-end sm:block">
                    <div className="flex-1 mr-27px sm:mr-0px">
                        <InputElement
                            className="mt-44px"
                            name="email"
                            label="Email"
                            disabled
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                pattern: VALIDATION_CONFIG.email,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            defaultValue={authState.forgotPasswordEmail}
                            inputSuffix={
                                <img
                                    src={mailIcon}
                                    alt="Email address"
                                    title="Email address"
                                    className="ml-8px"
                                />
                            }
                        />

                        <InputElement
                            className="mt-44px"
                            name="verificationCode"
                            label="Code"
                            placeholder="Enter your code"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                            }}
                            inputSuffix={
                                <img
                                    src={verificationCodeIcon}
                                    alt="Verification code"
                                    title="Verification code"
                                    className="ml-8px"
                                />
                            }
                        />

                        <PrimaryText
                            className="mt-4px"
                            size={10}
                            lineHeight={20}
                        >
                            Check your email for your verification code
                        </PrimaryText>

                        <InputElement
                            className="mt-44px"
                            name="password"
                            type="password"
                            label="Set password"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                validate:
                                    VALIDATION_CONFIG.passwordSpecialValidations,
                            }}
                        />
                    </div>

                    <PasswordStrengthElement
                        className="sm:mt-32px"
                        width={isMobile ? undefined : 260}
                        currentValue={password.current}
                        trianglePlacement={isMobile ? undefined : "bottomLeft"}
                    />
                </div>

                <div className="mt-32px flex items-center">
                    <div className="flex-1 mr-10px">
                        <Link to={ROUTES_CONFIG.loginUrl}>Cancel</Link>
                    </div>

                    <ButtonElement
                        label="Change password"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isLoading={authState.isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
