import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState: ICoveragePayersState = {
    items: [],
}

const coveragePayersSlice = createSlice({
    name: "coveragePayers",
    initialState,
    reducers: {
        coveragePayersReset: () => initialState,

        coveragePayersSetList: (
            state,
            action: PayloadAction<ICoveragePayer[]>
        ) => {
            state.items = action.payload
        },

        coveragePayersCreateDone: (
            state,
            action: PayloadAction<ICoveragePayer>
        ) => {
            state.items = [action.payload, ...state.items]
        },

        coveragePayersUpdateDone: (
            state,
            action: PayloadAction<ICoveragePayer>
        ) => {
            const newItems = [...state.items]

            const neededItemsIndex = newItems.findIndex(
                item => item.id === action.payload.id
            )

            if (~neededItemsIndex) {
                newItems[neededItemsIndex] = action.payload
            }

            state.items = newItems
        },

        coveragePayersDeleteDone: (state, action: PayloadAction<number>) => {
            state.items = state.items.filter(item => item.id !== action.payload)
        },
    },
})

export const {
    coveragePayersReset,
    coveragePayersSetList,
    coveragePayersCreateDone,
    coveragePayersUpdateDone,
    coveragePayersDeleteDone,
} = coveragePayersSlice.actions

export const coveragePayersReducer = coveragePayersSlice.reducer
