import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class CoveragePayersService {
    /**
     * Get list of coverage payers
     */
    static async getList(
        practice: IPractice
    ): Promise<ICoveragePayer[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PAYERS_GET_LIST.replace(
                ":practiceId",
                practice.id.toString()
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create new coverage payer
     */
    static async createInstance(
        data: IPayerUpsertData,
        practice: IPractice
    ): Promise<ICoveragePayer | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PAYERS_CREATE_INSTANCE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CoveragePayersService.mapPayerUpsertData(data),
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Update coverage payer
     */
    static async updateInstance(
        payerId: number,
        data: IPayerUpsertData,
        practice: IPractice
    ): Promise<ICoveragePayer | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PAYERS_UPDATE_INSTANCE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":payerId", payerId.toString()),
            CoveragePayersService.mapPayerUpsertData(data),
            "PUT"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete coverage payers
     */
    static async deleteInstance(
        payerId: number,
        practice: IPractice
    ): Promise<
        | {
              id: number
              result: boolean
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PAYERS_DELETE_INSTANCE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":payerId", payerId.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    ////////// PRIVATE

    /**
     * Map payer upsert data to format expected by backend
     */
    private static mapPayerUpsertData(data: IPayerUpsertData): IIndexable {
        const { payer, ...rest } = data

        return {
            ...rest,

            payerId: payer.id,

            // Convert amount to cents
            rates: data.rates.map(item => ({
                ...item,

                amount: UtilHelper.formatDollarsToCents(item.amount),
            })),
        }
    }
}
