import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"

// import { ROUTES_CONFIG } from "../../config/routes.config"

export const NirvanaBackgroundComponent: React.FunctionComponent = () => {
    const location = useLocation()

    const backgroundStyles: string = useMemo((): string => {
        switch (location.pathname) {
            // case ROUTES_CONFIG.coverageCheckerUrl:
            // case ROUTES_CONFIG.supportUrl:
            //     return "absolute h-250px md:h-0px"

            default:
                return "fixed h-screen"
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <div
            className={`
                left-0 top-0 -z-20
                w-screen bg-brand-offWhite
                ${backgroundStyles}
            `}
        />
    )
}
