/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import {
    PrimaryText,
    CheckboxElement,
    ConfirmationHelper,
    SpinnerElement,
} from "nirvana-react-elements"

import {
    CoveragePortalFlagStatus,
    POLICIES_CONFIG,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { policiesResolveFlag } from "../../../store/thunks/policies.thunks"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"

export const FlagsResolutionComponent: React.FunctionComponent<
    IFlagsResolutionComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const [isResolving, setIsResolving] = useState<boolean>(false)

    const canResolveFlags = useMemo<boolean>(() => {
        return PermissionsHelper.hasPermission(
            AvailableCoveragePortalPermission.resolveFlags,
            selectedPracticeRole
        )
    }, [selectedPracticeRole])

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isResolvingFlagLoading()
    }, [runtimeState.isLoading])

    const neededFlags = useMemo<IPolicyFlag[]>(() => {
        if (!props.coverageCheck.policy?.flags.length) {
            return []
        }

        const flagStatusOrder = Object.values(CoveragePortalFlagStatus)

        return [...props.coverageCheck.policy.flags].sort(
            (a, b) =>
                flagStatusOrder.indexOf(a.status) -
                flagStatusOrder.indexOf(b.status)
        )
    }, [props.coverageCheck.policy?.flags])

    const onResolveFlag = (flag: IPolicyFlag) => {
        setIsResolving(true)

        ConfirmationHelper.enableConfirmation(
            () => {
                setIsResolving(false)

                if (!selectedPracticeRole) {
                    return
                }

                dispatch(
                    policiesResolveFlag({
                        payload: {
                            flag,
                        },
                        practice: selectedPracticeRole.practice,
                    })
                )
            },
            "Do you want to complete this task?",
            undefined,
            () => setIsResolving(false)
        )
    }

    return canResolveFlags ? (
        <div
            className={`
                relative h-full overflow-y-auto
                bg-brand-warmLight05 border-r border-solid border-brand-warmLight
                ${props.className}
            `}
        >
            {neededFlags.length ? (
                <div>
                    <PrimaryText typography="h6" className="px-16px py-8px">
                        {neededFlags.length} Task
                        {neededFlags.length > 1 ? "s" : ""}
                    </PrimaryText>

                    {neededFlags.map((item, index) => {
                        const isFlagResolved =
                            item.status === CoveragePortalFlagStatus.DONE

                        const mapping =
                            POLICIES_CONFIG.flagTypeMappings[item.type]

                        return (
                            <div
                                key={index}
                                className={`
                                    p-16px mb-8px bg-brand-white
                                    border-b border-solid border-brand-warmLight
                                    ${isFlagResolved ? "opacity-50" : ""}
                                `}
                            >
                                <CheckboxElement
                                    name={`flag-${index}`}
                                    defaultChecked={
                                        !isFlagResolved && isResolving
                                            ? undefined
                                            : isFlagResolved
                                    }
                                    watchDefaultCheckedChange={true}
                                    disabled={isFlagResolved}
                                    onChange={
                                        isFlagResolved
                                            ? undefined
                                            : val => val && onResolveFlag(item)
                                    }
                                    label={
                                        <PrimaryText typography="subtitleSemibold">
                                            {mapping.category || mapping.label}
                                        </PrimaryText>
                                    }
                                />

                                <PrimaryText className="ml-24px mt-8px">
                                    {mapping.label}
                                </PrimaryText>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <PrimaryText
                    typography="h6"
                    className="mt-48px flex items-center justify-center"
                >
                    No tasks
                </PrimaryText>
            )}

            {isLoading && (
                <div
                    className="
                        absolute z-10 left-0 top-0 w-full h-full
                        flex items-center justify-center bg-brand-black-transparent-010
                    "
                >
                    <SpinnerElement />
                </div>
            )}
        </div>
    ) : null
}
