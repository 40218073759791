import React, { useState } from "react"
import { PrimaryText } from "nirvana-react-elements"

import chevronUp from "../assets/images/icons/chevron-up-dark.svg"
import chevronDown from "../assets/images/icons/chevron-down-dark.svg"

export const CollapseElement: React.FunctionComponent<
    ICollapseElementProps
> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(!props.isDefaultCollapsed)

    const toggle = () => {
        setIsOpen(current => !current)
    }

    return (
        <div
            className={`
                ${props.className}
            `}
        >
            <div className="flex items-start cursor-pointer" onClick={toggle}>
                <PrimaryText
                    typography={props.labelTypography || "h4"}
                    className="mr-16px"
                >
                    {props.label}
                </PrimaryText>

                <div
                    className={`
                        mr-16px relative
                        ${isOpen ? "top-8px" : "top-10px"}
                    `}
                >
                    <img
                        src={isOpen ? chevronUp : chevronDown}
                        width={18}
                        alt="toggle"
                    />
                </div>

                <div className="flex-1">{props.rightHeaderContent || null}</div>
            </div>

            {isOpen && (
                <div className={props.rightHeaderContent ? "mt-12px" : ""}>
                    {props.content}
                </div>
            )}
        </div>
    )
}
