/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import {
    PrimaryText,
    ConfirmationHelper,
    SelectElement,
    SpinnerElement,
    NIRVANA_THEME,
    useIsMobile,
} from "nirvana-react-elements"

import {
    AvailableApplicationRole,
    ROLES_PERMISSIONS_CONFIG,
} from "../../../config/rolesPermissions.config"
import {
    organizationUsersChangeRole,
    organizationUsersDelete,
} from "../../../store/thunks/organization.thunks"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { profileSelector } from "../../../store/selectors/profile.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { FeatureFlag } from "../../../config/featureFlags.config"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"

import removeIcon from "../../../assets/images/icons/trash-red.svg"

export const SingleOrganizationUserComponent: React.FunctionComponent<
    ISingleOrganizationUserComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const profile = useAppSelector(profileSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const isBillerGroupsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_BILLER_GROUPS
    )

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isSameUser = useMemo<boolean>(
        () => props.user.id === profile?.id,
        [props.user, profile]
    )

    const onRemoveUser = () => {
        if (!selectedPracticeRole) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                setIsLoading(true)

                dispatch(
                    organizationUsersDelete({
                        practice: selectedPracticeRole.practice,
                        payload: props.user,
                        onFinally: () => setIsLoading(false),
                    })
                )
            },
            undefined,
            `User "${
                props.user.name || props.user.email
            }" will be permanently removed`
        )
    }

    const onUserRoleChange = (role: AvailableApplicationRole) => {
        if (!selectedPracticeRole || role === props.user.role?.key) {
            return
        }

        setIsLoading(true)

        dispatch(
            organizationUsersChangeRole({
                payload: {
                    user: props.user,
                    role,
                },
                practice: selectedPracticeRole.practice,
                onFinally: () => setIsLoading(false),
            })
        )
    }

    return (
        <div
            className={`
                ${props.className}
                ${
                    !props.isLast
                        ? "border-b border-solid border-brand-warmLight"
                        : ""
                }
                relative h-56px px-16px flex items-center
                md:h-auto md:mt-36px md:block md:border md:border-brand-warmShadow
                md:rounded-8px md:bg-brand-whitePurple md:py-16px
            `}
        >
            {/*STATUS BADGE for mobiles*/}
            <div
                className={`
                    hidden md:block
                    absolute top--15px left-10px
                    px-8px py-2px rounded-4px
                    border border-solid w-max
                    bg-brand-offWhite border-brand-warmShadow
                `}
            >
                Active
            </div>

            <PrimaryText className="w-400px mr-16px md:mt-8px md:w-auto mouseflow-ignore">
                <div>
                    {/*LABEL FOR MOBILES / TABLETS*/}
                    <span className="opacity-50 hidden md:inline-block">
                        Name:&nbsp;
                    </span>
                    {props.user.name || "Unknown"} {isSameUser ? "(You)" : ""}
                </div>

                {props.user.billerGroups.length && isBillerGroupsEnabled ? (
                    <PrimaryText typography="caption">
                        Biller Groups:{" "}
                        {props.user.billerGroups
                            .map(item => item.name)
                            .join(", ")}
                    </PrimaryText>
                ) : null}
            </PrimaryText>

            <PrimaryText className="w-150px mr-16px md:mt-8px md:w-auto md:flex md:items-center">
                {/*LABEL FOR MOBILES / TABLETS*/}
                <span className="opacity-50 hidden md:inline-block md:mr-8px">
                    Role:&nbsp;
                </span>

                {!props.user.role?.label ? (
                    "Unknown"
                ) : isSameUser ||
                  [
                      AvailableApplicationRole.nirvanaAdmin,
                      AvailableApplicationRole.practiceOwner,
                  ].includes(props.user.role.key) ? (
                    props.user.role.label
                ) : (
                    <SelectElement
                        selectClassName="w-85px!"
                        name="role"
                        renderedOptions={
                            ROLES_PERMISSIONS_CONFIG.inviteRenderedRoles
                        }
                        onSelected={onUserRoleChange}
                        externalValue={props.user.role.label}
                        disabled={isLoading}
                        transparent
                        inlined={!isTablet}
                    />
                )}
            </PrimaryText>

            <PrimaryText className="flex-1 mr-16px md:mt-8px md:w-auto">
                {/*LABEL FOR MOBILES / TABLETS*/}
                <span className="opacity-50 hidden md:inline-block">
                    Checks Run this Month:&nbsp;
                </span>

                {new Intl.NumberFormat().format(
                    props.user.coverageChecksThisMonthCount
                )}
            </PrimaryText>

            {/*ACTION REMOVE*/}
            {!isSameUser && (
                <div className="md:absolute md:top-16px md:right-16px">
                    <img
                        className="cursor-pointer"
                        src={removeIcon}
                        alt="Remove"
                        onClick={() => !isLoading && onRemoveUser()}
                    />
                </div>
            )}

            {isLoading && (
                <div className="absolute z-10 right--15px">
                    <SpinnerElement size="small" />
                </div>
            )}
        </div>
    )
}
