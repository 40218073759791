/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react"
import { SpinnerElement } from "nirvana-react-elements"

import { ScreenScrollableTableElement } from "../../../elements/screenScrollableTable.element"
import { PoliciesListRowComponent } from "./policiesListRow.component"
import { PoliciesListTableHeaderComponent } from "./policiesListTableHeader.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { policiesSetHeaderDragDropDisabled } from "../../../store/slices/policies.slice"
import { OVERRIDES_CONFIG } from "../../../config/overrides.config"

export const PoliciesListTableComponent: React.FunctionComponent<
    IPoliciesListTableComponentProps
> = props => {
    const policiesState = useAppSelector(policiesSelector)

    // Ref so it can be used in event listeners
    const previousHeaderDragDropDisabled = useRef(
        policiesState.policiesHeaderDragDropDisabled
    )

    const dispatch = useAppDispatch()

    const firstTableRowRef = useRef<HTMLDivElement>(null)

    // Monitor first row to become hidden in viewport and disable drag and drop in header
    // Otherwise there's conflict between sticky and dnd
    useEffect(() => {
        if (!firstTableRowRef.current) {
            return
        }

        const onWindowScroll = () => {
            if (!firstTableRowRef.current) {
                return
            }

            const rect = firstTableRowRef.current.getBoundingClientRect()

            // rect.bottom needs to be more than 0
            const isFirstRowVisible = (rect?.bottom || 0) > 0
            const policiesHeaderDragDropDisabled = !isFirstRowVisible

            // Only update state when changes
            if (
                previousHeaderDragDropDisabled.current !==
                policiesHeaderDragDropDisabled
            ) {
                // Record for future checks
                previousHeaderDragDropDisabled.current =
                    policiesHeaderDragDropDisabled

                dispatch(
                    policiesSetHeaderDragDropDisabled(
                        policiesHeaderDragDropDisabled
                    )
                )
            }
        }

        window.addEventListener("scroll", onWindowScroll)

        return () => {
            window.removeEventListener("scroll", onWindowScroll)
        }
    }, [firstTableRowRef.current])

    const isPolicyBulkOverrideEligible = (
        policy: ICoverageCheckHistory
    ): boolean => {
        return (
            !!policy.coverageResult.responseStatus &&
            OVERRIDES_CONFIG.bulkOverrides.allowedResponseStatusCodes.includes(
                policy.coverageResult.responseStatus
            )
        )
    }

    const onSelectAllItems = () => {
        props.onSelectedItems?.(
            props.policies.filter(isPolicyBulkOverrideEligible)
        )
    }

    const onDeselectAllItems = () => {
        props.onDeselectedItems?.(props.policies)
    }

    const onSinglePolicySelected = (policy: ICoverageCheckHistory) => {
        props.onSelectedItems?.([policy])
    }

    const onSinglePolicyDeselected = (policy: ICoverageCheckHistory) => {
        props.onDeselectedItems?.([policy])
    }

    return (
        <div className={`relative ${props.className}`}>
            <ScreenScrollableTableElement
                tableRef={props.scrollableElementRef}
                stickyHeaderVerticalScrollElementSelector={
                    props.stickyHeaderVerticalScrollElementSelector
                }
                stickyHeaderHorizontalScrollElement={
                    props.stickyHeaderHorizontalScrollElement
                }
                stickyHeadersDisabled={
                    !props.forceHeaderStickiness &&
                    !policiesState.policiesHeaderDragDropDisabled
                }
                headers={
                    <PoliciesListTableHeaderComponent
                        policies={props.policies}
                        columns={props.columns}
                        policiesSelectable={props.policiesSelectable}
                        sortEnabled={props.sortEnabled}
                        sortConfiguration={props.sortConfiguration}
                        selectedItems={props.selectedItems}
                        onSetSortField={props.onSetSortField}
                        onSelectAllItems={onSelectAllItems}
                        onDeselectAllItems={onDeselectAllItems}
                        onChangeColumnOrder={props.onChangeColumnOrder}
                        ignoreHorizontalPadding={props.ignoreHorizontalPadding}
                    />
                }
            >
                {props.isListLoading ? (
                    <div
                        className={`
                            absolute z-10 left-0 top-0 w-full h-full flex items-center justify-center p-24px
                            ${
                                props.policies.length
                                    ? "top-0 bg-brand-warmLight05"
                                    : "top-72px"
                            }
                        `}
                    >
                        <SpinnerElement />
                    </div>
                ) : null}

                {props.policies.map((item, index) => {
                    const isSelected = !!props.selectedItems?.find(
                        innerItem =>
                            innerItem.coverageResult.resultId ===
                            item.coverageResult.resultId
                    )

                    const ignoreRowBorder =
                        props.ignoreLastItemBorder &&
                        index === props.policies.length - 1

                    return (
                        <PoliciesListRowComponent
                            key={index}
                            elementRef={!index ? firstTableRowRef : undefined}
                            viewType={props.viewType}
                            columns={props.columns}
                            policy={item}
                            highlightOnHover={props.policies.length > 1}
                            ignoreNavigateToPolicy={
                                props.ignoreNavigateToPolicy
                            }
                            ignoreRowBorder={ignoreRowBorder}
                            isSelectable={props.policiesSelectable}
                            isSelectionCheckboxDisabled={
                                !isPolicyBulkOverrideEligible(item)
                            }
                            isExternallySelected={isSelected}
                            onSelected={onSinglePolicySelected}
                            onDeselectedItem={onSinglePolicyDeselected}
                            ignoreHorizontalPadding={
                                props.ignoreHorizontalPadding
                            }
                        />
                    )
                })}
            </ScreenScrollableTableElement>
        </div>
    )
}
