/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import {
    useStateCallback,
    useIsMobile,
    SelectElement,
    VALIDATION_CONFIG,
    InputElement,
    useCptCodes,
    AvailableLookupConsumer,
} from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"

import removeIcon from "../../../assets/images/icons/trash-red.svg"
import plusIcon from "../../../assets/images/icons/plus-dark.svg"

const RATES_INPUT_ROOT_KEY = "rates"

export const CoveragePayersRatesManagementComponent: React.FunctionComponent<
    IPayerRatesManagementComponentProps
> = props => {
    const isMobile = useIsMobile()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { availableCptCodes, availableCptCodesStyled } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    // Set initial amount of rows: default to 1 row (so [0])
    const [inputRatesIndexes, setInputRatesIndexes] = useStateCallback<
        number[]
    >([0])

    const fieldGroupName = useMemo<string>(
        () =>
            props.fieldGroupName
                ? `${props.fieldGroupName}.${RATES_INPUT_ROOT_KEY}`
                : `${RATES_INPUT_ROOT_KEY}`,
        [props.fieldGroupName]
    )

    const dataWatcher = props.fieldGroupName
        ? props.reactHookFormWatch(props.fieldGroupName)
        : props.reactHookFormWatch()

    useEffect(() => {
        if (!props.predefinedRates) {
            return
        }

        setPredefinedRates(props.predefinedRates)
    }, [props.predefinedRates, fieldGroupName])

    const setPredefinedRates = (rates: ICptCodeData[]) => {
        const newRateIndexes = Array.from(Array(rates.length).keys())

        setInputRatesIndexes(newRateIndexes, () => {
            // set value of needed cptCode to dropdown
            newRateIndexes.forEach(newIndex => {
                setTimeout(() => {
                    const newValue = rates[newIndex]?.code

                    if (!newValue) {
                        return
                    }

                    props.reactHookFormSet(
                        `${fieldGroupName}[${newIndex}].code`,
                        newValue,
                        {
                            shouldValidate: true,
                        }
                    )
                })
            })
        })
    }

    const onAddRateRow = (cptCodeValue: string) => {
        if (inputRatesIndexes.length >= availableCptCodes.length) {
            return
        }

        const newRateIndex = Math.max(...inputRatesIndexes, 0) + 1

        setInputRatesIndexes(
            prevIndexes => [...prevIndexes, newRateIndex],
            () => {
                // set value of needed cptCode to dropdown
                setTimeout(() => {
                    props.reactHookFormSet(
                        `${fieldGroupName}[${newRateIndex}].code`,
                        cptCodeValue,
                        {
                            shouldValidate: true,
                        }
                    )
                })
            }
        )
    }

    const onRemoveRateRow = (index: number) => {
        if (inputRatesIndexes.length < 2) {
            return
        }

        setInputRatesIndexes(prevIndexes => [
            ...prevIndexes.filter(item => item !== index),
        ])
    }

    return availableCptCodes.length ? (
        <div className={`relative ${props.className}`}>
            {inputRatesIndexes.map(rateIndex => {
                const rateFieldGroupName = `${fieldGroupName}[${rateIndex}]`

                const ratesFieldGroupErrors =
                    props.reactHookErrors?.[RATES_INPUT_ROOT_KEY]?.[rateIndex]

                const isFirstRate = !inputRatesIndexes.indexOf(rateIndex)
                const isLastRate =
                    inputRatesIndexes.indexOf(rateIndex) ===
                    inputRatesIndexes.length - 1

                // Disable options for current rates group that were already selected

                const currentSelectedCptCodes = (
                    dataWatcher?.[RATES_INPUT_ROOT_KEY] || []
                ).map(item => item.code)

                const rowProcessedCptCodeOptions = availableCptCodesStyled.map(
                    item => ({
                        ...item,

                        disabled: currentSelectedCptCodes.includes(item.value),
                    })
                )

                return (
                    <div key={rateIndex}>
                        <div
                            className={`
                                relative flex items-start mt-16px
                                sm:block
                                ${inputRatesIndexes.length > 1 ? "mr-32px" : ""}
                            `}
                        >
                            <SelectElement
                                className="
                                    mr-16px w-275px
                                    sm:mr-0px sm:mt-52px sm:w-full
                                "
                                label={
                                    isFirstRate || isMobile
                                        ? "CPT Code"
                                        : undefined
                                }
                                placeholder="Select one"
                                name="code"
                                fieldGroupName={rateFieldGroupName}
                                reactHookFormRegister={
                                    props.reactHookFormRegister
                                }
                                reactHookFormUnregister={
                                    props.reactHookFormUnregister
                                }
                                reactHookFormSet={props.reactHookFormSet}
                                reactHookFormErrors={ratesFieldGroupErrors}
                                reactHookFormControl={props.reactHookControl}
                                defaultValue={
                                    props.predefinedRates?.[rateIndex]?.code
                                }
                                validations={{
                                    required: VALIDATION_CONFIG.required,
                                }}
                                options={rowProcessedCptCodeOptions}
                            />

                            <InputElement
                                className="flex-1 sm:mt-32px"
                                type="number"
                                label={
                                    isFirstRate || isMobile
                                        ? "Session Fee"
                                        : undefined
                                }
                                isLabelStatic
                                name="amount"
                                fieldGroupName={rateFieldGroupName}
                                reactHookControl={props.reactHookControl}
                                shouldUnregister
                                reactHookValidations={{
                                    required: VALIDATION_CONFIG.required,
                                    min: VALIDATION_CONFIG.minAmount,
                                }}
                                reactHookErrors={ratesFieldGroupErrors}
                                inputPrefix="$"
                                defaultValue={
                                    // convert to dollars
                                    props.predefinedRates?.[rateIndex]?.amount
                                        ? Math.floor(
                                              props.predefinedRates[rateIndex]
                                                  .amount / 100
                                          ).toString()
                                        : undefined
                                }
                            />

                            {/*Remove rate icon*/}
                            {inputRatesIndexes.length > 1 && (
                                <div
                                    className={`
                                        absolute right--32px top-14px
                                    `}
                                >
                                    <img
                                        src={removeIcon}
                                        className="cursor-pointer"
                                        alt="Remove"
                                        onClick={() =>
                                            onRemoveRateRow(rateIndex)
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {/*add rate btn*/}
                        {isLastRate &&
                        inputRatesIndexes.length < availableCptCodes.length ? (
                            <SelectElement
                                className="w-220px mt-16px"
                                name={`addCptCode-${rateIndex}`}
                                externalValue="Add another CPT code"
                                selectResultPrefixIcon={plusIcon}
                                options={rowProcessedCptCodeOptions}
                                onSelected={(cptCodeValue: string) =>
                                    onAddRateRow(cptCodeValue)
                                }
                                short
                            />
                        ) : null}
                    </div>
                )
            })}
        </div>
    ) : null
}
