import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class BillerGroupsService {
    /**
     * Get list of biller groups
     */
    static async getList(
        practice: IPractice,
        pagination: IPagination
    ): Promise<
        | {
              items: IBillerGroup[]
              pagination: IPagination
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_GET_LIST.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                start: pagination.start,
                count: pagination.count,
            }
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get single biller group
     */
    static async getSingle(
        practice: IPractice,
        billerGroupId: number
    ): Promise<IBillerGroup | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_GET_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":billerGroupId", billerGroupId.toString())
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create biller group
     */
    static async create(
        practice: IPractice,
        data: IBillerGroupUpsertRequestData
    ): Promise<IBillerGroup | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_CREATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            data,
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Update biller group
     */
    static async update(
        practice: IPractice,
        billerGroupId: number,
        data: IBillerGroupUpsertRequestData
    ): Promise<IBillerGroup | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_UPDATE_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":billerGroupId", billerGroupId.toString()),
            data,
            "PUT"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete biller group
     */
    static async delete(
        practice: IPractice,
        billerGroupId: number
    ): Promise<{ deletedId: number } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_DELETE_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":billerGroupId", billerGroupId.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Update users in biller group
     */
    static async updateUsers(
        practice: IPractice,
        billerGroupId: number,
        data: IBillerGroupUpdateUsersRequestData
    ): Promise<IBillerGroup | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.BILLER_GROUPS_UPDATE_USERS.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":billerGroupId", billerGroupId.toString()),
            data,
            "PUT"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
