/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PrimaryText, ProgressElement } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"

import { CoveragePortalFlagType } from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"

export const ReportsFlagsAggregationCardComponent: React.FunctionComponent<
    IReportsFlagsAggregationCardComponentProps
> = props => {
    const navigate = useNavigate()

    const { reportsFilters } = useAppSelector(policiesSelector)

    const onFilterFlags = (
        flags: CoveragePortalFlagType | CoveragePortalFlagType[]
    ) => {
        navigate(
            `${ROUTES_CONFIG.reportsUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.filters
            }=${JSON.stringify({
                ...reportsFilters,
                flags: Array.isArray(flags) ? flags : [flags],
            })}`
        )

        props.onSliceFiltered?.()
    }

    return (
        <div
            className={`
                relative bg-brand-white rounded-8px p-16px
                border border-solid border-brand-warmLight
                ${props.className}
            `}
        >
            <div
                className={`
                    flex items-start
                    ${
                        props.calculation.isInlinedSubLabel
                            ? "items-center"
                            : "items-start"
                    }
                `}
            >
                <div
                    className={`
                        w-15px h-15px rounded-4px mr-8px relative
                        ${
                            props.calculation.isInlinedSubLabel
                                ? "top--2px"
                                : "top-3px"
                        }
                    `}
                    style={{
                        backgroundColor: props.calculation.color,
                    }}
                />

                <div
                    className={`
                        flex-1
                        ${props.calculation.filterFlags ? "cursor-pointer" : ""}
                    `}
                    onClick={
                        props.calculation.filterFlags
                            ? onFilterFlags.bind(
                                  {},
                                  props.calculation.filterFlags
                              )
                            : undefined
                    }
                >
                    <PrimaryText typography="subtitle">
                        <span className="text-semibold">
                            {props.calculation.label}: {props.calculation.value}
                        </span>{" "}
                        {!props.calculation.isInlinedSubLabel ? (
                            <>
                                <br />
                                {props.calculation.percentage}% of Total Checks
                            </>
                        ) : (
                            <>
                                ({props.calculation.percentage}% of Total
                                Checks)
                            </>
                        )}
                    </PrimaryText>
                </div>
            </div>

            <ProgressElement
                containerClassName="mt-24px"
                percent={props.calculation.percentage}
                showInfo={false}
                small={true}
                bordered={true}
                trailColor="white"
                strokeColor={props.calculation.color}
            />

            {(props.calculation.children || []).map((child, index) => {
                return (
                    <div
                        key={index}
                        className="mt-19px flex items-center cursor-pointer"
                        onClick={() => onFilterFlags(child.filterFlag)}
                    >
                        <PrimaryText>{child.label}</PrimaryText>

                        <div className="flex-1" />

                        <PrimaryText>
                            <span className="text-semibold">{child.value}</span>{" "}
                            ({child.percentage}%)
                        </PrimaryText>
                    </div>
                )
            })}
        </div>
    )
}
