/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { PrimaryText } from "nirvana-react-elements"
import { useInView } from "react-intersection-observer"

import { billerGroupsGetList } from "../../../store/thunks/billerGroups.thunks"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { billerGroupsSelector } from "../../../store/selectors/billerGroups.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { VerticalTabsElement } from "../../../elements/verticalTabs.element"

import plusIcon from "../../../assets/images/icons/plus-square-dark.svg"
import chevronRightIcon from "../../../assets/images/icons/chevron-right-dark.svg"

export const BillerGroupsSidebarComponent: React.FunctionComponent<
    IBillerGroupsSidebarComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
    })

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const billerGroups = useAppSelector(billerGroupsSelector)

    // Get initial list of biller groups
    useEffect(() => {
        getBillerGroupsList()
    }, [selectedPracticeRole?.id])

    // Fetch next art of the list once scrolled to bottom
    useEffect(() => {
        if (!inView || !billerGroups.itemsPagination.moreAvailable) {
            return
        }

        getBillerGroupsList(billerGroups.itemsPagination)
    }, [inView])

    const getBillerGroupsList = (pagination?: IPagination) => {
        if (!selectedPracticeRole || pagination?.moreAvailable === false) {
            return
        }

        dispatch(
            billerGroupsGetList({
                payload: pagination,
                practice: selectedPracticeRole.practice,
            })
        )
    }

    return (
        <div
            className={`
                relative h-full overflow-y-auto
                flex flex-col
                ${props.className}
            `}
        >
            {props.onSidebarTabSelected &&
            props.sidebarAvailableTabs?.length ? (
                <VerticalTabsElement
                    className="mb-24px"
                    typography="captionSemibold"
                    onTabSelected={props.onSidebarTabSelected}
                    availableTabs={props.sidebarAvailableTabs}
                    selectedTab={props.sidebarSelectedTab}
                    hideSelectedTab
                />
            ) : null}

            <div className="bg-brand-warmLight05 flex-1">
                {/*HEADER*/}
                <div className="flex items-center px-16px py-8px">
                    <PrimaryText className="mr-8px flex-1" typography="h6">
                        {billerGroups.itemsPagination.total} Biller Group
                        {billerGroups.itemsPagination.total !== 1 ? "s" : ""}
                    </PrimaryText>

                    <div className="cursor-pointer" onClick={props.onCreate}>
                        <img src={plusIcon} alt="Add" />
                    </div>
                </div>

                {!billerGroups.items.length ? (
                    <PrimaryText
                        centered
                        className="absolute screen-center w-full opacity-75 md:hidden"
                    >
                        Manage biller groups here
                    </PrimaryText>
                ) : (
                    <>
                        {/*LIST*/}
                        {billerGroups.items.map((item, index) => {
                            const isSelected =
                                item.id === props.selectedBillerGroup?.id
                            const isLast =
                                index === billerGroups.items.length - 1

                            return (
                                <div
                                    key={index}
                                    ref={isLast ? inViewRef : undefined}
                                    className={`
                                        flex items-center p-16px
                                        border-t border-solid border-brand-warmLight
                                        ${
                                            isSelected
                                                ? "bg-brand-lilacLight"
                                                : "cursor-pointer bg-brand-white hover:bg-brand-whitePurple"
                                        }
                                    `}
                                    onClick={() =>
                                        !isSelected &&
                                        props.onBillerGroupSelected(item)
                                    }
                                >
                                    <div className="flex-1 mr-8px">
                                        <PrimaryText typography="textSemibold">
                                            {item.name}
                                        </PrimaryText>

                                        <PrimaryText typography="caption">
                                            {item.users.length} Member
                                            {item.users.length === 1 ? "" : "s"}
                                        </PrimaryText>
                                    </div>

                                    {!isSelected && (
                                        <img
                                            src={chevronRightIcon}
                                            alt="Select"
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}
