/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { ConfirmationHelper } from "nirvana-react-elements"

import {
    billerGroupsSelectSingle,
    billerGroupsSelectUser,
} from "../../../store/slices/billerGroups.slice"
import {
    billerGroupsDelete,
    billerGroupsUpdateUsers,
} from "../../../store/thunks/billerGroups.thunks"
import { BillerGroupNameComponent } from "./billerGroupName.component"
import { BillerGroupUsersListComponent } from "./billerGroupUsersList.component"
import { BillerGroupUsersAddComponent } from "./billerGroupUsersAdd.component"
import { UpdateUsersInGroupOperation } from "../../../config/organization.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { billerGroupsSelector } from "../../../store/selectors/billerGroups.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import arrowLeftIcon from "../../../assets/images/icons/arrow-left-dark.svg"
import trashIcon from "../../../assets/images/icons/trash-dark.svg"

export const BillerGroupInformationComponent: React.FunctionComponent<
    IBillerGroupInformationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const billerGroups = useAppSelector(billerGroupsSelector)

    const [editCancelTrigger, setEditCancelTrigger] = useState<number>()
    const [isNameEdit, setIsNameEdit] = useState<boolean>(false)

    const onGoBack = () => {
        // If editing name now -> cancel edit instead of deselecting item
        if (isNameEdit) {
            setEditCancelTrigger(new Date().getTime())
            return
        }

        dispatch(billerGroupsSelectSingle({}))
    }

    const onDeleteBillerGroup = () => {
        if (!selectedPracticeRole || !billerGroups.selectedItem) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                if (!billerGroups.selectedItem?.id) {
                    return
                }

                dispatch(
                    billerGroupsDelete({
                        payload: billerGroups.selectedItem.id,
                        practice: selectedPracticeRole.practice,
                    })
                )
            },
            undefined,
            `Biller group "${billerGroups.selectedItem.name}" will be permanently removed`
        )
    }

    const onUserSelected = (user: IOrganizationUser) => {
        dispatch(billerGroupsSelectUser(user))
    }

    const onUsersAdded = (users: IOrganizationUser[]) => {
        if (
            !selectedPracticeRole ||
            !billerGroups.selectedItem ||
            !users.length
        ) {
            return
        }

        dispatch(
            billerGroupsUpdateUsers({
                practice: selectedPracticeRole.practice,
                payload: {
                    billerGroupId: billerGroups.selectedItem.id,
                    data: {
                        userIds: users.map(item => item.id),
                        operation: UpdateUsersInGroupOperation.ADD,
                    },
                },
            })
        )
    }

    return (
        <div
            className={`
                relative
                md:fixed md:top-0 md:left-0 md:w-screen md:h-screen md:bg-brand-white md:z-10
                ${props.className}
            `}
        >
            <div className="p-16px flex items-start border-b-1px border-solid border-brand-warmLight">
                <div
                    className="cursor-pointer mr-16px mt-11px"
                    onClick={onGoBack}
                >
                    <img src={arrowLeftIcon} alt="Back" />
                </div>

                <BillerGroupNameComponent
                    className="flex-1"
                    existingBillerGroup={billerGroups.selectedItem}
                    editCancelTrigger={editCancelTrigger}
                    onEditStateChange={setIsNameEdit}
                />

                {!isNameEdit ? (
                    <div
                        className="cursor-pointer p-16px absolute top-0 right-0"
                        onClick={onDeleteBillerGroup}
                    >
                        <img src={trashIcon} alt="Back" />
                    </div>
                ) : null}
            </div>

            <BillerGroupUsersAddComponent
                className="p-16px border-b-1px border-solid border-brand-warmLight"
                existingUsers={billerGroups.selectedItem?.users || []}
                onUsersAdded={onUsersAdded}
            />

            <BillerGroupUsersListComponent
                users={billerGroups.selectedItem?.users || []}
                selectedUser={billerGroups.selectedItemUser}
                onUserSelected={onUserSelected}
            />
        </div>
    )
}
