import { configureStore } from "@reduxjs/toolkit"
import { reducer as toastrReducer } from "react-redux-toastr"

import { authReducer } from "./slices/auth.slice"
import { runtimeReducer } from "./slices/runtime.slice"
import { userDetailsReducer } from "./slices/userDetails.slice"
import { supportReducer } from "./slices/support.slice"
import { coveragePayersReducer } from "./slices/coveragePayers.slice"
import { organizationReducer } from "./slices/organization.slice"
import { billerGroupsReducer } from "./slices/billerGroups.slice"
import { calculatorReducer } from "./slices/calculator.slice"
import { policiesReducer } from "./slices/policies.slice"
import { coverageOverridesReducer } from "./slices/coverageOverrides.slice"

export const store = configureStore({
    reducer: {
        toastr: toastrReducer,

        auth: authReducer,
        runtime: runtimeReducer,
        userDetails: userDetailsReducer,
        calculator: calculatorReducer,
        organization: organizationReducer,
        coveragePayers: coveragePayersReducer,
        billerGroups: billerGroupsReducer,
        policies: policiesReducer,
        coverageOverrides: coverageOverridesReducer,
        support: supportReducer,
    },
    // Ignore serialization warnings
    // Since for example toastr puts them in store
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
