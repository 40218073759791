/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react"
import Sticky from "react-sticky-el"

import { useScrollableElementBoundaries } from "../hooks/scrollableElementBoundaries.hook"
import { useScrollableElementScrollPosition } from "../hooks/scrollableElementScrollPosition.hook"

export const ScreenScrollableTableElement: React.FunctionComponent<
    IScreenScrollableTableElementProps
> = props => {
    const tableRef = useRef<HTMLDivElement>(null)

    const tableBoundaries = useScrollableElementBoundaries(
        tableRef,
        props.stickyHeaderHorizontalScrollElement
    )

    // If passed from outside will be monitoring horizontal scroll of parent element
    const parentScrollPositions = useScrollableElementScrollPosition(
        props.stickyHeaderHorizontalScrollElement
    )

    return (
        <div
            ref={props.tableRef}
            className={`
                relative
                ${props.className}
            `}
        >
            <div ref={tableRef} className="overflow-x-auto table w-full">
                <Sticky
                    scrollElement={
                        props.stickyHeaderVerticalScrollElementSelector
                    }
                    stickyStyle={{
                        left:
                            tableBoundaries?.x ||
                            (parentScrollPositions?.left
                                ? parentScrollPositions.left +
                                  (tableBoundaries?.x || 0)
                                : undefined),
                    }}
                    wrapperClassName="flex z-20 border-b border-solid border-brand-warmLight"
                    stickyClassName="flex"
                    disabled={props.stickyHeadersDisabled}
                >
                    {/*HEADERS*/}
                    {props.headers}
                </Sticky>

                {props.children}
            </div>
        </div>
    )
}
