import React from "react"

import { HtmlHelper } from "../../helpers/html.helper"
import { AvailablePlanStatus } from "../../config/coverage.config"
import { TOOLTIPS_CONFIG } from "../../config/tooltips.config"

export const ActiveAtRiskPlanStatusBadgeElement: React.FunctionComponent<{
    coverageResult: ICoverageResult
}> = props => {
    if (props.coverageResult.planStatus !== AvailablePlanStatus.activeAtRisk) {
        return null
    }

    return HtmlHelper.getHtmlWrappedBadge(
        "Active - At Risk",
        undefined,
        TOOLTIPS_CONFIG.coverageBreakdown.activeAtRiskPlanStatus
    )
}
