import { ManagerOptions } from "socket.io-client"

export enum SocketEvent {
    coveragePortalJoinRoom = "coveragePortalJoinRoom",
    coveragePortalLeaveRoom = "coveragePortalLeaveRoom",

    // Coverage Portal single toast message, will be displayed as toast in coverage portal
    coveragePortalSingleToastMsg = "coveragePortalSingleToastMsg",

    // COMMENTS
    coveragePortalPolicyCommentCreated = "coveragePortalPolicyCommentCreated",
}

export const SOCKETS_CONFIG = {
    defaultSocketIOConfig: {
        autoConnect: false,
        // reconnection: false,
        reconnectionDelay: 20 * 1000, // in ms
        reconnectionAttempts: 5,
        transports: ["websocket"],
    } as ManagerOptions,
}
