/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import moment from "moment-timezone"
import * as PapaParse from "papaparse"
import { CSVLink } from "react-csv"
import { PageFloatingButton } from "nirvana-react-elements"

import { PayerCoverageCheckNetwork } from "../../config/coverage.config"
import { CALCULATOR_CONFIG } from "../../config/calculator.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { UtilHelper } from "../../helpers/util.helper"
import { CsvHelper } from "../../helpers/csv.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { calculatorSelector } from "../../store/selectors/calculator.selector"

import downloadWhiteImg from "../../assets/images/icons/download-white.svg"

export const ResultsDownloadComponent: React.FunctionComponent<
    IResultsDownloadComponentProps
> = props => {
    const calculatorState = useAppSelector(calculatorSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const resultsCount = useMemo<number>(() => {
        return (
            calculatorState.coverageChecks?.reduce(
                (accumulator, item) =>
                    accumulator +
                    (item.result?.IN ? 1 : 0) +
                    (item.result?.OUT ? 1 : 0),
                0
            ) || 0
        )
    }, [calculatorState.coverageChecks])

    const getProcessedCsvResults = (): string => {
        if (!calculatorState.coverageChecks?.length || !resultsCount) {
            return ""
        }

        const csvData = [] as (string | number | boolean)[][]

        let headersAdded = false

        // Since our indexes can be messed up in state array (because removing adding rows)
        // We'd like to get indexes in similar manner how we are doing in calculator component
        const checksIndexes = UtilHelper.getRealIndexesOfArray(
            calculatorState.coverageChecks
        )

        // Go through each index of our checks, so basically go through each check input and result data
        checksIndexes.forEach(index => {
            if (!calculatorState.coverageChecks?.[index].inputData) {
                return
            }

            ;[
                PayerCoverageCheckNetwork.IN,
                PayerCoverageCheckNetwork.OUT,
            ].forEach(networkKey => {
                const inputData =
                    calculatorState.coverageChecks[index].inputData

                const resultData: ICoverageResult | undefined =
                    calculatorState.coverageChecks[index].result?.[networkKey]

                if (!resultData) {
                    return
                }

                const columns = CsvHelper.getCoverageCheckerDownloadCSVColumns(
                    selectedPracticeRole?.availableModalities
                )

                // We need to process headers here, so we add passThrough headers as well
                if (!headersAdded) {
                    // Add header rows
                    // Include also pass through if they are there
                    csvData.push([
                        ...columns.map(mapping => mapping.header),

                        ...Object.keys(inputData.passThroughColumns || {}),
                    ])

                    headersAdded = true
                }

                let csvRow = [] as (string | number | boolean)[]

                // Go through each column for our final csv and process check using mappings
                columns.forEach(mapping => {
                    const columnValue = CsvHelper.getSingleExportColumn(
                        mapping,
                        inputData,
                        resultData,
                        selectedPracticeRole
                    )

                    // Add value to row
                    csvRow.push(columnValue)
                })

                // Add passThrough columns to the end of csv rows
                if (inputData.passThroughColumns) {
                    Object.keys(inputData.passThroughColumns).forEach(key => {
                        if (!inputData.passThroughColumns) {
                            return
                        }

                        csvRow.push(inputData.passThroughColumns[key])
                    })
                }

                // Process each value and make sure there are no commas...
                csvRow = csvRow.map(column =>
                    typeof column === "string"
                        ? column.replace(/,/g, "")
                        : column
                )

                // Add row to csv
                csvData.push(csvRow)
            })
        })

        return PapaParse.unparse(csvData)
    }

    const processedCsvData = useMemo<string>(() => {
        return getProcessedCsvResults()
    }, [calculatorState.coverageChecks])

    const csvFileName = useMemo<string>(() => {
        return CALCULATOR_CONFIG.downloadFileName.replace(
            "{{time}}",
            moment().format(GENERAL_CONFIG.defaultMomentDateTimeFormat)
        )
    }, [processedCsvData])

    return resultsCount ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <CSVLink
                data={processedCsvData}
                filename={csvFileName}
                target="_blank"
            >
                <PageFloatingButton
                    label={`Download CSV with ${resultsCount} result${
                        resultsCount === 1 ? "" : "s"
                    }`}
                    zIndex={30}
                    icon={downloadWhiteImg}
                />
            </CSVLink>
        </div>
    ) : null
}
