import React from "react"
import {
    useScrollFix,
    PrimaryText,
    PageHelmetElement,
} from "nirvana-react-elements"

import { SupportContentComponent } from "./supportContent.component"
import { GENERAL_CONFIG } from "../../config/general.config"

export const SupportComponent: React.FunctionComponent = () => {
    useScrollFix()

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Support"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <PrimaryText typography="h1">Support</PrimaryText>

            <PrimaryText className="mt-8px" typography="text">
                Looking to get the most out of Nirvana Health? We’re here to
                help.
            </PrimaryText>

            <SupportContentComponent className="mt-32px" />
        </div>
    )
}
