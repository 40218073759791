/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { AddCommentComponent } from "./addComment.component"
import { UserCommentCardComponent } from "./userCommentCard.component"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { CoveragePortalCommentType } from "../../../config/policies.config"
import { SystemCommentCardComponent } from "./systemCommentCard.component"

import expandIcon from "../../../assets/images/icons/comments-expand.svg"
import collapseIcon from "../../../assets/images/icons/comments-collapse.svg"

export const PolicyCommentsComponent: React.FunctionComponent<
    IPolicyCommentsComponentProps
> = props => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [replyToComment, setReplyToComment] = useState<IPolicyComment>()
    const [editComment, setEditComment] = useState<IPolicyComment>()

    const runtimeState = useAppSelector(runtimeSelector)
    const { selectedItemComments } = useAppSelector(policiesSelector)

    const isListLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isGetPolicyCommentsLoading()
    }, [runtimeState.isLoading])

    const onClearReplyToComment = () => {
        setReplyToComment(undefined)
    }

    const onClearEditComment = () => {
        setEditComment(undefined)
    }

    const toggleCollapse = () => {
        setIsExpanded(current => !current)
    }

    return (
        <div
            className={`
                relative
                w-300px h-full overflow-visible
                md:w-full
                ${props.className}
            `}
        >
            <div
                className={`
                    absolute z-10 right-0 top-0 h-full flex flex-col
                    bg-brand-warmLight2 overflow-x-hidden overflow-y-auto
                    border-l border-solid border-brand-warmLight
                    transition-all duration-500
                    md:h-600px
                    ${isExpanded ? "w-700px md:w-full" : "w-full"}
                `}
            >
                <div
                    className="
                        sticky left-0 top-0 w-full
                        pr-16px py-8px flex items-center bg-brand-offWhite
                        border-b border-solid border-brand-warmLight
                    "
                >
                    <div
                        className="
                            relative top--1px cursor-pointer pl-16px pr-8px py-8px
                            md:hidden
                        "
                        onClick={toggleCollapse}
                    >
                        <img
                            src={isExpanded ? collapseIcon : expandIcon}
                            alt="Comments"
                        />
                    </div>

                    <PrimaryText
                        className="opacity-75 flex-1 md:pl-16px"
                        typography="h6"
                    >
                        Comments
                    </PrimaryText>
                </div>

                <div
                    className={`
                        flex-1 min-h-0 overflow-y-auto flex flex-col-reverse
                        ${
                            !selectedItemComments.length && isListLoading
                                ? "justify-center items-center"
                                : ""
                        }
                    `}
                >
                    {/* eslint-disable-next-line array-callback-return */}
                    {selectedItemComments.map((comment, index) => {
                        switch (comment.type) {
                            case CoveragePortalCommentType.USER:
                                return (
                                    <UserCommentCardComponent
                                        key={index}
                                        className="mb-16px"
                                        item={comment}
                                        onReplyToComment={setReplyToComment}
                                        onEditComment={setEditComment}
                                    />
                                )

                            case CoveragePortalCommentType.SYSTEM:
                                return (
                                    <SystemCommentCardComponent
                                        key={index}
                                        className="mb-16px"
                                        item={comment}
                                    />
                                )
                        }
                    })}

                    {!selectedItemComments.length && isListLoading ? (
                        <SpinnerElement />
                    ) : null}
                </div>

                <AddCommentComponent
                    patient={props.patient}
                    policy={props.policy}
                    replyToComment={replyToComment}
                    editComment={editComment}
                    onClearReplyToComment={onClearReplyToComment}
                    onClearEditComment={onClearEditComment}
                />
            </div>
        </div>
    )
}
