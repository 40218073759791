import React from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    InputElement,
    IPopupProps,
    PopupWrapperElement,
    PrimaryText,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import arrowRightImg from "../../assets/images/icons/arrow-right.svg"

export const CustomNpiCollectionPopupComponent: React.FunctionComponent<
    IPopupProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const onSave = (data: any) => {
        const inputData = data as { npi: string }

        props.onDone(inputData.npi)
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
        >
            <div className="flex items-center">
                <PrimaryText typography="h3" className="mr-24px flex-1">
                    Custom NPI
                </PrimaryText>

                <div className="cursor-pointer" onClick={() => props.onDone()}>
                    <img src={closeIcon} alt="Close" title="Close" />
                </div>
            </div>

            <PrimaryText typography="text" className="mt-32px">
                Manually input a provider NPI if you are not reusing this NPI,
                or if it isn’t appearing in our search results.
            </PrimaryText>

            <InputElement
                className="mt-44px"
                label="Individual NPI"
                name="npi"
                reactHookErrors={errors}
                reactHookControl={control}
                reactHookValidations={{
                    required: VALIDATION_CONFIG.required,
                    pattern: VALIDATION_CONFIG.npi,
                }}
            />

            <div className="mt-32px flex">
                <div className="flex-1" />

                {/*SUBMIT BTN*/}
                <ButtonElement
                    label="Save"
                    type="primary"
                    size="large"
                    htmlType="button"
                    onClick={handleSubmit(onSave)}
                    icon={arrowRightImg}
                    isRightIcon
                />
            </div>
        </PopupWrapperElement>
    )
}
