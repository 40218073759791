/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"

export const useScrollableElementScrollPosition = (
    ref?: React.RefObject<HTMLDivElement>
) => {
    const [scrollPositions, setScrollPositions] = useState<{
        left: number
        top: number
    }>()

    useEffect(() => {
        if (!ref) {
            return
        }

        const onElementScroll = () => {
            const left = ref?.current?.scrollLeft || 0
            const top = ref?.current?.scrollTop || 0

            setScrollPositions({
                left,
                top,
            })
        }

        ref?.current?.addEventListener("scroll", onElementScroll)

        return () => {
            ref?.current?.removeEventListener("scroll", onElementScroll)
        }
    }, [ref?.current])

    return scrollPositions
}
