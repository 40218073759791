import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class SupportService {
    /**
     * Report coverage result by user
     */
    static async reportCoverageResult(
        message: string,
        coverageResult: ICoverageResult,
        practice: IPractice
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.SUPPORT_REPORT_COVERAGE_RESULT.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                message,

                coverageApiRequestId: coverageResult.requestId,
                isInNetworkCheck: coverageResult.isInNetworkCheck,
                payerExternalId: coverageResult.payer?.payerId,
                memberId: coverageResult.memberId,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
