/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PrimaryText } from "nirvana-react-elements"

import { SingleOrganizationUserComponent } from "./singleOrganizationUser.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { organizationSelector } from "../../../store/selectors/organization.selector"

export const OrganizationUsersListComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const organizationState = useAppSelector(organizationSelector)

    return (
        <div
            className={`
                ${props.className}
            `}
        >
            {/*HEADINGS*/}
            <div
                className="
                    flex items-center px-16px py-8px
                    sticky top-0 bg-brand-warmLight05 z-10
                    md:hidden
                "
            >
                <PrimaryText
                    typography="h6"
                    className="opacity-75 w-400px mr-16px"
                >
                    Name
                </PrimaryText>

                <PrimaryText
                    typography="h6"
                    className="opacity-75 w-150px mr-16px"
                >
                    Role
                </PrimaryText>

                <PrimaryText typography="h6" className="opacity-75 flex-1">
                    Checks Run this Month
                </PrimaryText>
            </div>

            {organizationState.users.map((user, index) => (
                <SingleOrganizationUserComponent
                    key={index}
                    user={user}
                    isLast={index === organizationState.users.length - 1}
                />
            ))}
        </div>
    )
}
