/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    InputElement,
    IPopupProps,
    ISelectRenderedOption,
    PopupWrapperElement,
    PrimaryText,
    SelectElement,
    useStateCallback,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import {
    AvailableApplicationRole,
    ROLES_PERMISSIONS_CONFIG,
} from "../../config/rolesPermissions.config"
import { organizationInvitesCreate } from "../../store/thunks/organization.thunks"
import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"
import { billerGroupsSelector } from "../../store/selectors/billerGroups.selector"
import { billerGroupsGetList } from "../../store/thunks/billerGroups.thunks"
import { UtilHelper } from "../../helpers/util.helper"
import { FeatureFlag } from "../../config/featureFlags.config"
import { useIsFeatureFlagEnabled } from "../../hooks/isFeatureFlagEnabled.hook"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"

export const OrganizationUserInvitePopupComponent: React.FunctionComponent<
    IPopupProps
> = props => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)
    const billerGroups = useAppSelector(billerGroupsSelector)

    const [selectedBillerGroups, setSelectedBillerGroups] = useStateCallback<
        IBillerGroup[]
    >([])

    const isLoading = useMemo<boolean>(
        () => RuntimeHelper.isOrganizationInviteBeingSent(),
        [runtimeState.isLoading]
    )

    const isBillerGroupsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_BILLER_GROUPS
    )

    const getBillerGroupDisplayValue = (group: IBillerGroup): string => {
        return group.name || group.id.toString()
    }

    const availableBillerGroupsOptions = useMemo<
        ISelectRenderedOption[]
    >(() => {
        return billerGroups.items.map(item => ({
            displayValue: getBillerGroupDisplayValue(item),
            value: item,
        }))
    }, [billerGroups.items])

    const onBillerGroupSelected = (group: IBillerGroup) => {
        setSelectedBillerGroups(current => [group, ...current])
    }

    const onBillerGroupDeselected = (billerGroupDisplayValue: string) => {
        setSelectedBillerGroups(current =>
            current.filter(
                group =>
                    billerGroupDisplayValue !==
                    getBillerGroupDisplayValue(group)
            )
        )
    }

    // Preload biller groups on mount
    useEffect(() => {
        if (!selectedPracticeRole || !isBillerGroupsEnabled) {
            return
        }

        dispatch(
            billerGroupsGetList({
                payload: UtilHelper.getPagination(100),
                practice: selectedPracticeRole.practice,
            })
        )
    }, [selectedPracticeRole?.id, isBillerGroupsEnabled])

    const onSave = (data: any) => {
        if (!selectedPracticeRole) {
            return
        }

        const inputData = data as IOrganizationUserInviteData

        dispatch(
            organizationInvitesCreate({
                practice: selectedPracticeRole.practice,
                payload: {
                    role: inputData.role,
                    email: inputData.email,
                    billerGroupIds: selectedBillerGroups.map(item => item.id),
                },
                onSuccess: props.onDone,
            })
        )
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
            popupWidthClassName="w-720px rounded-24px! md:w-screen"
        >
            <div className="flex items-center">
                <PrimaryText typography="h3" className="flex-1 mr-24px">
                    Add User
                </PrimaryText>

                <div className="cursor-pointer" onClick={props.onDone}>
                    <img src={closeIcon} alt="Close" title="Close" />
                </div>
            </div>

            <InputElement
                className="mt-52px"
                name="email"
                label="Email"
                reactHookControl={control}
                reactHookErrors={errors}
                reactHookValidations={{
                    required: VALIDATION_CONFIG.required,
                    pattern: VALIDATION_CONFIG.email,
                    maxLength: VALIDATION_CONFIG.maxLength,
                }}
                inputSuffix={
                    <img
                        src={mailIcon}
                        alt="Email address"
                        title="Email address"
                        className="ml-5px"
                    />
                }
            />

            <SelectElement
                className="mt-52px"
                placeholder="Select one"
                label="Role"
                name="renderedRole"
                reactHookFormControl={control}
                validations={{
                    required: VALIDATION_CONFIG.required,
                }}
                renderedOptions={ROLES_PERMISSIONS_CONFIG.inviteRenderedRoles}
                onSelected={(role: AvailableApplicationRole) => {
                    setValue("role", role)
                }}
            />

            {isBillerGroupsEnabled ? (
                <SelectElement
                    className="mt-52px"
                    name="billerGroups"
                    mode="multiple"
                    label="Biller groups"
                    renderedOptions={availableBillerGroupsOptions}
                    onSelected={onBillerGroupSelected}
                    onDeselected={onBillerGroupDeselected}
                />
            ) : null}

            <div className="mt-32px flex">
                <div className="flex-1" />

                <ButtonElement
                    label="Send invite"
                    type="primary"
                    size="large"
                    htmlType="button"
                    onClick={handleSubmit(onSave)}
                    isLoading={isLoading}
                />
            </div>
        </PopupWrapperElement>
    )
}
