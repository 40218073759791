/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { PrimaryText } from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"

import { userDetailsSetSelectedPracticeRole } from "../../store/slices/userDetails.slice"
import { useAppSelector } from "../../store/selectors/app.selector"
import { profileSelector } from "../../store/selectors/profile.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"

import checkIcon from "../../assets/images/icons/check-dark-no-outline.svg"

export const SelectedPracticeComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const showPracticeSelection = () => setPracticeSelectActive(true)

    const hidePracticeSelection = () => setPracticeSelectActive(false)

    const containerRef = useOnclickOutside(hidePracticeSelection)

    const profile = useAppSelector(profileSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [practiceSelectActive, setPracticeSelectActive] =
        useState<boolean>(false)

    const practiceSelectionAvailable = useMemo<boolean>(
        () => !!profile && profile.practiceRoles.length > 1,
        [profile?.practiceRoles]
    )

    const isPracticeRoleSelected = (practiceRole: IPracticeRole): boolean =>
        practiceRole.id === selectedPracticeRole?.id

    const onPracticeSelected = (practiceRole: IPracticeRole) => {
        if (!isPracticeRoleSelected(practiceRole)) {
            dispatch(userDetailsSetSelectedPracticeRole(practiceRole))
        }

        hidePracticeSelection()
    }

    return profile && selectedPracticeRole ? (
        <div
            ref={containerRef}
            className={`
                ${props.className}
                ${practiceSelectionAvailable ? "cursor-pointer" : undefined}
            `}
            onClick={
                practiceSelectionAvailable && !practiceSelectActive
                    ? showPracticeSelection
                    : undefined
            }
        >
            <PrimaryText typography="buttonText" className="pt-3px">
                {selectedPracticeRole.practice.name}
            </PrimaryText>

            {/*practice selection if multiple*/}
            {practiceSelectActive && (
                <div
                    className="
                        absolute w-300px z-100 bg-brand-offWhite top-55px right-0
                        rounded-8px border border-solid border-brand-warmShadow
                        overflow-hidden
                    "
                >
                    {profile.practiceRoles.map((item, index) => (
                        <div
                            className={`
                                flex items-center p-16px
                                ${
                                    profile?.practiceRoles[index + 1]
                                        ? "border-b border-solid border-brand-warmShadow"
                                        : ""
                                }
                                ${
                                    isPracticeRoleSelected(item)
                                        ? "cursor-default bg-brand-lilacLight"
                                        : "cursor-pointer bg-brand-white"
                                }
                            `}
                            onClick={() => onPracticeSelected(item)}
                            key={index}
                        >
                            <PrimaryText
                                className="flex-1 mr-16px"
                                typography="textSemibold"
                            >
                                {item.practice.name}
                            </PrimaryText>

                            {isPracticeRoleSelected(item) ? (
                                <img src={checkIcon} alt="Selected" />
                            ) : (
                                <div className="w-12px" />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    ) : null
}
