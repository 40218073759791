/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    ButtonElement,
    PrimaryText,
    CheckboxElement,
    NIRVANA_COLORS,
    ConfirmationHelper,
} from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"

import { STYLES_CONFIG } from "../../../config/styles.config"

import calendarIcon from "../../../assets/images/icons/calendar-dark.svg"
import eyeCrossedIcon from "../../../assets/images/icons/eye-crossed-dark.svg"
import closeIcon from "../../../assets/images/icons/close-dark.svg"

export const PoliciesColumnsManagementComponent: React.FunctionComponent<
    IPolicyColumnsManagementComponentProps
> = props => {
    const containerRef = useOnclickOutside(
        () => !isResetConfirmActive && setIsSelectionActive(false)
    )

    const [isSelectionActive, setIsSelectionActive] = useState<boolean>(false)
    const [isResetConfirmActive, setIsResetConfirmActive] =
        useState<boolean>(false)

    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >(props.currentColumnsConfiguration)

    const isAllSelected = useMemo<boolean>(() => {
        return (
            columnsConfiguration.length ===
            columnsConfiguration.filter(item => item.isEnabled).length
        )
    }, [columnsConfiguration])

    const currentlyHiddenColumns = useMemo<IPolicyColumnConfiguration[]>(() => {
        return columnsConfiguration.filter(item => !item.isEnabled)
    }, [columnsConfiguration])

    const enabledColumns = useMemo<IPolicyColumnConfiguration[]>(
        () => columnsConfiguration.filter(item => item.isEnabled),
        [columnsConfiguration]
    )

    const isResetEnabled = useMemo<boolean>(() => {
        return (
            JSON.stringify(props.currentColumnsConfiguration) !==
            JSON.stringify(props.initialColumnsConfiguration)
        )
    }, [columnsConfiguration, props.initialColumnsConfiguration])

    const isApplyEnabled = useMemo<boolean>(() => {
        return (
            JSON.stringify(columnsConfiguration) !==
            JSON.stringify(props.currentColumnsConfiguration)
        )
    }, [columnsConfiguration, props.currentColumnsConfiguration])

    // Update local columns config when external changes
    useEffect(() => {
        setColumnsConfiguration(props.currentColumnsConfiguration)
    }, [props.currentColumnsConfiguration])

    const toggleColumnVisibility = (
        columnConfig: IPolicyColumnConfiguration
    ) => {
        setColumnsConfiguration(current => {
            const currentCopy = current.map(item => Object.assign({}, item))

            const neededIndex = current.findIndex(
                item => item.label === columnConfig.label
            )

            if (!~neededIndex) {
                return currentCopy
            }

            currentCopy[neededIndex].isEnabled =
                !currentCopy[neededIndex].isEnabled

            return currentCopy
        })
    }

    const toggleAllColumnsSelection = () => {
        const columns = columnsConfiguration.map(column => ({
            ...column,
            isEnabled: !isAllSelected,
        }))

        setColumnsConfiguration(columns)
    }

    const applyChanges = () => {
        // don't apply changes if no columns selected at all
        if (!enabledColumns.length) {
            return
        }

        props.onColumnsConfigurationChange(columnsConfiguration)
        setIsSelectionActive(false)
    }

    const onReset = () => {
        setIsResetConfirmActive(true)

        ConfirmationHelper.enableConfirmation(
            () => {
                setColumnsConfiguration(
                    props.initialColumnsConfiguration.map(item =>
                        Object.assign({}, item)
                    )
                )

                setIsResetConfirmActive(false)
            },
            undefined,
            `Columns will be reset to defaults. All visibility and ordering changes will be lost.`,
            () => {
                setIsResetConfirmActive(false)
            }
        )
    }

    const onCancel = () => {
        setColumnsConfiguration(props.currentColumnsConfiguration)
        setIsSelectionActive(false)
    }

    const getRenderedFields = (
        fields: IPolicyColumnConfiguration[]
    ): JSX.Element => {
        return (
            <>
                {fields.map((config, index) => {
                    return (
                        <div
                            key={index}
                            className="
                                cursor-pointer mt-20px bg-brand-white
                                flex items-center justify-start
                            "
                            onClick={() => toggleColumnVisibility(config)}
                        >
                            <CheckboxElement
                                name={config.label}
                                defaultChecked={config.isEnabled}
                                watchDefaultCheckedChange
                                className="mr-16px ml-4px"
                                size="big"
                            />

                            <PrimaryText
                                className="flex-1"
                                typography="buttonText"
                            >
                                {config.label}
                            </PrimaryText>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div
            ref={containerRef}
            className={`
                relative
                ${props.className}
            `}
        >
            <div
                className="
                    cursor-pointer rounded-22px px-16px py-13px bg-brand-lilac
                    flex items-center justify-center
                    border border-solid border-brand-primary
                "
                onClick={() => setIsSelectionActive(true)}
            >
                <img src={eyeCrossedIcon} alt="hidden" className="mr-8px" />

                <PrimaryText
                    typography="buttonText"
                    className="relative top-2px"
                >
                    {currentlyHiddenColumns.length} hidden field
                    {currentlyHiddenColumns.length === 1 ? "" : "s"}
                </PrimaryText>

                <img src={calendarIcon} alt="columns" className="ml-8px" />
            </div>

            {isSelectionActive && (
                <div
                    className={`
                        no-body-scroll
                        fixed h-screen z-20 bg-brand-white shadow-7 overflow-hidden overflow-y-auto
                        w-365px sm:w-screen top-0
                        ${props.isRightPlacement ? "right-0" : "left-0"}
                    `}
                >
                    <div className="p-24px">
                        <div className="flex">
                            <div className="flex-1" />

                            <div
                                className="cursor-pointer px-16px pb-4px"
                                onClick={onCancel}
                            >
                                <img src={closeIcon} alt="close" />
                            </div>
                        </div>

                        <div className="mt-24px flex items-center border-b-1px border-gray-200 pb-20px mb-20px">
                            <div onClick={toggleAllColumnsSelection}>
                                <PrimaryText
                                    typography="buttonText"
                                    weight="bold"
                                    color={NIRVANA_COLORS.brand.vibrantPurple}
                                    underline
                                    className="cursor-pointer"
                                >
                                    {isAllSelected
                                        ? "Deselect All"
                                        : "Select All"}
                                </PrimaryText>
                            </div>

                            <div className="flex-1" />

                            <div
                                onClick={() => {
                                    isResetEnabled && onReset()
                                }}
                            >
                                <PrimaryText
                                    typography="buttonText"
                                    weight="bold"
                                    color={NIRVANA_COLORS.brand.vibrantPurple}
                                    underline
                                    className={
                                        isResetEnabled
                                            ? "cursor-pointer"
                                            : "opacity-20"
                                    }
                                >
                                    Reset to defaults
                                </PrimaryText>
                            </div>
                        </div>

                        <PrimaryText
                            typography="buttonText"
                            className="mt-24px"
                        >
                            Default Fields
                        </PrimaryText>

                        {getRenderedFields(
                            columnsConfiguration.filter(
                                item => item.isDefaultColumn
                            )
                        )}

                        <PrimaryText
                            typography="buttonText"
                            className="mt-32px"
                        >
                            Additional Fields
                        </PrimaryText>

                        {getRenderedFields(
                            columnsConfiguration.filter(
                                item => !item.isDefaultColumn
                            )
                        )}
                    </div>

                    <div
                        className="
                            sticky z-10 bottom-0 left-0 w-full bg-brand-white p-24px
                            border-t border-gray-200 mt-20px pt-20px flex justify-between
                        "
                    >
                        <ButtonElement
                            buttonClassName="w-100px h-40px bg-white! text-base font-medium rounded-8px!"
                            label="Cancel"
                            type="ghost"
                            htmlType="button"
                            onClick={onCancel}
                            buttonCss={STYLES_CONFIG.button.smallBorderRadius}
                        />

                        <ButtonElement
                            label="Apply"
                            type="primary"
                            buttonClassName="w-100px h-40px text-base font-medium"
                            htmlType="button"
                            onClick={applyChanges}
                            buttonCss={STYLES_CONFIG.button.smallBorderRadius}
                            disabled={!enabledColumns.length || !isApplyEnabled}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
