/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText } from "nirvana-react-elements"
import moment from "moment-timezone"

import { CoveragePortalCommentRenderingType } from "../../../config/policies.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { POLICIES_COLUMNS_CONFIG } from "../../../config/policiesColumns.config"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"

export const SystemCommentCardComponent: React.FunctionComponent<
    ISystemCommentCardComponentProps
> = props => {
    const renderedMiddlePart = useMemo<JSX.Element | null>(() => {
        switch (props.item.renderingType) {
            case CoveragePortalCommentRenderingType.OVERRIDE:
                if (!props.item.renderingData?.responseDifferences?.length) {
                    return null
                }

                return (
                    <div className="mb-16px">
                        {/*HEADER*/}
                        <div className="flex items-start bg-brand-white px-8px pt-10px pb-6px">
                            <PrimaryText
                                className="flex-1 mr-8px"
                                typography="captionSemibold"
                            >
                                Field
                            </PrimaryText>

                            <PrimaryText
                                className="flex-1 mr-8px"
                                typography="captionSemibold"
                            >
                                New Value
                            </PrimaryText>

                            <PrimaryText
                                className="flex-1"
                                typography="captionSemibold"
                            >
                                Previous Value
                            </PrimaryText>
                        </div>

                        {props.item.renderingData.responseDifferences.map(
                            (item, index) => {
                                const columnConfig =
                                    POLICIES_COLUMNS_CONFIG.columnsConfig[
                                        item.key
                                    ]

                                const previousValue =
                                    typeof item.previousValue !== "undefined" &&
                                    item.previousValue !== null
                                        ? PoliciesHelper.getFormattedColumnValue(
                                              item.previousValue,
                                              columnConfig,
                                              COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
                                          )
                                        : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown

                                const currentValue =
                                    typeof item.currentValue !== "undefined" &&
                                    item.currentValue !== null
                                        ? PoliciesHelper.getFormattedColumnValue(
                                              item.currentValue,
                                              columnConfig,
                                              COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
                                          )
                                        : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown

                                if (
                                    previousValue === currentValue &&
                                    previousValue ===
                                        COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
                                ) {
                                    return null
                                }

                                return (
                                    <div
                                        key={index}
                                        className="mt-1px flex items-start bg-brand-white px-8px pt-10px pb-6px"
                                    >
                                        <PrimaryText
                                            className="flex-1 mr-8px min-w-0 truncate"
                                            typography="caption"
                                        >
                                            {columnConfig.label}
                                        </PrimaryText>

                                        <PrimaryText
                                            className="flex-1 mr-8px min-w-0 truncate"
                                            typography="caption"
                                        >
                                            {currentValue}
                                        </PrimaryText>

                                        <PrimaryText
                                            className="flex-1 min-w-0 truncate"
                                            typography="caption"
                                        >
                                            {previousValue}
                                        </PrimaryText>
                                    </div>
                                )
                            }
                        )}
                    </div>
                )

            case CoveragePortalCommentRenderingType.GENERAL:
            default:
                return null
        }
    }, [props.item])

    const renderedFooter = useMemo<JSX.Element | null>(() => {
        switch (props.item.renderingType) {
            case CoveragePortalCommentRenderingType.OVERRIDE:
                if (!props.item.renderingData?.overrideExpirationDate) {
                    return null
                }

                return (
                    <PrimaryText
                        typography="caption"
                        className="px-8px"
                        centered
                    >
                        Override Expiration date:{" "}
                        {moment(
                            props.item.renderingData.overrideExpirationDate
                        ).format(GENERAL_CONFIG.defaultMomentDateFormat)}
                    </PrimaryText>
                )

            case CoveragePortalCommentRenderingType.GENERAL:
            default:
                return null
        }
    }, [props.item])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div className="p-8px flex items-center">
                <div className="h-1px bg-brand-warmShadow flex-1" />

                <div className="mx-8px">
                    <PrimaryText typography="captionSemibold" centered>
                        {props.item.comment}
                    </PrimaryText>

                    <PrimaryText typography="caption" centered>
                        {moment(props.item.createdAt).format(
                            GENERAL_CONFIG.defaultMomentDateTimeFormat
                        )}
                    </PrimaryText>
                </div>

                <div className="h-1px bg-brand-warmShadow flex-1" />
            </div>

            {renderedMiddlePart}

            {renderedFooter}
        </div>
    )
}
