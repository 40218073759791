export enum FeatureFlag {
    // Coverage Portal
    ONE_VERIFY_REPORTS = "one_verify-reports",
    ONE_VERIFY_REVIEW = "one_verify-review",
    ONE_VERIFY_BILLER_GROUPS = "one_verify-biller_groups",
    ONE_VERIFY_OVERRIDES = "one_verify-overrides",
    ONE_VERIFY_PLAN_YEAR_RESETS = "one_verify-plan-year-resets",
    // TODO remove this at some point
    ONE_VERIFY_FLAGS_RESOLUTION = "one_verify-flags_resolution",
}

export const FEATURE_FLAGS_CONFIG = {}
