/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText, ButtonElement } from "nirvana-react-elements"
import moment from "moment-timezone"

import { GENERAL_CONFIG } from "../../config/general.config"
import { InvitedPracticeElement } from "../../elements/invitedPractice.element"
import { authSetCheckUserInvitation } from "../../store/slices/auth.slice"
import { userDetailsAcceptOrganizationInvite } from "../../store/thunks/userDetails.thunks"
import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { useAppDispatch } from "../../store/appDispatch.hook"

import logo from "../../assets/images/logo.svg"
import mainImage from "../../assets/images/shapes/invite-accept.svg"

export const PracticeAcceptInvitationComponent: React.FunctionComponent<{
    invite: IOrganizationUserInvite
}> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)

    const isLoading = useMemo(
        () => RuntimeHelper.isAcceptingOrganizationInviteLoading(),
        [runtimeState.isLoading]
    )

    const onDeclineInvitation = () => dispatch(authSetCheckUserInvitation())

    const onAcceptInvitation = () => {
        dispatch(
            userDetailsAcceptOrganizationInvite({
                payload: props.invite,
            })
        )
    }

    return (
        <div className="fixed z-1070 let-0 top-0 bg-brand-offWhite w-screen h-screen pt-40px px-40px">
            <div className="pb-42px">
                <img src={logo} alt="Nirvana" />
            </div>

            <img
                src={mainImage}
                alt="Nirvana"
                title="Nirvana"
                className="margin-x-center sm:w-150px"
            />

            <PrimaryText className="mt-44px" typography="h4" centered>
                You were invited to join organization
            </PrimaryText>

            {props.invite.expiresAt && (
                <PrimaryText className="mt-8px" centered>
                    Please accept or decline invitation before{" "}
                    {moment(props.invite.expiresAt).format(
                        GENERAL_CONFIG.defaultMomentDateTimeFormat
                    )}
                </PrimaryText>
            )}

            <div className="max-w-400px mx-auto mt-32px">
                {props.invite.practice && (
                    <InvitedPracticeElement practice={props.invite.practice} />
                )}

                <div
                    className="
                        flex items-center mt-32px
                    "
                >
                    <ButtonElement
                        buttonClassName="sm:w-full"
                        label="Decline"
                        type="default"
                        htmlType="button"
                        size="large"
                        onClick={onDeclineInvitation}
                    />

                    <div className="flex-1 mx-16px" />

                    <ButtonElement
                        buttonClassName="sm:w-full"
                        label="Accept Invitation"
                        type="primary"
                        htmlType="button"
                        size="large"
                        isLoading={isLoading}
                        onClick={onAcceptInvitation}
                    />
                </div>
            </div>
        </div>
    )
}
