import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import { UtilHelper } from "../../helpers/util.helper"

const initialState: IBillerGroupsState = {
    items: [],
    itemsPagination: UtilHelper.getPagination(),
    selectedItem: undefined,
    selectedItemUser: undefined,
}

const billerGroupsSlice = createSlice({
    name: "billerGroups",
    initialState,
    reducers: {
        billerGroupsReset: () => initialState,

        billerGroupsSetList: (
            state,
            action: PayloadAction<{
                items: IBillerGroup[]
                newPagination: IPagination
            }>
        ) => {
            // Basically if we were requesting with start=0
            const wasNewPagination =
                action.payload.newPagination.start -
                    action.payload.newPagination.count <=
                0

            // IF was new pagination, then we replace all clients in array, otherwise add to array
            // When adding to array, make sure there are no duplicates by id
            state.items = wasNewPagination
                ? action.payload.items
                : (UtilHelper.mergeArrayByUniqueKey(
                      state.items,
                      action.payload.items,
                      "id"
                  ) as IBillerGroup[])

            state.itemsPagination = action.payload.newPagination
        },

        billerGroupsSelectSingle: (
            state,
            action: PayloadAction<{
                item?: IBillerGroup
                ignoreSelectedUserReset?: boolean
            }>
        ) => {
            state.selectedItem = action.payload.item

            state.selectedItemUser = action.payload.ignoreSelectedUserReset
                ? state.selectedItemUser
                : undefined
        },

        billerGroupsCreateDone: (
            state,
            action: PayloadAction<IBillerGroup>
        ) => {
            return {
                ...state,
                items: [action.payload, ...state.items],
                itemsPagination: {
                    ...state.itemsPagination,

                    start: state.itemsPagination.start + 1,
                    total: state.itemsPagination.total + 1,
                },
                selectedItem: action.payload,
            }
        },

        billerGroupsUpdateDone: (
            state,
            action: PayloadAction<IBillerGroup>
        ) => {
            const isSelectedBillerGroupUpdated =
                state.selectedItem?.id === action.payload.id

            const userExistInUpdatedBillerGroup =
                !!state.selectedItemUser &&
                !!action.payload.users.find(
                    item => item.id === state.selectedItemUser?.id
                )

            const newBillerGroups = [...state.items]

            const neededIndex = newBillerGroups.findIndex(
                item => item.id === action.payload.id
            )

            if (neededIndex > -1) {
                newBillerGroups[neededIndex] = action.payload
            }

            return {
                ...state,
                items: newBillerGroups,
                selectedItem: isSelectedBillerGroupUpdated
                    ? action.payload
                    : state.selectedItem,

                selectedItemUser: !state.selectedItemUser
                    ? undefined
                    : userExistInUpdatedBillerGroup ||
                      !isSelectedBillerGroupUpdated
                    ? userExistInUpdatedBillerGroup
                        ? state.selectedItemUser
                        : {
                              ...state.selectedItemUser,
                              billerGroups:
                                  state.selectedItemUser.billerGroups.filter(
                                      group => group.id !== action.payload.id
                                  ),
                          }
                    : undefined,
            }
        },

        billerGroupsDeleteDone: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                items: state.items.filter(item => item.id !== action.payload),
                itemsPagination: {
                    ...state.itemsPagination,

                    start: state.itemsPagination.start - 1,
                    total: state.itemsPagination.total - 1,
                },
                selectedItem: undefined,
                selectedItemUser: undefined,
            }
        },

        billerGroupsSelectUser: (
            state,
            action: PayloadAction<IOrganizationUser | undefined>
        ) => {
            state.selectedItemUser = action.payload
        },
    },
})

export const {
    billerGroupsReset,
    billerGroupsSetList,
    billerGroupsSelectSingle,
    billerGroupsCreateDone,
    billerGroupsUpdateDone,
    billerGroupsDeleteDone,
    billerGroupsSelectUser,
} = billerGroupsSlice.actions

export const billerGroupsReducer = billerGroupsSlice.reducer
