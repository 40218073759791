/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { AvatarElement, PrimaryText } from "nirvana-react-elements"

import chevronRightIcon from "../../../assets/images/icons/chevron-right-dark.svg"
import trashIcon from "../../../assets/images/icons/trash-dark.svg"

export const BillerGroupUsersListComponent: React.FunctionComponent<
    IBillerGroupUsersListComponentProps
> = props => {
    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.users.map((user, index) => {
                const isSelected = user.id === props.selectedUser?.id
                const isLast = index === props.users.length - 1

                return (
                    <div
                        key={index}
                        className={`
                            flex items-center px-24px py-12px
                            ${
                                !isLast
                                    ? "border-b-1px border-solid border-brand-warmLight"
                                    : ""
                            }
                            ${
                                isSelected
                                    ? "bg-brand-lilacLight"
                                    : "bg-brand-white hover:bg-brand-whitePurple"
                            }
                            ${
                                props.onUserSelected && !isSelected
                                    ? "cursor-pointer"
                                    : ""
                            }
                        `}
                        onClick={() => props.onUserSelected?.(user)}
                    >
                        <AvatarElement
                            className="mr-12px"
                            user={user}
                            width={24}
                            fontClassName="fs-12px lh-12px pt-2px"
                        />

                        <PrimaryText className="flex-1 mr-16px">
                            {user.name}
                        </PrimaryText>

                        <PrimaryText
                            typography="caption"
                            className={
                                props.onUserSelected || props.onUserRemoved
                                    ? "mr-16px"
                                    : ""
                            }
                        >
                            In {user.billerGroups.length} group
                            {user.billerGroups.length === 1 ? "" : "s"}
                        </PrimaryText>

                        {props.onUserSelected ? (
                            <div
                                className={props.onUserRemoved ? "mr-16px" : ""}
                            >
                                <img src={chevronRightIcon} alt="Select" />
                            </div>
                        ) : null}

                        {props.onUserRemoved ? (
                            <div
                                onClick={e => {
                                    e.stopPropagation()
                                    props.onUserRemoved?.(user)
                                }}
                                className="cursor-pointer"
                            >
                                <img src={trashIcon} alt="Remove" />
                            </div>
                        ) : null}
                    </div>
                )
            })}
        </div>
    )
}
