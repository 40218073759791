/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import moment from "moment-timezone"
import { PrimaryText } from "nirvana-react-elements"

import { UtilHelper } from "../../../helpers/util.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { CALCULATOR_CONFIG } from "../../../config/calculator.config"
import { CollapseElement } from "../../../elements/collapse.element"
import { HtmlHelper } from "../../../helpers/html.helper"
import { CoverageBreakdownBadgeElement } from "../../../elements/coverageBadges/coverageBreakdownBadge.element"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"
import { FlagBadgeComponent } from "../../policies/flags/flagBadge.component"

export const CoverageResultGeneralComponent: React.FunctionComponent<
    ICoverageResultGeneralComponentProps
> = props => {
    const getPersonDemographicsBreakdown = (values: {
        firstName?: string
        lastName?: string
        firstNameOverride?: string
        lastNameOverride?: string
        dob?: string
        dobOverride?: string
        address?: ICoverageResultDemographicsAddress
        gender?: string
    }): ICoverageBreakdownData[] => {
        return [
            {
                label: "Member Name",
                value:
                    !values.firstName && !values.lastName
                        ? "Not provided"
                        : UtilHelper.capitalizeWordsInSentence(
                              [values.firstName, values.lastName]
                                  .filter(item => !!item)
                                  .map(item => item?.trim())
                                  .join(" ")
                          ),
                valueDifference:
                    !values.firstNameOverride && !values.lastNameOverride
                        ? undefined
                        : UtilHelper.capitalizeWordsInSentence(
                              [
                                  values.firstNameOverride ||
                                      values.firstName ||
                                      COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                                  values.lastNameOverride ||
                                      values.lastName ||
                                      COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                              ]
                                  .map(item => item?.trim())
                                  .join(" ")
                          ),
            },
            {
                label: "DOB",
                value: values.dob
                    ? moment(values.dob).format(
                          GENERAL_CONFIG.defaultMomentDateFormat
                      )
                    : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                valueDifference: values.dobOverride
                    ? moment(values.dobOverride).format(
                          GENERAL_CONFIG.defaultMomentDateFormat
                      )
                    : undefined,
            },
            {
                label: "Address",
                value: values.address
                    ? UtilHelper.getFormattedDemographicsAddress(values.address)
                    : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
            },
            {
                label: "Gender",
                value: values.gender
                    ? CALCULATOR_CONFIG.genderMapping[values.gender].toString()
                    : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
            },
        ]
    }

    const patientDemographics = useMemo<ICoverageBreakdownData[]>(() => {
        const items = getPersonDemographicsBreakdown({
            firstName: props.memberFirstName,
            firstNameOverride: props.memberFirstNameOverride,
            lastName: props.memberLastName,
            lastNameOverride: props.memberLastNameOverride,
            dob: props.memberDob,
            dobOverride: props.memberDobOverride,
            address: props.memberAddress,
            gender: props.memberGender,
        })

        if (props.relationshipToSubscriber) {
            items.push({
                label: "Rel. to Subscriber",
                value: props.relationshipToSubscriber,
            })
        }

        return items
    }, [props])

    const subscriberDemographics = useMemo<
        ICoverageBreakdownData[] | null
    >(() => {
        if (!props.subscriberDemographics) {
            return null
        }

        return [
            {
                label: "Member ID",
                value: props.subscriberDemographics.memberId,
            },

            ...getPersonDemographicsBreakdown({
                firstName: props.subscriberDemographics.firstName,
                lastName: props.subscriberDemographics.lastName,
                dob: props.subscriberDemographics.dob,
                address: props.subscriberDemographics.address || undefined,
                gender: props.subscriberDemographics.gender,
            }),
        ]
    }, [props.subscriberDemographics])

    const providerData = useMemo<ICoverageBreakdownData[] | null>(() => {
        const items: ICoverageBreakdownData[] = []

        if (props.providerName) {
            items.push({
                label: "Name",
                value: props.providerName,
            })
        }

        if (props.npi) {
            items.push({
                label: "NPI",
                value: props.npi,
            })
        }

        if (props.cptCode) {
            items.push({
                label: "CPT Code",
                value: props.cptCode,
            })
        }

        if (props.sessionRate) {
            items.push({
                label: "Session Rate",
                value: UtilHelper.getFormattedMoney(
                    parseInt(props.sessionRate.toString()),
                    true
                ),
            })
        }

        if (!items.length) {
            return null
        }

        return items
    }, [props])

    const demographicsContent = useMemo<JSX.Element>(() => {
        return (
            <div>
                {props.flags?.length ? (
                    <div className="flex items-center flex-wrap mb-16px">
                        {props.flags.map((item, index) => {
                            return (
                                <FlagBadgeComponent
                                    key={index}
                                    className="mt-4px mr-8px"
                                    flagType={item}
                                />
                            )
                        })}
                    </div>
                ) : null}

                <div className="mt-8px flex items-start md:block">
                    {/*MEMBER*/}
                    <div className="flex-1 mr-24px md:mr-0px">
                        {patientDemographics.map((item, index) => {
                            return HtmlHelper.getCoverageBreakdownItem(
                                item,
                                index
                            )
                        })}
                    </div>

                    {/*SUBSCRIBER*/}
                    <div className="flex-1 md:mt-24px">
                        {subscriberDemographics ? (
                            <>
                                <PrimaryText>
                                    Subscriber Information
                                </PrimaryText>

                                <div className="pl-16px border-l border-solid border-brand-primary">
                                    {subscriberDemographics.map(
                                        (item, index) => {
                                            return HtmlHelper.getCoverageBreakdownItem(
                                                item,
                                                index
                                            )
                                        }
                                    )}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }, [patientDemographics, subscriberDemographics, props.flags])

    const providerContent = useMemo<JSX.Element | null>(() => {
        if (!providerData) {
            return null
        }

        const middleIndex = Math.ceil(providerData.length / 2)

        const leftData = providerData.slice(0, middleIndex)
        const rightData = providerData.slice(middleIndex)

        return (
            <div className="mt-8px flex items-start md:block">
                <div className="flex-1 mr-24px md:mr-0px">
                    {leftData.map((item, index) => {
                        return HtmlHelper.getCoverageBreakdownItem(item, index)
                    })}
                </div>

                <div className="flex-1">
                    {rightData.map((item, index) => {
                        return HtmlHelper.getCoverageBreakdownItem(item, index)
                    })}
                </div>
            </div>
        )
    }, [providerData])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.isInputOverridden && (
                <PrimaryText
                    className="
                        mb-24px bg-brand-orange010 px-8px py-24px rounded-8px
                        border border-solid border-brand-orange
                    "
                    typography="textSemibold"
                    centered
                >
                    Our results found some differences. Please see below.
                </PrimaryText>
            )}

            <div
                className={`
                    bg-brand-white
                    ${props.rounded ? "rounded-8px" : ""}
                `}
            >
                <CollapseElement
                    className="p-24px"
                    label="Patient Demographics"
                    content={demographicsContent}
                    rightHeaderContent={
                        <div className="flex items-center flex-wrap mt--4px">
                            {props.customerPatientType ? (
                                <CoverageBreakdownBadgeElement
                                    className="my-4px"
                                    label={
                                        COVERAGE_CONFIG
                                            .selectRenderedPatientTypes[
                                            props.customerPatientType
                                        ].displayValue
                                    }
                                />
                            ) : null}

                            {props.customerPatientId ? (
                                <CoverageBreakdownBadgeElement
                                    className="my-4px"
                                    label="Patient ID"
                                    value={props.customerPatientId}
                                />
                            ) : null}

                            {props.customerPatientNextAppointmentDate ? (
                                <CoverageBreakdownBadgeElement
                                    className="my-4px"
                                    label="Next Appt Date"
                                    value={moment(
                                        props.customerPatientNextAppointmentDate
                                    ).format(
                                        GENERAL_CONFIG.defaultMomentDateFormat
                                    )}
                                />
                            ) : null}
                        </div>
                    }
                />

                {providerContent ? (
                    <div>
                        <div className="h-1px w-full bg-brand-black-transparent-010" />

                        <CollapseElement
                            className="p-24px"
                            label="Provider"
                            isDefaultCollapsed={true}
                            content={providerContent}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
