import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    policiesSetSelectedItemComments,
    policiesAddedSelectedItemComments,
    policiesUpdatedSelectedItemComment,
    policiesDeletedSelectedItemComment,
} from "../slices/policies.slice"
import { CoverageHistoryCommentsService } from "../../services/coverageHistoryComments.service"
import { RootState } from "../store"
import { CoverageHistoryHelper } from "../../helpers/coverageHistory.helper"

/**
 * Get single policy/patient comments
 */
export const policiesGetSinglePolicyComments = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        patient?: IPatientIdentificationData
        policy?: IPolicyIdentificationData
    }>
>("policiesGetSinglePolicyCommentsPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesGetSinglePolicyCommentsLoading"))

    try {
        const result = await CoverageHistoryCommentsService.getList(
            action.practice,
            action.payload.patient,
            action.payload.policy
        )

        if (result) {
            dispatch(policiesSetSelectedItemComments(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesGetSinglePolicyCommentsLoading"))
    }
})

/**
 * Create comment for single policy/patient
 */
export const policiesCreateSinglePolicyComment = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        comment: string
        patient?: IPatientIdentificationData
        policy?: IPolicyIdentificationData
        replyToCommentId?: number
    }>
>("policiesCreateSinglePolicyCommentPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesCreateSinglePolicyCommentLoading"))

    try {
        const result = await CoverageHistoryCommentsService.create(
            action.practice,
            action.payload.comment,
            action.payload.patient,
            action.payload.policy,
            action.payload.replyToCommentId
        )

        if (result) {
            dispatch(policiesAddedSelectedItemComments(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesCreateSinglePolicyCommentLoading"))
    }
})

/**
 * Update comment for single policy/patient
 */
export const policiesUpdateSinglePolicyComment = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        commentId: number
        comment: string
    }>
>("policiesUpdateSinglePolicyCommentPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesUpdateSinglePolicyCommentLoading"))

    try {
        const result = await CoverageHistoryCommentsService.update(
            action.practice,
            action.payload.commentId,
            action.payload.comment
        )

        if (result) {
            dispatch(policiesUpdatedSelectedItemComment(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesUpdateSinglePolicyCommentLoading"))
    }
})

/**
 * Delete comment for single policy/patient
 */
export const policiesDeleteSinglePolicyComment = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        commentId: number
    }>
>("policiesDeleteSinglePolicyCommentPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("policiesDeleteSinglePolicyCommentLoading"))

    try {
        const result = await CoverageHistoryCommentsService.delete(
            action.practice,
            action.payload.commentId
        )

        if (result) {
            dispatch(policiesDeletedSelectedItemComment(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("policiesDeleteSinglePolicyCommentLoading"))
    }
})

/**
 * New comment was received through sockets
 */
export const policiesNewCommentReceivedThroughSockets = createAsyncThunk<
    void,
    IThunkActionData<IPolicyComment>
>(
    "policiesNewCommentReceivedThroughSocketsPrefix",
    async (action, { dispatch, getState }) => {
        try {
            const globalState = getState() as RootState
            const { selectedItem } = globalState.policies

            if (
                !CoverageHistoryHelper.isCommentRelatedCoverageCheck(
                    selectedItem,
                    action.payload
                )
            ) {
                return
            }

            dispatch(policiesAddedSelectedItemComments(action.payload))
        } catch (e) {}
    }
)
