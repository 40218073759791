/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import {
    SelectElement,
    ProgressElement,
    PrimaryText,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"

import { POLICIES_CONFIG } from "../../../config/policies.config"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { policiesSetVisualizationsCollapseTrigger } from "../../../store/slices/policies.slice"

import paginateLeftIcon from "../../../assets/images/icons/paginate-left.svg"
import paginateRightIcon from "../../../assets/images/icons/paginate-right.svg"
import searchIcon from "../../../assets/images/icons/search-dark.svg"
import closeIcon from "../../../assets/images/icons/close-dark.svg"

export const PoliciesListPaginationComponent: React.FunctionComponent<
    IPoliciesListPaginationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const { handleSubmit, control } = useForm()

    const paginationEnabled = useMemo<boolean>(() => {
        return props.nextPagination.total > props.nextPagination.count
    }, [props.nextPagination])

    const previousStart = useMemo<number>(() => {
        return props.nextPagination.start - 2 * props.nextPagination.count
    }, [props.nextPagination])

    const previousPaginationEnabled = useMemo<boolean>(() => {
        return previousStart >= 0
    }, [previousStart])

    const nextPaginationEnabled = useMemo<boolean>(() => {
        return !!props.nextPagination.moreAvailable
    }, [props.nextPagination])

    const pagesConfig = useMemo<{
        pages: number[]
        currentPage: number
    }>(() => {
        const pages = Array.from(
            Array(
                Math.ceil(
                    props.nextPagination.total / props.nextPagination.count
                )
            ).keys()
        )

        const currentPage =
            Math.floor(
                (props.nextPagination.start - props.nextPagination.count) /
                    props.nextPagination.count
            ) + 1

        return {
            pages: pages.map(item => item + 1),
            currentPage,
        }
    }, [props.nextPagination])

    // Will show current and some on both sides
    const neededRenderedPages = useMemo<{
        hasPrevious: boolean
        hasNext: boolean
        pages: number[]
    }>(() => {
        const previousPages: number[] = []
        const nextPages: number[] = []

        for (let i = 1; i <= 2; i++) {
            const previousPage = pagesConfig.pages.includes(
                pagesConfig.currentPage - i
            )
                ? pagesConfig.currentPage - i
                : null

            const nextPage = pagesConfig.pages.includes(
                pagesConfig.currentPage + i
            )
                ? pagesConfig.currentPage + i
                : null

            if (previousPage) {
                previousPages.push(previousPage)
            }

            if (nextPage) {
                nextPages.push(nextPage)
            }
        }

        const pages = [...previousPages, pagesConfig.currentPage, ...nextPages]
        const hasPrevious = !pages.includes(Math.min(...pagesConfig.pages))
        const hasNext = !pages.includes(Math.max(...pagesConfig.pages))

        // Always add last page
        if (hasNext) {
            pages.push(Math.max(...pagesConfig.pages))
        }

        // Sort asc
        pages.sort((a, b) => a - b)

        return {
            hasPrevious,
            hasNext,
            pages,
        }
    }, [pagesConfig.pages])

    const [isGoToPageActive, setIsGoToPageActive] = useState<boolean>(false)

    const onPaginationOptionSelected = (selectedValue: any) => {
        const neededItem = POLICIES_CONFIG.availablePaginationOptions.find(
            item => item.value === selectedValue
        )

        if (!neededItem) {
            return
        }

        props.setSelectedPaginationOption(neededItem)

        dispatch(policiesSetVisualizationsCollapseTrigger())
    }

    const getNextItems = (isLast?: boolean) => {
        if (!nextPaginationEnabled) {
            return
        }

        onGetNewItems(
            !isLast
                ? props.nextPagination.start
                : (Math.max(...pagesConfig.pages) - 1) *
                      props.nextPagination.count
        )
    }

    const getPreviousItems = (neededStart?: number) => {
        if (!previousPaginationEnabled) {
            return
        }

        onGetNewItems(
            typeof neededStart !== "undefined" ? neededStart : previousStart
        )
    }

    const onGetNewItems = (start: number) => {
        props.getNewItems({
            ...props.nextPagination,

            start,
            moreAvailable: true,
        })

        dispatch(policiesSetVisualizationsCollapseTrigger())
    }

    const toggleGoToPage = (value?: boolean) => {
        setIsGoToPageActive(current =>
            typeof value !== "undefined" ? value : !current
        )
    }

    const onGoToPage = (data: any) => {
        const { neededPage } = data

        if (
            !neededPage ||
            neededPage > Math.max(...pagesConfig.pages) ||
            parseInt(neededPage) === pagesConfig.currentPage
        ) {
            return
        }

        toggleGoToPage(false)
        onGetNewItems((neededPage - 1) * props.nextPagination.count)
    }

    return (
        <div
            className={`
                ${props.className}
                w-full
            `}
        >
            {paginationEnabled ? (
                <div className="px-12px py-24px w-full">
                    <ProgressElement
                        percent={Math.ceil(
                            (props.nextPagination.start /
                                (props.nextPagination.total || 1)) *
                                100
                        )}
                        showInfo={false}
                    />
                </div>
            ) : null}

            <div
                className={`
                    py-24px relative w-full
                    ${!paginationEnabled ? "mt-32px" : ""}
                `}
            >
                <div className="flex justify-center">
                    {paginationEnabled ? (
                        <div className="flex items-center">
                            <PrimaryText className="mr-24px">
                                <span className="text-semibold">
                                    {Math.max(
                                        0,
                                        props.nextPagination.start -
                                            props.nextPagination.count
                                    ) + 1}
                                    -
                                    {Math.min(
                                        props.nextPagination.start,
                                        props.nextPagination.total
                                    )}
                                </span>{" "}
                                of {props.nextPagination.total}
                            </PrimaryText>

                            <div
                                className={`
                                    mr-2px px-4px flex relative top--1px
                                    ${
                                        previousPaginationEnabled
                                            ? "cursor-pointer"
                                            : "opacity-50"
                                    }
                                `}
                                onClick={() => {
                                    getPreviousItems(0)
                                }}
                            >
                                <img
                                    src={paginateLeftIcon}
                                    alt="Paginate first"
                                />

                                <img
                                    src={paginateLeftIcon}
                                    alt="Paginate first"
                                />
                            </div>

                            <img
                                className={`
                                    mr-8px px-4px relative top--1px
                                    ${
                                        previousPaginationEnabled
                                            ? "cursor-pointer"
                                            : "opacity-50"
                                    }
                                `}
                                src={paginateLeftIcon}
                                alt="Paginate left"
                                onClick={() => {
                                    getPreviousItems()
                                }}
                            />

                            <div className="flex items-center mx-4px">
                                {neededRenderedPages.hasPrevious && (
                                    <PrimaryText
                                        typography="text"
                                        className="mx-2px"
                                    >
                                        ...
                                    </PrimaryText>
                                )}

                                {neededRenderedPages.pages.map(
                                    (page, index) => {
                                        const isActivePage =
                                            page === pagesConfig.currentPage

                                        // If it's our max page
                                        // Need to add 3 dots before it if has more - so basically there's a break before it
                                        const needAddDotsDivider =
                                            page ===
                                                Math.max(
                                                    ...pagesConfig.pages
                                                ) && neededRenderedPages.hasNext

                                        return (
                                            <div
                                                key={index}
                                                className="flex items-center"
                                            >
                                                {needAddDotsDivider && (
                                                    <PrimaryText
                                                        typography="text"
                                                        className="mx-2px"
                                                    >
                                                        ...
                                                    </PrimaryText>
                                                )}

                                                <div
                                                    className={`
                                                        px-4px mx-2px
                                                        ${
                                                            !isActivePage
                                                                ? "cursor-pointer"
                                                                : ""
                                                        }
                                                    `}
                                                    onClick={() =>
                                                        onGoToPage({
                                                            neededPage: page,
                                                        })
                                                    }
                                                >
                                                    <PrimaryText
                                                        typography={
                                                            isActivePage
                                                                ? "textSemibold"
                                                                : "text"
                                                        }
                                                    >
                                                        {page}
                                                    </PrimaryText>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>

                            <img
                                className={`
                                    ml-8px mr-2px px-4px relative top--1px
                                    ${
                                        nextPaginationEnabled
                                            ? "cursor-pointer"
                                            : "opacity-50"
                                    }
                                `}
                                src={paginateRightIcon}
                                alt="Paginate right"
                                onClick={() => {
                                    getNextItems()
                                }}
                            />

                            <div
                                className={`
                                    px-4px flex relative top--1px
                                    ${
                                        nextPaginationEnabled
                                            ? "cursor-pointer"
                                            : "opacity-50"
                                    }
                                `}
                                onClick={() => {
                                    getNextItems(true)
                                }}
                            >
                                <img
                                    src={paginateRightIcon}
                                    alt="Paginate last"
                                />

                                <img
                                    src={paginateRightIcon}
                                    alt="Paginate last"
                                />
                            </div>

                            <div className="ml-24px flex items-center">
                                <div
                                    className={`
                                        cursor-pointer
                                        ${isGoToPageActive ? "invisible" : ""}
                                    `}
                                    onClick={() => toggleGoToPage(true)}
                                >
                                    <PrimaryText typography="textSemibold">
                                        Go to page
                                    </PrimaryText>
                                </div>

                                {isGoToPageActive && (
                                    <div className="absolute z-10">
                                        <form
                                            onSubmit={handleSubmit(onGoToPage)}
                                            noValidate={true}
                                        >
                                            <div className="flex items-center">
                                                <InputElement
                                                    className="w-70px mr-8px"
                                                    name="neededPage"
                                                    size="small"
                                                    type="number"
                                                    reactHookControl={control}
                                                    reactHookValidations={{
                                                        required:
                                                            VALIDATION_CONFIG.required,
                                                        min: VALIDATION_CONFIG.minAmount,
                                                    }}
                                                />

                                                <div
                                                    className="cursor-pointer mr-8px"
                                                    onClick={handleSubmit(
                                                        onGoToPage
                                                    )}
                                                >
                                                    <img
                                                        src={searchIcon}
                                                        alt="Search"
                                                    />
                                                </div>

                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        toggleGoToPage(false)
                                                    }
                                                >
                                                    <img
                                                        src={closeIcon}
                                                        alt="Cancel"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="absolute z-10 right-48px top-14px md:top-48px">
                    <SelectElement
                        className="w-180px"
                        placeholder="Select one"
                        name="paginationOption"
                        externalValue={
                            props.selectedPaginationOption.displayValue
                        }
                        renderedOptions={
                            POLICIES_CONFIG.availablePaginationOptions
                        }
                        onSelected={onPaginationOptionSelected}
                        inlined
                    />
                </div>
            </div>
        </div>
    )
}
