/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { CheckboxElement, PrimaryText } from "nirvana-react-elements"
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd"

import {
    AvailableCoverageHistorySortDirection,
    AvailableCoverageHistorySortField,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"

import sortIcon from "../../../assets/images/icons/sort-general.svg"
import sortDownIcon from "../../../assets/images/icons/sort-down.svg"

export const PoliciesListTableHeaderComponent: React.FunctionComponent<
    IPoliciesListTableHeaderComponentProps
> = props => {
    const policiesState = useAppSelector(policiesSelector)

    const activeColumns = useMemo<IPolicyColumnConfiguration[]>(() => {
        return props.columns.filter(item => item.isEnabled)
    }, [props.columns])

    const [isDragActive, setIsDragActive] = useState<boolean>(false)

    const onSortChanged = (newSortField: AvailableCoverageHistorySortField) => {
        props.onSetSortField?.(newSortField)
    }

    const onOrderChangeDragEnd = (result: DropResult) => {
        setIsDragActive(false)

        // if move to same position or moving handler is not passed
        if (
            !result.destination ||
            result.destination.index === result.source.index ||
            !props.onChangeColumnOrder
        ) {
            return
        }

        const columnLabelMoveSource = result.draggableId
        const columnLabelMoveTarget =
            activeColumns[result.destination.index].label

        // If moving forward, index of target will be more than source
        props.onChangeColumnOrder(
            columnLabelMoveSource,
            columnLabelMoveTarget,
            result.destination.index > result.source.index
                ? PolicyHeaderDragDirection.AFTER
                : PolicyHeaderDragDirection.BEFORE
        )
    }

    return (
        <div className="table-row w-full">
            <div
                className={`
                    flex items-center bg-brand-white
                    ${!props.ignoreHorizontalPadding ? "px-16px" : ""}
                `}
            >
                {props.policiesSelectable && (
                    <div className="py-8px mr-16px">
                        <CheckboxElement
                            name="selectAll"
                            defaultChecked={
                                !!props.policies.length &&
                                props.selectedItems?.length ===
                                    props.policies.length
                            }
                            watchDefaultCheckedChange={true}
                            onChange={newValue =>
                                newValue
                                    ? props.onSelectAllItems?.()
                                    : props.onDeselectAllItems?.()
                            }
                        />
                    </div>
                )}

                <DragDropContext
                    onDragEnd={onOrderChangeDragEnd}
                    onDragStart={() => {
                        setIsDragActive(true)
                    }}
                >
                    <Droppable
                        droppableId="droppable"
                        direction="horizontal"
                        isDropDisabled={
                            !props.onChangeColumnOrder ||
                            policiesState.policiesHeaderDragDropDisabled
                        }
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={`
                                    flex overflow-auto
                                    ${
                                        snapshot.isDraggingOver
                                            ? "bg-brand-lilacLight"
                                            : undefined
                                    }
                                `}
                                {...provided.droppableProps}
                            >
                                <span className="hidden">
                                    {provided.placeholder}
                                </span>

                                {activeColumns.map((columnConfig, index) => {
                                    const isSortEnabled = !!(
                                        props.sortEnabled &&
                                        columnConfig.sortByField
                                    )

                                    const isCurrentFieldMainSort =
                                        props.sortConfiguration?.sortBy ===
                                        columnConfig.sortByField

                                    return (
                                        <Draggable
                                            index={index}
                                            key={columnConfig.label}
                                            draggableId={columnConfig.label}
                                            isDragDisabled={
                                                !props.onChangeColumnOrder ||
                                                policiesState.policiesHeaderDragDropDisabled
                                            }
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={
                                                        provided.draggableProps
                                                            .style
                                                    }
                                                    className={`
                                                        mr-16px relative flex-shrink-0 py-8px
                                                        ${
                                                            snapshot.isDragging
                                                                ? "bg-brand-lilac"
                                                                : "bg-brand-white"
                                                        }
                                                        ${
                                                            columnConfig.widthClassName ||
                                                            "w-250px"
                                                        }
                                                        ${
                                                            columnConfig.mouseflowIgnore
                                                                ? "mouseflow-ignore"
                                                                : ""
                                                        }
                                                        ${
                                                            isDragActive
                                                                ? "pl-8px"
                                                                : ""
                                                        }
                                                    `}
                                                >
                                                    <PrimaryText
                                                        className="opacity-75 flex items-center"
                                                        typography="h6"
                                                    >
                                                        {columnConfig.label}

                                                        {isSortEnabled ? (
                                                            <div
                                                                className={`
                                                                    cursor-pointer px-8px py-2px
                                                                    ${
                                                                        isCurrentFieldMainSort
                                                                            ? "transform scale-150"
                                                                            : ""
                                                                    }
                                                                `}
                                                                onClick={
                                                                    isSortEnabled
                                                                        ? () => {
                                                                              columnConfig.sortByField &&
                                                                                  onSortChanged(
                                                                                      columnConfig.sortByField
                                                                                  )
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <img
                                                                    className={
                                                                        isCurrentFieldMainSort &&
                                                                        props
                                                                            .sortConfiguration
                                                                            ?.sortDirection ===
                                                                            AvailableCoverageHistorySortDirection.ASC
                                                                            ? "transform rotate-180"
                                                                            : ""
                                                                    }
                                                                    src={
                                                                        isCurrentFieldMainSort
                                                                            ? sortDownIcon
                                                                            : sortIcon
                                                                    }
                                                                    alt="Sort"
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </PrimaryText>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}
