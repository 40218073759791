/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    PrimaryText,
    SpinnerElement,
    ButtonElement,
    PageHelmetElement,
} from "nirvana-react-elements"

import {
    organizationInvitesGetList,
    organizationUsersGetList,
} from "../../../store/thunks/organization.thunks"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { OrganizationUserInvitePopupComponent } from "../../popups/organizationUserInvitePopup.component"
import { OrganizationUsersListComponent } from "./organizationUsersList.component"
import { OrganizationInvitesListComponent } from "./organizationInvitesList.component"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { organizationSelector } from "../../../store/selectors/organization.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"

import plusIcon from "../../../assets/images/icons/plus-dark.svg"

export const OrganizationUsersComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const organizationState = useAppSelector(organizationSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const [isInitiated, setIsInitiated] = useState<boolean>(false)
    const [isUserInviteActive, setIsUserInviteActive] = useState<boolean>(false)

    const canManageUsers = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.managePracticeUsers,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const isListLoading = useMemo<boolean>(() => {
        return (
            RuntimeHelper.isOrganizationUsersListLoading() ||
            RuntimeHelper.isOrganizationUserInvitesListLoading()
        )
    }, [runtimeState.isLoading])

    // On mount if create was requested activate creation popup
    useEffect(() => {
        const isInvite = !!searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.create
        )

        if (!isInvite) {
            return
        }

        setIsUserInviteActive(true)

        // clean url
        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.create)
        setSearchParams(searchParams)
    }, [])

    // Reload users and invites list on practice change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            organizationUsersGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )

        dispatch(
            organizationInvitesGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )

        // Mark component as initiated
        // Without this spinner jumps and messy UI happens
        setTimeout(() => setIsInitiated(true))
    }, [selectedPracticeRole?.id])

    return canManageUsers && selectedPracticeRole ? (
        <div
            className={`
                ${props.className}
                max-w-840px mx-auto relative
            `}
        >
            <PageHelmetElement
                title="Users"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div className="flex items-center">
                <PrimaryText className="mr-16px" typography="h4">
                    Users
                </PrimaryText>

                <ButtonElement
                    label="Add user"
                    icon={plusIcon}
                    htmlType="button"
                    type="default"
                    onClick={() => setIsUserInviteActive(true)}
                />
            </div>

            {isListLoading || !isInitiated ? (
                <SpinnerElement containerClassName="mt-36px text-center" />
            ) : organizationState.users.length < 2 &&
              !organizationState.invites.length ? (
                <div className="mt-16px bg-brand-warmLight05 rounded-16px px-24px py-40px">
                    <PrimaryText>
                        Manage access to the organization, individuals’ roles,
                        and track how many checks each person has run.
                    </PrimaryText>
                </div>
            ) : (
                <>
                    <OrganizationUsersListComponent className="mt-16px" />

                    <OrganizationInvitesListComponent />
                </>
            )}

            <OrganizationUserInvitePopupComponent
                isActive={isUserInviteActive}
                onDone={() => setIsUserInviteActive(false)}
            />
        </div>
    ) : null
}
