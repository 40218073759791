import { NavigateFunction } from "react-router-dom"
import {
    UtilHelper,
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"
import { SocketsHelper } from "./sockets.helper"
import { authReset } from "../store/slices/auth.slice"
import { userDetailsReset } from "../store/slices/userDetails.slice"
import { coveragePayersReset } from "../store/slices/coveragePayers.slice"
import { organizationReset } from "../store/slices/organization.slice"
import { billerGroupsReset } from "../store/slices/billerGroups.slice"
import { calculatorReset } from "../store/slices/calculator.slice"
import { policiesReset } from "../store/slices/policies.slice"
import { coverageOverridesReset } from "../store/slices/coverageOverrides.slice"
import { runtimeReset } from "../store/slices/runtime.slice"
import { supportReset } from "../store/slices/support.slice"

export class AuthHelper {
    /**
     * Check if user is logged in
     */
    static isLoggedIn(): boolean {
        return !!AuthHelper.getToken()
    }

    /**
     * Set token got from API to localStorage
     */
    static setToken(token: string) {
        BrowserStorageHelper.update(
            GENERAL_CONFIG.browserStorageKeys.tokenKey,
            token
        )
    }

    /**
     * Get token from localStorage
     */
    static getToken(): string | undefined {
        return (
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys.tokenKey
            ) || undefined
        )
    }

    /**
     * Remove token from localStorage
     */
    static removeToken() {
        BrowserStorageHelper.remove(GENERAL_CONFIG.browserStorageKeys.tokenKey)
    }

    /**
     * Login user to application with token
     */
    static login(token: string, navigate?: NavigateFunction) {
        AuthHelper.setToken(token)

        // Handle redirectTo from url query
        UtilHelper.redirectTo(
            UtilHelper.getRawUrlSearchParam(
                GENERAL_CONFIG.urlSearchParamsKeys.redirectTo
            ) || ROUTES_CONFIG.baseApplicationUrl,
            navigate
        )
    }

    /**
     * Logout user
     */
    static logout(
        redirectUrl: string = ROUTES_CONFIG.loginUrl,
        navigate?: NavigateFunction
    ) {
        try {
            sessionStorage.clear()
        } catch (e) {}

        // Clear saved filters in local storage
        try {
            BrowserStorageHelper.remove(
                GENERAL_CONFIG.browserStorageKeys
                    .reportsPoliciesFiltersConfiguration,
                BrowserStorageType.localStorage
            )

            BrowserStorageHelper.remove(
                GENERAL_CONFIG.browserStorageKeys
                    .overridesPoliciesFiltersConfiguration,
                BrowserStorageType.localStorage
            )
        } catch (e) {}

        AuthHelper.dispatch(authReset())
        AuthHelper.dispatch(billerGroupsReset())
        AuthHelper.dispatch(calculatorReset())
        AuthHelper.dispatch(coverageOverridesReset())
        AuthHelper.dispatch(coveragePayersReset())
        AuthHelper.dispatch(organizationReset())
        AuthHelper.dispatch(policiesReset())
        AuthHelper.dispatch(runtimeReset())
        AuthHelper.dispatch(supportReset())
        AuthHelper.dispatch(userDetailsReset())

        AuthHelper.removeEagleEyeViewAsData(false)

        // Clear socket.io connection
        const socketInstance = SocketsHelper.getInstance()
        socketInstance.disconnect(true)

        AuthHelper.removeToken()

        UtilHelper.redirectTo(redirectUrl, navigate)
    }

    /**
     * Set view as data for Eagle Eye functionality
     * that allows to view as any other user and view any practice that that user has access to
     */
    static setEagleEyeViewAsData(
        data: IEagleEyeViewAsConfig,
        withReload = true
    ) {
        BrowserStorageHelper.set(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            data,
            BrowserStorageType.localStorage
        )

        if (withReload) {
            window.location.reload()
        }
    }

    /**
     * Get view as data for Eagle Eye functionality
     */
    static getEagleEyeViewAsData(): IEagleEyeViewAsConfig | null {
        return BrowserStorageHelper.get(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            null,
            BrowserStorageType.localStorage
        )
    }

    /**
     * Delete view as data for Eagle Eye functionality
     */
    static removeEagleEyeViewAsData(withReload = true) {
        BrowserStorageHelper.remove(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            BrowserStorageType.localStorage
        )

        if (withReload) {
            window.location.reload()
        }
    }

    /**
     * Dispatch some action to store
     */
    private static dispatch(action: any) {
        const dispatch = require("../store/store").store.dispatch

        dispatch(action)
    }
}
