/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { GiBeastEye } from "react-icons/gi"
import useOnclickOutside from "react-cool-onclickoutside"
import {
    ISelectRenderedOption,
    ButtonElement,
    AutoCompleteElement,
    SelectElement,
    NIRVANA_COLORS,
    PrimaryText,
} from "nirvana-react-elements"

import { LookupService } from "../../services/lookup.service"
import { LookupHelper } from "../../helpers/lookup.helper"
import { AuthHelper } from "../../helpers/auth.helper"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"
import { organizationUsersGetList } from "../../store/thunks/organization.thunks"

import searchIcon from "../../assets/images/icons/search-dark.svg"
import closeIcon from "../../assets/images/icons/close-dark.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"

export const EagleEyeComponent: React.FunctionComponent<
    IEagleEyeComponentProps
> = props => {
    const dispatch = useAppDispatch()
    const runtimeState = useAppSelector(runtimeSelector)

    const toggle = (value?: boolean) => {
        setIsExpanded(current =>
            typeof value === "undefined" ? !current : value
        )
    }

    const existingConfigContainerRef = useOnclickOutside(() => {
        toggle(false)
    })

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const [availableUsers, setAvailableUsers] = useState<IOrganizationUser[]>(
        []
    )

    const [selectedPractice, setSelectedPractice] =
        useState<ISimplifiedPractice>()
    const [selectedUser, setSelectedUser] = useState<IOrganizationUser>()

    const [eagleEyeData] = useState<IEagleEyeViewAsConfig | null>(
        AuthHelper.getEagleEyeViewAsData()
    )

    const isOrganizationUsersLoading = useMemo(
        () => RuntimeHelper.isOrganizationUsersListLoading(),
        [runtimeState.isLoading]
    )

    const renderedAvailableUsers: ISelectRenderedOption[] = useMemo(
        () =>
            availableUsers.map(item =>
                LookupHelper.getRenderedOrganizationUser(item)
            ),
        [availableUsers]
    )

    const onPracticeSelected = async (item: ISimplifiedPractice | null) => {
        if (!item) {
            setAvailableUsers([])
            setSelectedPractice(undefined)
            setSelectedUser(undefined)

            return
        }

        setSelectedPractice(item)

        const users = await dispatch(
            organizationUsersGetList({
                payload: undefined,
                practice: item.id,
            })
        ).unwrap()

        setAvailableUsers(users)
    }

    const onReset = () => {
        AuthHelper.removeEagleEyeViewAsData()
    }

    const onViewAsSubmit = () => {
        if (!selectedUser || !selectedPractice) {
            return
        }

        AuthHelper.setEagleEyeViewAsData({
            user: selectedUser,
            practice: selectedPractice,
        })
    }

    return (
        <div
            className={`
                relative ${props.className}
            `}
        >
            <GiBeastEye
                size={40}
                color={NIRVANA_COLORS.brand.primary}
                className="cursor-pointer"
                onClick={() => toggle()}
            />

            {isExpanded && (
                <div
                    className="
                        absolute z-100 top-45px right-20px shadow-3
                        rounded-6px w-550px p-20px bg-brand-white
                    "
                >
                    <PrimaryText typography="h4Medium">Eagle Eye</PrimaryText>

                    <div
                        className="absolute right-30px top-30px cursor-pointer"
                        onClick={() => toggle(false)}
                    >
                        <img src={closeIcon} alt="Close" title="Close" />
                    </div>

                    {eagleEyeData ? (
                        <div
                            ref={existingConfigContainerRef}
                            className="mt-16px"
                        >
                            <div className="flex">
                                <PrimaryText
                                    className="w-100px"
                                    typography="textSemibold"
                                >
                                    Practice:
                                </PrimaryText>

                                <PrimaryText className=" flex-1 min-w-0 truncate">
                                    {eagleEyeData.practice.name}

                                    <PrimaryText typography="caption">
                                        ID: {eagleEyeData.practice.id}
                                    </PrimaryText>
                                </PrimaryText>
                            </div>

                            <div className="mt-16px flex">
                                <PrimaryText
                                    className="w-100px"
                                    typography="textSemibold"
                                >
                                    User:
                                </PrimaryText>

                                <PrimaryText className=" flex-1 min-w-0 truncate">
                                    {eagleEyeData.user.name}

                                    <PrimaryText typography="caption">
                                        ID: {eagleEyeData.user.id}
                                    </PrimaryText>
                                    <PrimaryText typography="caption">
                                        Email: {eagleEyeData.user.email}
                                    </PrimaryText>
                                    <PrimaryText typography="caption">
                                        Role: {eagleEyeData.user.role?.label}
                                    </PrimaryText>
                                </PrimaryText>
                            </div>

                            <div className="mt-16px flex">
                                <div className="flex-1" />

                                <ButtonElement
                                    label="Reset to initial user"
                                    type="default"
                                    htmlType="button"
                                    size="large"
                                    onClick={onReset}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <AutoCompleteElement
                                name="practice"
                                className="mt-48px"
                                label="Practice"
                                placeholder="Search practice by id, name, or group npi"
                                dataFetcher={LookupService.lookupPractices}
                                itemRenderer={LookupHelper.getRenderedPractice}
                                onSelected={onPracticeSelected}
                                inputPrefix={searchIcon}
                                isLabelStatic
                                ignoreAutoSelectionOnBlur
                                notFoundTitle="No matches found"
                            />

                            <SelectElement
                                name="user"
                                className="mt-40px"
                                label="User"
                                placeholder="Please choose"
                                renderedOptions={renderedAvailableUsers}
                                onSelected={setSelectedUser}
                                disabled={isOrganizationUsersLoading}
                            />

                            <div className="mt-24px flex">
                                <div className="flex-1" />

                                <ButtonElement
                                    label="View as"
                                    type="primary"
                                    htmlType="button"
                                    size="large"
                                    icon={arrowRightIcon}
                                    isLoading={isOrganizationUsersLoading}
                                    onClick={onViewAsSubmit}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
