import React from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import {
    ButtonElement,
    PrimaryText,
    useScrollFix,
    PageHelmetElement,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { authForgotPassword } from "../../store/thunks/auth.thunks"
import { useAppSelector } from "../../store/selectors/app.selector"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { authSelector } from "../../store/selectors/auth.selector"
import { useAppDispatch } from "../../store/appDispatch.hook"

import mainImage from "../../assets/images/shapes/reset-password.svg"
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"

export const RestoreComponent: React.FunctionComponent = () => {
    useScrollFix()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const authState = useAppSelector(authSelector)

    const onPasswordRestore = (data: any) => {
        dispatch(
            authForgotPassword({
                payload: data.email,
                navigate,
            })
        )
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Reset Password"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <img
                src={mainImage}
                alt="Nirvana Health"
                title="Nirvana Health"
                className="mx-auto sm:w-150px"
            />

            <PrimaryText centered className="mt-32px" typography="h1">
                Reset password
            </PrimaryText>

            <PrimaryText centered className="mt-32px" typography="subtitle">
                We will send you a verification code, <br /> which you will need
                to reset your password.
            </PrimaryText>

            <form onSubmit={handleSubmit(onPasswordRestore)} noValidate={true}>
                <InputElement
                    className="mt-44px"
                    name="email"
                    label="Email address"
                    reactHookControl={control}
                    reactHookErrors={errors}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        pattern: VALIDATION_CONFIG.email,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                    inputSuffix={
                        <img
                            src={mailIcon}
                            alt="Email address"
                            title="Email address"
                            className="ml-8px"
                        />
                    }
                />

                <div className="mt-32px flex items-center">
                    <div className="flex-1 mr-24px">
                        <Link to={ROUTES_CONFIG.loginUrl}>Cancel</Link>
                    </div>

                    <ButtonElement
                        label="Reset"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
