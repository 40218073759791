/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    FixedTopRightActionElement,
    PageHelmetElement,
    StepperElement,
    useScrollFix,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../../config/routes.config"
import { STEPPER_CONFIG } from "../../../config/stepper.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { authSelector } from "../../../store/selectors/auth.selector"
import { authSetCheckUserInvitation } from "../../../store/slices/auth.slice"
import { authRegister } from "../../../store/thunks/auth.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"

export const RegisterComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const authState = useAppSelector(authSelector)

    const submitRegistration = (registerData: IRegisterData) => {
        dispatch(
            authRegister({
                payload: {
                    ...registerData,

                    inviteToken: authState.userInvitation?.inviteToken,
                },
                navigate,
                onSuccess: () => dispatch(authSetCheckUserInvitation()),
            })
        )
    }

    const getSpecialStepBottomContent = (
        currentStepIndex: number
    ): JSX.Element | null => {
        if (currentStepIndex === 0) {
            return (
                <FixedTopRightActionElement
                    className="z-201 md:static! md:mt-32px"
                    ctaAction={() => navigate(ROUTES_CONFIG.loginUrl)}
                    ctaLabel="Log In"
                    additionalLabel="Have an account already?"
                />
            )
        }

        return null
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Create an Account"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <StepperElement
                steps={STEPPER_CONFIG.registerSteps}
                onStepperSubmit={submitRegistration}
                getSpecialStepBottomContent={getSpecialStepBottomContent}
                searchParams={searchParams}
            />
        </div>
    )
}
