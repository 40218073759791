import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { CoverageOverridesService } from "../../services/coverageOverrides.service"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { UtilHelper } from "../../helpers/util.helper"
import { OVERRIDES_CONFIG } from "../../config/overrides.config"

const upsertSingleOverride = async (
    practice: IPractice,
    data: IOverrideUpsertRequestRawData
): Promise<ICoverageOverrideResult> => {
    try {
        const result = await CoverageOverridesService.upsert(practice, data)

        if (!result) {
            throw new Error(
                `Failed to create override for member ID: ${data.policyIdentification.memberId}`
            )
        }

        return result
    } catch (e: any) {
        return {
            success: [],
            errors: [
                {
                    msg: e.message,
                    requestData:
                        CoverageOverridesService.getMappedOverrideData(data),
                },
            ],
        }
    }
}

/**
 * Create coverage overrides in bulk
 */
export const createCoverageOverrides = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        data: IOverrideUpsertRequestRawData[]
        sleepAtTheEndSeconds?: number
    }>
>("createCoverageOverridesPrefix", async (action, { dispatch }) => {
    !action.ignorePreloader &&
        dispatch(runtimeStartLoading("createCoverageOverridesLoading"))

    try {
        const batchSize = OVERRIDES_CONFIG.bulkOverrides.creationChunkSize
        const finalResult: ICoverageOverrideResult = {
            success: [],
            errors: [],
        }

        let processedCount = 0

        while (processedCount < action.payload.data.length) {
            const overrideResults = [] as Promise<ICoverageOverrideResult>[]

            // Generate processing promises with our "pagination' based on batch size
            for (let i = processedCount; i < processedCount + batchSize; i++) {
                const payload = action.payload.data[i]

                if (!payload) {
                    continue
                }

                overrideResults.push(
                    upsertSingleOverride(action.practice, payload)
                )
            }

            processedCount += batchSize

            const results = await Promise.all(overrideResults)

            // Add new results to final results
            for (const result of results) {
                finalResult.success = [
                    ...finalResult.success,
                    ...result.success,
                ]

                finalResult.errors = [...finalResult.errors, ...result.errors]
            }
        }

        // history service is not being updated right away with new data - there's some delay
        // make sure to wait until getting new data
        await UtilHelper.sleep(action.payload.sleepAtTheEndSeconds || 2)

        action.onSuccess && action.onSuccess(finalResult)
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("createCoverageOverridesLoading"))
    }
})

/**
 * Get single override
 */
export const getSingleCoverageOverride = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        data: IPolicyIdentificationDataWithNetwork
    }>
>("getSingleCoverageOverridePrefix", async (action, { dispatch }) => {
    !action.ignorePreloader &&
        dispatch(runtimeStartLoading("getSingleCoverageOverrideLoading"))

    try {
        const result = await CoverageOverridesService.getSingle(
            action.practice,
            action.payload.data
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("getSingleCoverageOverrideLoading"))
    }
})

/**
 * Delete single override
 */
export const deleteSingleCoverageOverride = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        overrideNirvanaRequestId: string
    }>
>("deleteSingleCoverageOverridePrefix", async (action, { dispatch }) => {
    !action.ignorePreloader &&
        dispatch(runtimeStartLoading("deleteSingleCoverageOverrideLoading"))

    try {
        const result = await CoverageOverridesService.deleteSingle(
            action.practice,
            action.payload.overrideNirvanaRequestId
        )

        if (result?.nirvanaRequestId) {
            // history service is not being updated right away with new data - there's some delay
            // make sure to wait until getting new data
            await UtilHelper.sleep(2)

            action.onSuccess && action.onSuccess(result.nirvanaRequestId)
        } else {
            ToastrHelper.error(
                "Failed to remove override. Please try again or contact support"
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("deleteSingleCoverageOverrideLoading"))
    }
})
