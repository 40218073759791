import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import {
    CoverageCheckerMode,
    CoverageCheckerRunningState,
} from "../../config/calculator.config"
import { PayerCoverageCheckNetwork } from "../../config/coverage.config"

const initialState: ICalculatorState = {
    checkerMode: CoverageCheckerMode.CSV,
    runningState: CoverageCheckerRunningState.INPUT_CSV,
    coverageChecksSubmitted: false,
    inputDataSetAt: new Date().getTime(),
    coverageChecks: [],
}

const calculatorSlice = createSlice({
    name: "calculator",
    initialState,
    reducers: {
        calculatorReset: state => {
            return {
                ...initialState,

                // Preserve is csv running type during reset
                checkerMode: state.checkerMode,
                runningState:
                    state.checkerMode === CoverageCheckerMode.CSV
                        ? CoverageCheckerRunningState.INPUT_CSV
                        : CoverageCheckerRunningState.INPUT_MANUAL,
            }
        },

        calculatorToggleRunningType: (
            state,
            action: PayloadAction<{ checkerMode?: CoverageCheckerMode }>
        ) => {
            // Toggle is csv running type + reset
            // Can accept specific value or will toggle

            const newCheckerMode =
                typeof action.payload.checkerMode !== "undefined"
                    ? action.payload.checkerMode
                    : state.checkerMode === CoverageCheckerMode.CSV
                    ? CoverageCheckerMode.MANUAL
                    : CoverageCheckerMode.CSV

            return {
                ...initialState,

                checkerMode: newCheckerMode,
                runningState:
                    newCheckerMode === CoverageCheckerMode.CSV
                        ? CoverageCheckerRunningState.INPUT_CSV
                        : CoverageCheckerRunningState.INPUT_MANUAL,
            }
        },

        calculatorSetRunningState: (
            state,
            action: PayloadAction<CoverageCheckerRunningState>
        ) => {
            state.runningState = action.payload
        },

        calculatorSetInputData: (
            state,
            action: PayloadAction<ICoverageInputData[]>
        ) => {
            state.inputDataSetAt = new Date().getTime()
            state.coverageChecks = action.payload.map(inputData => ({
                inputData,
            }))
        },

        calculatorRemoveInputData: (
            state,
            action: PayloadAction<ICoverageInputData>
        ) => {
            state.inputDataSetAt = new Date().getTime()
            state.coverageChecks = state.coverageChecks.filter(
                item => item.inputData.id !== action.payload.id
            )
        },

        calculatorSetCoverageChecksSubmitted: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.coverageChecksSubmitted = action.payload
        },

        calculatorReceivedCoverageResult: (
            state,
            action: PayloadAction<ICoverageResult>
        ) => {
            const newCoverageChecks = [...state.coverageChecks]

            // Use input data id that we've generated previously -> find needed index by it
            const neededCheckIndex = newCoverageChecks.findIndex(
                check => check.inputData.id === action.payload.resultId
            )

            if (~neededCheckIndex) {
                newCoverageChecks[neededCheckIndex].result = {
                    ...(newCoverageChecks[neededCheckIndex].result || {}),

                    [action.payload.isInNetworkCheck
                        ? PayerCoverageCheckNetwork.IN
                        : PayerCoverageCheckNetwork.OUT]: action.payload,
                }
            }

            state.coverageChecks = newCoverageChecks
        },
    },
})

export const {
    calculatorReset,
    calculatorToggleRunningType,
    calculatorSetRunningState,
    calculatorSetInputData,
    calculatorRemoveInputData,
    calculatorSetCoverageChecksSubmitted,
    calculatorReceivedCoverageResult,
} = calculatorSlice.actions

export const calculatorReducer = calculatorSlice.reducer
