/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    useScrollFix,
    ScreenTabsElement,
    PrimaryText,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"
import { AccountDetailsComponent } from "./accountDetails.component"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { MENU_CONFIG } from "../../config/menu.config"

export const ProfileComponent: React.FunctionComponent = () => {
    useScrollFix()

    const [searchParams] = useSearchParams()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    // ?tab=1 is supported in url for quick navigation to some tab
    const [selectedTab, setSelectedTab] = useState<IProfileAvailableTab>(
        MENU_CONFIG.profileAvailableTabs[
            searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) &&
            MENU_CONFIG.profileAvailableTabs[
                searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) || 0
            ]
                ? searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.tab) || 0
                : 0
        ]
    )

    const availableTabs = useMemo<IProfileAvailableTab[]>(() => {
        if (!selectedPracticeRole) {
            return []
        }

        // Filter out tabs by permissions
        return MENU_CONFIG.profileAvailableTabs.filter(
            item =>
                !item.neededPermissions ||
                PermissionsHelper.hasPermission(
                    item.neededPermissions,
                    selectedPracticeRole,
                    item.neededPermissionsLogicalOperator
                )
        )
    }, [selectedPracticeRole?.id])

    const tabRelatedContent = useMemo<JSX.Element | null>(() => {
        switch (selectedTab.key) {
            case "account":
                return <AccountDetailsComponent />

            default:
                return null
        }
    }, [selectedTab.key])

    return (
        <div className="pb-32 max-w-840px mx-auto relative">
            {availableTabs.length > 1 ? (
                <ScreenTabsElement
                    className="w-840px mx-auto mb-40px md:w-full"
                    currentTab={selectedTab}
                    tabs={availableTabs}
                    onSelected={tab =>
                        setSelectedTab(tab as IProfileAvailableTab)
                    }
                />
            ) : (
                <PrimaryText className="mt-32px mb-24px" typography="h4">
                    {selectedTab.label}
                </PrimaryText>
            )}

            {tabRelatedContent}
        </div>
    )
}
