import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    coveragePayersCreateDone,
    coveragePayersDeleteDone,
    coveragePayersSetList,
    coveragePayersUpdateDone,
} from "../slices/coveragePayers.slice"
import { CoveragePayersService } from "../../services/coveragePayers.service"

/**
 * Get list of coverage payers
 */
export const coveragePayersGetList = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<undefined>
>("coveragePayersGetListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("coveragePayersGetListLoading"))

    try {
        const result = await CoveragePayersService.getList(action.practice)

        if (result) {
            dispatch(coveragePayersSetList(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("coveragePayersGetListLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Create coverage payer
 */
export const coveragePayersCreate = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IPayerUpsertData>
>("coveragePayersCreatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("coveragePayersCreateLoading"))

    try {
        const result = await CoveragePayersService.createInstance(
            action.payload,
            action.practice
        )

        if (result) {
            dispatch(coveragePayersCreateDone(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("coveragePayersCreateLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Update coverage payer
 */
export const coveragePayersUpdate = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        payerId: number
        updateData: IPayerUpsertData
    }>
>("coveragePayersUpdatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("coveragePayersUpdateLoading"))

    try {
        const result = await CoveragePayersService.updateInstance(
            action.payload.payerId,
            action.payload.updateData,
            action.practice
        )

        if (result) {
            dispatch(coveragePayersUpdateDone(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("coveragePayersUpdateLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Delete coverage payer
 */
export const coveragePayersDelete = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<number>
>("coveragePayersDeletePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("coveragePayersDeleteLoading"))

    try {
        const result = await CoveragePayersService.deleteInstance(
            action.payload,
            action.practice
        )

        if (result?.result && result.id) {
            dispatch(coveragePayersDeleteDone(result.id))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("coveragePayersDeleteLoading"))

        action.onFinally && action.onFinally()
    }
})
