/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import {
    PrimaryText,
    SpinnerElement,
    NIRVANA_COLORS,
} from "nirvana-react-elements"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesGetReportsAggregationsFlags } from "../../../store/thunks/policies.thunks"
import { UtilHelper } from "../../../helpers/util.helper"
import { ReportsFlagsAggregationCardComponent } from "./reportsFlagsAggregationCard.component"

export const ReportsFlagsVisualizationComponent: React.FunctionComponent<
    IReportsFlagsVisualizationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { reportsFilters, reportsAggregationsFlags } =
        useAppSelector(policiesSelector)

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isReportsAggregationsFlagsLoading()
    }, [runtimeState.isLoading])

    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const thunkPromise = dispatch(
            policiesGetReportsAggregationsFlags({
                practice: selectedPracticeRole.practice,
                payload: {
                    filters: reportsFilters,
                },
            })
        )

        return () => {
            thunkPromise.abort()
        }
    }, [selectedPracticeRole?.practice.id, JSON.stringify(reportsFilters)])

    const mappedFlagsAggregations = useMemo<
        IReportsAggregatedFlagsCalculations[]
    >(() => {
        const activeNoFlagsValues =
            reportsAggregationsFlags?.active_plans_without_flags || 0

        const totalCount = reportsAggregationsFlags?.total_checks || 0

        const result = [
            {
                label: "Active plans with no denial risks",
                isInlinedSubLabel: true,
                color: NIRVANA_COLORS.brand.green,
                value: activeNoFlagsValues,
                percentage: UtilHelper.getPercentageValue(
                    activeNoFlagsValues,
                    totalCount
                ),
            },
        ] as IReportsAggregatedFlagsCalculations[]

        for (const config of POLICIES_CONFIG.reportsFlagsAggregationsConfigs) {
            const filterFlags = (
                Object.keys(
                    POLICIES_CONFIG.flagTypeMappings
                ) as CoveragePortalFlagType[]
            ).filter(flagType => {
                const severity =
                    POLICIES_CONFIG.flagTypeMappings[flagType].severity

                return (
                    severity >= config.flagSeverityLower &&
                    severity < config.flagSeverityHigher
                )
            })

            const sectionTotalSum = filterFlags.reduce((memo, flag) => {
                return (
                    memo + (reportsAggregationsFlags?.aggregations?.[flag] || 0)
                )
            }, 0)

            const calculation: IReportsAggregatedFlagsCalculations = {
                label: config.label,
                color: config.color,
                filterFlags,
                value: sectionTotalSum,
                percentage: UtilHelper.getPercentageValue(
                    sectionTotalSum,
                    totalCount,
                    1
                ),
                children: [],
            }

            // Process children
            for (const flag of filterFlags) {
                const value = reportsAggregationsFlags?.aggregations?.[flag]

                if (!value) {
                    continue
                }

                calculation.children?.push({
                    label: POLICIES_CONFIG.flagTypeMappings[flag].label,
                    value,
                    percentage: UtilHelper.getPercentageValue(
                        value,
                        totalCount,
                        1
                    ),
                    filterFlag: flag,
                })
            }

            result.push(calculation)
        }

        return result
    }, [reportsAggregationsFlags])

    return (
        <div
            className={`
                relative min-h-550px bg-brand-lilacLight
                ${props.className}
            `}
        >
            {isLoading && (
                <div
                    className="
                        absolute z-10 w-full h-full bg-brand-whiteTransparent85
                        flex items-center justify-center
                    "
                >
                    <SpinnerElement />
                </div>
            )}

            {reportsAggregationsFlags && (
                <div className="py-40px px-24px">
                    <PrimaryText size={29} lineHeight={34} weight="bold">
                        Denial Risks
                    </PrimaryText>

                    {/*ITEMS with inlined label first one by one*/}
                    {mappedFlagsAggregations
                        .filter(item => !!item.isInlinedSubLabel)
                        .map((item, index) => {
                            return (
                                <ReportsFlagsAggregationCardComponent
                                    key={index}
                                    calculation={item}
                                    className="mt-24px"
                                />
                            )
                        })}

                    {/*ITEMS without inlined label then in row*/}
                    <div className="mt-24px flex">
                        {mappedFlagsAggregations
                            .filter(item => !item.isInlinedSubLabel)
                            .map((item, index) => {
                                return (
                                    <ReportsFlagsAggregationCardComponent
                                        key={index}
                                        className={`
                                            flex-1 self-stretch
                                            ${index ? "ml-24px" : ""}
                                        `}
                                        calculation={item}
                                        onSliceFiltered={props.onSliceFiltered}
                                    />
                                )
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}
